import React from 'react';
import './Loading.css';

function Loading({ message }) {
  return (
    <div className="loading-view">
      <div className="spinner-border spinner-border-sm" role="status" />
      <p>{message ? message : 'Loading...'}</p>
    </div>
  );
}

export default Loading;
