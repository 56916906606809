import React, { createRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import axios from 'axios';
import { API_GROUPS } from '../../utils/constants';
import ButtonLoad from '../ButtonLoad';
import './NewGroupModal.scss';

const NewGroupModal = (props) => {
  const { userInfo } = useSelector((state) => state.auth);
  const [groupName, setGroupName] = useState('');
  const [savingGroup, setSavingGroup] = useState(false);
  const [modalDismiss, setModalDismiss] = useState(createRef());

  const newGroupSave = () => {
    setSavingGroup(true);
    axios
      .post(
        API_GROUPS,
        {
          groupName: groupName
        },
        {
          headers: {
            Authorization: `bearer ${userInfo.accessToken}`
          }
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          setGroupName(' ');
          props.callbackFromParent();
          Swal.fire({
            type: 'success',
            title: 'Success!',
            text: 'The group was created successfully.'
          }).then(() => {
            setSavingGroup(false);
            modalDismiss.click();
          });
        }
      })
      .catch(function (xhr, ajaxOptions, thrownError) {
        Swal.fire({
          type: 'error',
          title: 'Error!',
          text: 'The group was not created successfully due an error.'
        }).then(() => {
          setSavingGroup(false);
          modalDismiss.click();
        });
      });
  };

  const handleGroupChange = (e) => {
    setGroupName(e.target.value);
  };

  return (
    <div>
      <div
        className="modal fade"
        id="addNewGroup"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="addNewGroupLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content tx-14">
            <div className="modal-header">
              <h6 className="modal-title" id="addNewGroupLabel">
                Add New Group
              </h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="formGroupExampleInput" className="d-block">
                    What's the group name?
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Group Name"
                    value={groupName}
                    onChange={handleGroupChange}></input>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary tx-13"
                  data-dismiss="modal"
                  ref={(button) => {
                    setModalDismiss(button);
                  }}>
                  Close
                </button>
                <ButtonLoad
                  state={savingGroup}
                  btn={'btn-primary btn-save-group'}
                  icon={'fa-plus'}
                  text={'Save Group'}
                  callback={() => {
                    newGroupSave();
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewGroupModal;
