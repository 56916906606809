import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import axios from 'axios';
import Image from '../../../components/StrategyImages/Image';
import './ImageLibrary.css';
import S3 from 'react-aws-s3';
import swal from 'sweetalert2';

import {
  API_KEY,
  BUCKET_CS_IMAGES_UPLOAD,
  AWS_REGION,
  AWS_MMS_S3_API_KEY,
  AWS_MMS_S3_ACCESS_KEY,
  UPDATE_STOCK_CLIENT_UPLOAD_IMAGES,
  DELETE_CL_IMAGE,
  LIST_CL_IMAGES
} from '../../../utils/constants';

const ImageLibrary = (props) => {
  const [images, setImages] = useState([]);

  const handleDeleteImage = async (imageToDelete) => {
    try {
      let config = {
        method: 'post',
        url: DELETE_CL_IMAGE,
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          clientId: imageToDelete.PK,
          sourceUrl: imageToDelete.SK
        })
      };
      await axios(config);
      setImages(images.filter((image) => image.SK !== imageToDelete.SK));
    } catch (error) {
      console.error('Error deleting image:', error);
    } finally {
    }
  };

  const handleSelectClient = async (client) => {
    try {
      const response = await axios.get(`${LIST_CL_IMAGES}/${client.id}`);
      setImages(response.data);
    } catch (error) {
      console.error('Error fetching images:', error);
    } finally {
    }
  };

  useEffect(() => {
    if (props?.selectedClient?.id) handleSelectClient(props?.selectedClient);
  }, [props.selectedClient]);

  // ======================================== Return ========================================

  const config = {
    dirName: `client/${props?.selectedClient?.id}` /*optional */,
    bucketName: BUCKET_CS_IMAGES_UPLOAD,
    region: AWS_REGION,
    accessKeyId: AWS_MMS_S3_API_KEY,
    secretAccessKey: AWS_MMS_S3_ACCESS_KEY,
    s3Url: `https://${BUCKET_CS_IMAGES_UPLOAD}.s3.${AWS_REGION}.amazonaws.com`
  };
  const [files, setFiles] = useState({ length: 0 });
  const [uploadingImages, setUploadingImages] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const handleUpload = async (file) => {
    let newFileName = uuid();
    const ReactS3Client = new S3(config);
    let { location } = await ReactS3Client.uploadFile(file, newFileName);
    return location;
  };

  const renderImages = (files) => {
    let i = 0;
    let itemsList = [];
    while (i < files.length) {
      itemsList[i] = files[i];
      i++;
    }
    itemsList = itemsList.map((item, index) => {
      function returnFileSize(number) {
        if (number < 1024) {
          return number + 'bytes';
        } else if (number >= 1024 && number < 1048576) {
          return (number / 1024).toFixed(1) + 'KB';
        } else if (number >= 1048576) {
          return (number / 1048576).toFixed(1) + 'MB';
        }
      }
      function handleName(name) {
        if (name.length > 35) {
          return name.slice(0, 35) + ' ...';
        } else return name;
      }
      return (
        <div
          key={index}
          className="card"
          style={{
            width: '48%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: 5,
              marginTop: 5,
              overflow: 'hidden'
            }}>
            <b>FileName: </b>
            <u>{handleName(item.name)}</u>
            <b>Size: </b>
            {returnFileSize(item.size)}
          </div>
          <div>
            <img
              alt=""
              src={URL.createObjectURL(item)}
              style={{ width: 100, height: 100, objectFit: 'cover' }}
            />
          </div>
        </div>
      );
    });

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          flexFlow: 'row wrap',
          alignContent: 'flex-end',
          justifyContent: 'space-between'
        }}>
        {itemsList}
      </div>
    );
  };

  return (
    <div className="card" id="img-library">
      <div className="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-between">
        <h6 className="tx-uppercase tx-semibold mg-b-0">
          Client Image Library {images.length > 0 ? `(${images.length})` : ''}
        </h6>
        <form>
          <input
            style={{
              visibility: 'hidden',
              position: 'absolute'
            }}
            id="image_uploads"
            onChange={(e) => {
              setFiles(e.target.files);
            }}
            type="file"
            multiple
            accept="image/png, image/jpeg"
          />
          {(files?.length && showUploadModal) > 0 ? (
            <div
              className="form-group"
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}>
              <React.Fragment>
                <div className="modal-backdrop in">
                  <div
                    className="modal fade in show"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    style={{ display: 'block' }}>
                    <div
                      className="modal-dialog modal-dialog-centered modal-lg tx-2"
                      role="document">
                      <div className="modal-content tx-12">
                        <div className="modal-header">
                          <h5 className="modal-title" id="addNewGroupLabel">
                            Upload Images ({files?.length})
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => setFiles({ length: 0 })}
                            disabled={uploadingImages}>
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div
                            className="card"
                            style={{
                              maxHeight: '500px',
                              minHeight: '325px',
                              overflow: 'scroll'
                            }}>
                            <div className="card-body">
                              <div>{renderImages(files)}</div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            disabled={uploadingImages}
                            className="btn btn-danger tx-13"
                            data-dismiss="modal"
                            onClick={() => {
                              setShowUploadModal(false);
                            }}>
                            Cancel
                          </button>
                          <button
                            type="button"
                            disabled={uploadingImages || files?.length === 0}
                            className="btn btn-primary"
                            onClick={async () => {
                              setUploadingImages(true);
                              config.name = files[0].name;
                              setProgress(0);
                              let newArr = files;
                              for (let i = 0; i < newArr.length; i++) {
                                let location = await handleUpload(newArr[i]);
                                axios({
                                  method: 'post',
                                  url: UPDATE_STOCK_CLIENT_UPLOAD_IMAGES,
                                  data: {
                                    clientId: props.selectedClient?.id,
                                    sourceUrl: location,
                                    source: 'manual_upload',
                                    keywords: ''
                                  },
                                  headers: {
                                    'X-API-KEY': API_KEY,
                                    'Content-Type': 'application/json'
                                  }
                                });
                                if (i !== 0) {
                                  let pr = (i / files.length) * 100;
                                  setProgress(Math.round(pr));
                                } else {
                                  setProgress(0);
                                }
                              }
                              setTimeout(async () => {
                                setUploadingImages(false);
                                handleSelectClient(props?.selectedClient);
                                setFiles({});
                                setProgress(0);
                                swal.fire('Success!', 'Uploaded', 'success');
                              }, 3000);
                            }}>
                            <span
                              className={`spinner-border spinner-border-sm mr-1 ${
                                !uploadingImages ? 'hidden' : ''
                              }`}
                              role="status"
                              aria-hidden="true"></span>
                            {uploadingImages
                              ? 'Uploading...' +
                                `${progress !== 0 ? progress : '0'}%`
                              : 'Upload'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            </div>
          ) : null}
        </form>
        <label
          style={{ margin: '0px' }}
          htmlFor="image_uploads"
          className="btn btn-sm  btn-primary mg-l-10"
          onClick={() => {
            setShowUploadModal(true);
          }}>
          Upload
        </label>
      </div>

      <div className="card-body pd-0">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%',
            paddingTop: '50px',
            justifyContent: 'center'
          }}>
          {images.length > 0 ? (
            images.map((img, idx) => {
              return (
                <div key={idx + img?.SK}>
                  <div>
                    <div>
                      <Image
                        onDeleteImage={handleDeleteImage}
                        style={{
                          width: '15vw',
                          marginLeft: '12px',
                          marginRight: '12px'
                        }}
                        img={img}
                      />
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <>
              {Array.from({ length: 8 }).map((item, idx) => (
                <div
                  key={idx}
                  style={{
                    width: '20vw',
                    margin: '20px',
                    minHeight: '14vw',
                    backgroundColor: '#E7E7E7',
                    borderRadius: '5px'
                  }}></div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageLibrary;
