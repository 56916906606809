import { API_GET_GOOGLE_PROFILE_INFO } from '../../../../utils';

export const getGoogleProfileInfo = async (clientId) => {
  const url = API_GET_GOOGLE_PROFILE_INFO;

  try {
    const googleInfo = await (
      await fetch(url + new URLSearchParams({ clientId }), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    ).json();

    return {
      loading: false,
      data: googleInfo
    };
  } catch (error) {
    console.error('An unknown error occurred while fetching notes info', error);
    return {
      loading: false,
      data: {}
    };
  }
};
