import { API_AUTO_ASSIGN } from '../../utils/constants';

const initialState = {
  loading: true,
  active: [],
  inactive: []
};

export const getFetchListContentCreators = () => {
  return async (dispatch) => {
    const { inactive, active } = await (await fetch(API_AUTO_ASSIGN)).json();
    dispatch(
      getListContentCreators({
        loading: false,
        inactive: inactive.Items,
        active: active.Items
      })
    );
  };
};

export const getListContentCreators = (contentCreators) => ({
  type: '[Assignment] Get List Content Creators',
  payload: contentCreators
});

export const getActiveList = (contentCreator, active) => ({
  type: '[Assignment] Get List Active',
  payload: {
    contentCreator,
    active
  }
});

export const getInactiveList = (contentCreator, inactive) => ({
  type: '[Assignment] Get List Inactive',
  payload: {
    contentCreator,
    inactive
  }
});

/**
 * REDUCER FOR CONTENT CREATOR ASSIGMENT
 */
const assignmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case '[Assignment] Get List Content Creators':
      return {
        ...action.payload
      };
    case '[Assignment] Get List Active':
      return {
        loading: false,
        inactive: [...state.inactive, action.payload.contentCreator],
        active: action.payload.active
      };
    case '[Assignment] Get List Inactive':
      return {
        loading: false,
        active: [...state.active, action.payload.contentCreator],
        inactive: action.payload.inactive
      };
    default:
      return state;
  }
};

export default assignmentReducer;
