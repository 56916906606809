import React, { useEffect, useState, createRef } from 'react';
import Swal from 'sweetalert2';

import Clock from 'react-live-clock';
import { NextIcon, PrevIcon, RestartIcon } from '../Icons';
import { useSelector } from 'react-redux';
import './Modal-calendar.css';
import * as Theme from '../theme/theme';
import axios from 'axios';
import Links from './SocialNetworkIcons/Links';
import {
  API_POSTS_EDIT,
  API_REGEN_AI_POST_CONTENT,
  API_EDIT_PROMPT
} from '../utils';
import ImagesCarousel from './ImagesCarousel';

import { postsSelector } from '../store/slices/posts';

const ViewPostsInCalendar = (props) => {
  const status = [
    { title: 'Schedule' },
    { title: 'Posted' },
    { title: 'Draft' },
    { title: 'Prompt' }
  ];
  const [statePost, setStatePost] = useState(0);
  const [facebook, setFacebook] = useState([]);
  const [googleMyBusiness, setGoogleMyBusiness] = useState([]);
  const [twitter, setTwitter] = useState([]);
  const [instagram, setInstagram] = useState([]);
  const [facebookResponse, setFacebookResponse] = useState([]);
  const [gmbResponse, setGmbResponse] = useState([]);
  const [twResponse, setTwResponse] = useState([]);
  const [igResponse, setIgResponse] = useState([]);
  const [fbDisplay, setFbDisplay] = useState([]);
  const [gmbDisplay, setGmbDisplay] = useState([]);
  const [twDisplay, setTwDisplay] = useState([]);
  const [editableMessage, setEditableMessage] = useState(false);
  const [editedMessage, setEditedMessage] = useState('');
  const [isEditingMessage, setIsEditingMessage] = useState(false);
  const [output, setOutput] = useState('');
  const allowedEmails = [
    'phillippa@adlersocial.com',
    'christiana@adlersocial.com',
    'deborah@adlersocial.com',
    'esther@adlersocial.com',
    'heather@adlersocial.com',
    'jose@adlersocial.com',
    'deannie@adlersocial.com',
    'zachary@adlersocial.com',
    'emily@adlersocial.com'
  ];
  const currentEmail = useSelector((state) => state?.auth?.userInfo?.email);
  let [modalDismiss, setModalDismiss] = useState(createRef());

  const { selectedPost } = useSelector(postsSelector);

  useEffect(() => {
    if (!props?.post?.status) return;
    if (props.post.status === 'Draft') {
      setStatePost(2);
    } else if (props.post.status === 'Prompt') {
      setStatePost(3);
      return;
    } else {
      // TIME ZONE
      if (typeof props.post.start === 'number') {
        new Date(props.timeZone) >= new Date(props.post.start * 1000)
          ? setStatePost(1)
          : setStatePost(0);
      } else {
        new Date(props.timeZone) >= props.post.start
          ? setStatePost(1)
          : setStatePost(0);
      }
    }

    //SET SOCIAL NETWORKS
    setGoogleMyBusiness(props.post?.socialNetworks.googleMyBusiness);
    setTwitter(props.post?.socialNetworks.twitter);
    setFacebook(props.post?.socialNetworks.facebook);
    setInstagram(props.post?.socialNetworks.instagram);
    //SET RESPONSES
    setFacebookResponse(props.post?.socialNetworks.facebookPosted);
    setGmbResponse(props.post?.socialNetworks.googleMyBusinessPosted);
    setTwResponse(props.post?.socialNetworks.twitterPosted);
    setIgResponse(props.post?.socialNetworks.instagramPosted);

    facebook ? setFbDisplay('d-inline-block') : setFbDisplay('d-none');
    googleMyBusiness
      ? setGmbDisplay('d-inline-block')
      : setGmbDisplay('d-none');
    twitter ? setTwDisplay('d-inline-block') : setTwDisplay('d-none');
  }, [
    props?.post?.id,
    props?.refresh,
    props?.posts,
    props?.post,
    facebook,
    googleMyBusiness,
    props?.timeZone,
    twitter
  ]);

  useEffect(() => {
    if (!editableMessage) {
      setEditedMessage(props?.post?.title);
    }
  }, [editableMessage]);

  useEffect(() => {
    setOutput(null);
  }, [props?.post?.id]);

  const regenPostContent = async () => {
    setIsEditingMessage(true);
    let start;
    if (typeof props.post.start === 'object') {
      start = new Date(props.post.start).getTime();
      start = start / 1000;
      start = Math.floor(start);
    } else {
      start = props.post.start;
    }
    const regenResponse = await axios.post(API_REGEN_AI_POST_CONTENT, {
      clientId: props?.post?.client?.id,
      unixTimestamp: String(start)
    });
    setOutput(regenResponse.data.body);
    setIsEditingMessage(false);
  };

  const handleEditMessage = async () => {
    setIsEditingMessage(true);
    const response = await axios.post(API_EDIT_PROMPT, {
      clientId: props?.post?.client?.id,
      prompt: editedMessage,
      editedBy: props?.userRole?.displayName,
      SK: props?.post?.SK
    });
    const { data } = response;
    // await props.getStatusPost();
    await props.carrouselModal(8, data?.id);

    await regenPostContent();

    setIsEditingMessage(false);
    setEditableMessage(!editableMessage);
  };
  function formatDayWeek(input) {
    // Split the argument into day and week
    if (!input) return;
    const [day, week] = input.split('#');

    // Define an array with the names of the days of the week
    const weekDays = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday'
    ];

    // Convert day and week to integers
    const dayNumber = parseInt(day, 10);
    const weekNumber = parseInt(week, 10);

    // Check if the values are valid
    if (dayNumber >= 1 && dayNumber <= 7 && weekNumber > 0) {
      // Get the corresponding day name
      const dayName = weekDays[dayNumber - 1];

      // Determine the week ordinal (1st, 2nd, 3rd, 4th, etc.)
      const weekOrdinal = getWeekOrdinal(weekNumber);

      // Build the output string
      const result = `${dayName} of the ${weekOrdinal} week`;

      return result;
    } else {
      return 'Invalid argument';
    }
  }

  // Function to get the week ordinal (1st, 2nd, 3rd, 4th, etc.)
  function getWeekOrdinal(weekNumber) {
    if (weekNumber === 1) {
      return '1st';
    } else if (weekNumber === 2) {
      return '2nd';
    } else if (weekNumber === 3) {
      return '3rd';
    } else {
      return weekNumber + 'th';
    }
  }
  const getTimeZoneClock = (currentClientTimeZone) => {
    let timeZoneClock = null;
    switch (currentClientTimeZone) {
      case 'Eastern Daylight Time - (GMT-4)':
        timeZoneClock = 'America/New_York';
        break;
      case 'Central Daylight Time - (GMT-5)':
        timeZoneClock = 'America/Chicago';
        break;
      case 'Mountain Daylight Time - (GMT-6)':
        timeZoneClock = 'America/Denver';
        break;
      case 'Mountain Standard Time - (GMT-7)':
        timeZoneClock = 'America/Phoenix';
        break;
      case 'Pacific Daylight Time - (GMT-7)':
        timeZoneClock = 'America/Los_Angeles';
        break;
      case 'Alaska Daylight Time - (GMT-8)':
        timeZoneClock = 'America/Anchorage';
        break;
      default:
        timeZoneClock = 'Pacific/Honolulu';
        break;
    }
    return timeZoneClock;
  };

  function formatDate(date) {
    if (typeof date == 'number') {
      let d = new Date(date * 1000);

      const tf = new Intl.DateTimeFormat('en', {
        month: 'long',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        dayPeriod: 'short'
      });

      try {
        const [
          { value: mo },
          ,
          { value: da },
          ,
          { value: ho },
          ,
          { value: mi },
          ,
          { value: dp }
        ] = tf.formatToParts(d);
        return `${da} ${mo} - ${ho} ${mi} ${dp}`;
      } catch (error) {
        return 'undefined';
      }
    }

    const dtf = new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      dayPeriod: 'short'
    });
    // prettier-ignore
    try {
      const [{ value: mo }, , { value: da }, , { value: ye }, , { value: ho }, , { value: mi }, , { value: dp }] = dtf.formatToParts(date);
      return `${da} ${mo} ${ye} - ${ho} ${mi} ${dp}`;
    } catch (error) {
      return 'undefined';
    }
  }

  let currentRole = props.userRole.role;

  let contentStatus = (
    <label
      style={{
        color:
          statePost === 0 && props?.post?.createdBy !== 'APP'
            ? '#039be5'
            : statePost === 1 && props?.post?.createdBy !== 'APP'
            ? '#f9b47f'
            : statePost === 2 && props?.post?.createdBy !== 'APP'
            ? '#7987a1'
            : statePost === 3
            ? Theme.promptColor
            : props?.post?.createdBy === 'APP'
            ? '#10b759'
            : '#7987a1'
      }}
      className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">
      {status[statePost]['title']}*
    </label>
  );
  let getIdxPost = () => {
    let idx = 0;
    while (props?.posts[idx]?.id !== props?.post?.id && idx < 300) {
      idx++;
    }
    return idx;
  };
  return (
    <div>
      {props.post &&
      getIdxPost() > 0 &&
      !isEditingMessage &&
      !editableMessage ? (
        <button
          className={`bnt-l-follow`}
          onClick={() => {
            props.carrouselModal(0);
          }}>
          <PrevIcon id="prev-icon" className="icon-modal" />
        </button>
      ) : null}

      <div
        className="modal fade"
        id="viewPost"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="addNewGroupLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div
            className={`modal-content ${
              statePost !== 3
                ? 'modal-content-cal-view'
                : 'modal-content-prompt-view'
            }`}>
            <div
              className="modal-header"
              style={{
                backgroundColor:
                  statePost === 0 && props?.post?.createdBy !== 'APP'
                    ? '#039be5'
                    : statePost === 1 && props?.post?.createdBy !== 'APP'
                    ? '#f9b47f'
                    : statePost === 2 && props?.post?.createdBy !== 'APP'
                    ? '#7987a1'
                    : statePost === 3
                    ? Theme.promptColor
                    : props?.post?.createdBy === 'APP'
                    ? '#10b759'
                    : null
              }}>
              <h6 className="event-title title-modal-view">{''}</h6>
              <nav className="nav nav-modal-event">
                {(statePost === 2 || statePost === 0) && (
                  <button
                    className="button-like-a nav-link"
                    onClick={() => {
                      props.updatePost(props?.post?.id, {
                        isDraft: statePost === 2 ? false : true
                      });
                      modalDismiss.click();
                      props.close();
                    }}>
                    <i className="fas fa-lg fa-magic" />
                  </button>
                )}
                {(statePost === 2 || statePost === 0) &&
                (currentRole === 'superAdmin' ||
                  allowedEmails.includes(currentEmail)) ? (
                  <button
                    className="button-like-a nav-link"
                    disabled={isEditingMessage}
                    onClick={() => {
                      props.IAPostTools(props?.post);
                      modalDismiss.click();
                      props.close();
                    }}>
                    <i className="fas fa-fw fa-lg fa-robot feather" />
                  </button>
                ) : null}
                {statePost === 1
                  ? []
                  : !isEditingMessage && (
                      <button
                        className="button-like-a nav-link"
                        disabled={isEditingMessage}
                        onClick={() => {
                          if (statePost === 3) {
                            setEditableMessage(!editableMessage);
                            if (editableMessage) handleEditMessage();
                            return;
                          }

                          props.editPost(props.post);
                          modalDismiss.click();
                          props.close();
                        }}>
                        {!editableMessage ? (
                          <i className="fas fa-fw fa-lg fa-pencil-alt feather" />
                        ) : (
                          <i className="fas fa-lg fa-check" />
                        )}
                      </button>
                    )}
                {statePost === 1 ? (
                  <button
                    className="button-like-a nav-link"
                    onClick={() => {
                      props.repairPost(props.post);
                      modalDismiss.click();
                      props.close();
                    }}>
                    <i className="fa fa-wrench" aria-hidden="true"></i>
                  </button>
                ) : null}
                {statePost === 1 &&
                (currentRole === 'superAdmin' ||
                  allowedEmails.includes(currentEmail)) ? (
                  <button
                    className="button-like-a nav-link"
                    onClick={() => {
                      props.rePost(props.post);
                      modalDismiss.click();
                      props.close();
                    }}>
                    <i className="fa fa-retweet" aria-hidden="true"></i>
                  </button>
                ) : null}
                {(statePost === 0 || statePost === 1) &&
                (currentRole === 'admin' || currentRole === 'superAdmin') ? (
                  <button
                    className="button-like-a nav-link"
                    onClick={() => {
                      props.getStatusPost();
                      props.carrouselModal(7);
                    }}>
                    <RestartIcon className="button-Restart" />
                  </button>
                ) : null}
                {statePost === 1 &&
                (currentRole === 'admin' || currentRole === 'superAdmin') ? (
                  <button
                    disabled={isEditingMessage}
                    className="button-like-a nav-link"
                    onClick={() => {
                      Swal.fire({
                        title: 'Delete Post',
                        html: `
                            <div class="mb-2">Are you sure you want delete ?</div>
                            <div class="form-check ${fbDisplay}">
                                <label class="form-check-label" for="fbcheckbox">
                                    <span class="fab fa-fw fa-facebook" style="color:#3b5998" />
                                </label>
                                <input type="checkbox" id="fbcheckbox" checked>
                                
                            </div>
                            <div class="form-check ${gmbDisplay}">
                                <label class="form-check-label" for="gmbcheckbox">
                                    <span class="fab fa-fw fa-google" style="color: #4284f5" />
                                </label>
                                <input type="checkbox" id="gmbcheckbox" checked>
                            </div>
                            <div class="form-check ${twDisplay}">
                                <label class="form-check-label" for="twcheckbox">
                                    <span class="fab fa-fw fa-twitter" style="color: #1da1f2" />
                                </label>
                                <input type="checkbox" id="twcheckbox" checked>
                            </div>
                          `,
                        showCloseButton: true,
                        showCancelButton: true,
                        preConfirm: () => {
                          const fbcheck =
                            Swal.getPopup().querySelector(
                              '#fbcheckbox'
                            ).checked;
                          const gmbcheck =
                            Swal.getPopup().querySelector(
                              '#gmbcheckbox'
                            ).checked;
                          const twcheckbox =
                            Swal.getPopup().querySelector(
                              '#twcheckbox'
                            ).checked;

                          return {
                            fbCheck: fbcheck,
                            gmbCheck: gmbcheck,
                            twCheck: twcheckbox
                          };
                        }
                      }).then((res) => {
                        if (res.value) {
                          props.deletePost(props.post.id, res.value);
                          modalDismiss.click();
                          props.close();
                        }
                      });
                    }}>
                    <i className="far fa-fw fa-lg fa-trash-alt feather" />
                  </button>
                ) : statePost !== 1 ? (
                  <button
                    className="button-like-a nav-link"
                    disabled={isEditingMessage}
                    onClick={() => {
                      Swal.fire({
                        title: 'Delete Post',
                        text: 'Are you sure you want delete',
                        showCloseButton: true,
                        showCancelButton: true
                      }).then((res) => {
                        if (res.value) {
                          props.deletePost(props.post.id, null);
                          modalDismiss.click();
                          props.close();
                        }
                      });
                    }}>
                    <i className="far fa-fw fa-lg fa-trash-alt feather" />
                  </button>
                ) : (
                  []
                )}
                <button
                  className="button-like-a nav-link"
                  disabled={isEditingMessage}
                  onClick={() => {
                    modalDismiss.click();
                    props.close();
                  }}
                  data-dismiss="modal">
                  <i className="fas fa-fw fa-lg fa-times feather" />
                </button>
              </nav>
            </div>
            <div className="modal-body">
              <div className="row row-sm">
                <div className="col-sm-6">
                  <label className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">
                    {statePost !== 3 ? 'Date' : 'scheduled for'}
                  </label>
                  <p className="event-start-date">
                    {statePost !== 3
                      ? formatDate(props?.post?.start)
                      : 'Each ' + formatDayWeek(props?.post?.SK)}
                  </p>
                  <label className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">
                    {statePost !== 3 ? 'Created By' : 'content strategy'}
                  </label>

                  <p>
                    {statePost !== 3 && props?.post?.createdBy
                      ? `${props?.post?.createdBy}`
                      : statePost !== 3
                      ? ''
                      : props?.contentStrategy?.strategyName || '--'}
                  </p>
                  {statePost === 3 && (
                    <>
                      <label className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03"></label>

                      <p></p>
                      <label className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03"></label>

                      <p></p>
                    </>
                  )}
                  {statePost !== 3 && (
                    <>
                      <label className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">
                        Created At
                      </label>
                      <p>
                        {props?.post?.createdAt
                          ? `${formatDate(props?.post?.createdAt)}`
                          : ''}
                      </p>
                    </>
                  )}
                </div>
                <div id="timestatus" className="col-sm-6">
                  <div>
                    <label className="tx-uppercase tx-sans tx-10 tx-medium tx-spacing-1 tx-color-03 mg-b-10">
                      {statePost !== 3 ? 'Current Time' : 'modified by '}
                    </label>
                    <br />
                    {statePost !== 3 ? (
                      <Clock
                        format={'HH:mm:ss'}
                        className="ml-2"
                        ticking={true}
                        timezone={getTimeZoneClock(props.time)}
                      />
                    ) : props?.post?.editedBy ? (
                      <p style={{ maxWidth: '70px' }}>{props.post?.editedBy}</p>
                    ) : (
                      <p>--</p>
                    )}
                    {statePost === 3 && (
                      <>
                        <label className="tx-uppercase tx-sans tx-10 tx-medium tx-spacing-1 tx-color-03 mg-b-10">
                          Modified at
                        </label>
                        <br />

                        {props?.post?.updatedAt ? (
                          <p style={{ maxWidth: '70px' }}>
                            {formatDate(props.post?.updatedAt / 1000)}
                          </p>
                        ) : (
                          <p>--</p>
                        )}
                      </>
                    )}
                  </div>
                  {contentStatus}
                </div>
              </div>
              {statePost !== 3 ? (
                <div className="d-flex flex-row">
                  <div
                    style={{
                      overflowY: 'auto',
                      width: '100%'
                    }}>
                    <label className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">
                      Description
                    </label>
                    <div className="flex-grow-1 flex-shrink-1">
                      <p className="mb-2 event-desc tx-gray-900 mg-b-40">
                        {props?.post?.title}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <label className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">
                    {statePost !== 3 ? (
                      'Description'
                    ) : (
                      <>
                        Prompt
                        {editableMessage && !isEditingMessage ? (
                          <button
                            style={{
                              border: 'none',
                              background: 'none',
                              marginLeft: '10px',
                              color: 'gray',
                              outline: 'none'
                            }}
                            onClick={() => {
                              handleEditMessage();
                            }}>
                            <i className="fas fa-lg fa-check" />
                          </button>
                        ) : null}
                        {isEditingMessage && (
                          <span style={{ marginLeft: '10px' }}>
                            <i className="fas fa-lg fa-spinner fa-spin" />
                          </span>
                        )}
                      </>
                    )}
                  </label>

                  <textarea
                    type="text"
                    disabled={!editableMessage || isEditingMessage}
                    placeholder="Enter your post message here"
                    value={editableMessage ? editedMessage : props?.post?.title}
                    style={{
                      borderRadius: '3px',
                      marginBottom: '2px',
                      minHeight: '125px',
                      maxHeight: '300px',
                      width: '100%',
                      resize: editableMessage ? 'vertical' : 'none',
                      border: editableMessage ? '1px solid #ced4da' : 'none',
                      backgroundColor: 'inherit'
                    }}
                    onChange={(e) => {
                      setEditedMessage(e.target.value);
                    }}
                  />
                </>
              )}
              {statePost === 3 ? (
                <div className="d-flex flex-row">
                  <div
                    style={{
                      overflowY: 'auto',
                      width: '100%'
                    }}>
                    <label className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">
                      output{' '}
                      {output && (
                        <button
                          className="button-like-a"
                          disabled={isEditingMessage}
                          onClick={async () => {
                            await regenPostContent();
                          }}>
                          <i
                            className="fas fa-sync-alt"
                            aria-hidden="true"
                            style={{
                              color: 'gray'
                            }}
                          />
                        </button>
                      )}
                    </label>
                    <div className="flex-grow-1 flex-shrink-1">
                      <p className="mb-2 event-desc tx-gray-900 mg-b-40">
                        {output ? JSON.parse(output)?.message : '--'}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
              {statePost !== 3 ? (
                <div className={'content-vwimg'}>
                  {selectedPost?.images !== undefined ? (
                    <label className="tx-uppercase tx-sans tx-11 tx-medium tx-spacing-1 tx-color-03">
                      Image
                    </label>
                  ) : (
                    []
                  )}
                  {selectedPost?.images !== undefined ? (
                    selectedPost?.images?.length > 1 ? (
                      <ImagesCarousel images={selectedPost.images} />
                    ) : (
                      <div
                        src={
                          props?.post?.images !== undefined
                            ? props?.post?.images[0]['url']
                            : ''
                        }
                        className="event-imag"
                        style={{
                          backgroundImage: `url(${props.post['images'][0]['url']})`
                        }}
                      />
                    )
                  ) : (
                    []
                  )}
                </div>
              ) : (
                <div className={'content-vwprompt'}></div>
              )}
              <div className={'content-footer-view'}>
                <button
                  className={'btn btn-secondary pd-x-20'}
                  disabled={isEditingMessage}
                  data-dismiss="modal"
                  ref={(button) => {
                    setModalDismiss(button);
                  }}
                  onClick={() => {
                    props.close();
                  }}>
                  Close
                </button>
                {statePost === 3 && output && (
                  <button
                    className={'btn btn-warning pd-x-20'}
                    disabled={isEditingMessage}
                    onClick={() => {
                      axios
                        .post(API_POSTS_EDIT, output)
                        .then((response) => {
                          props.close();
                          modalDismiss.click();
                          props.handleShowPrompts();
                        })
                        .catch((error) => {
                          if (error) {
                            Swal.fire({
                              title: 'error',
                              text: 'We have trouble scheduling the publication'
                            });
                          }
                          console.error(error);
                        });
                    }}>
                    Confirm
                  </button>
                )}
                {statePost !== 3 && (
                  <div className="d-flex">
                    <Links
                      selectedClient={props.selectedClient}
                      userInfo={props.userRole}
                      facebook={facebook}
                      facebookResponse={facebookResponse}
                      googleMyBusiness={googleMyBusiness}
                      gmbResponse={gmbResponse}
                      twitter={twitter}
                      twResponse={twResponse}
                      instagram={instagram}
                      igResponse={igResponse}
                      statePost={statePost}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {props.post &&
      getIdxPost() < props.posts.length - 1 &&
      !isEditingMessage &&
      !editableMessage ? (
        <button
          className={`bnt-r-follow`}
          onClick={() => {
            props.carrouselModal(1);
          }}>
          <NextIcon className="icon-modal" />
        </button>
      ) : null}
    </div>
  );
};

export default ViewPostsInCalendar;
