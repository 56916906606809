import React from 'react';
import Image from '../../components/StrategyImages/Image';

function ImageGallery({
  isPage = false,
  images,
  onDeleteImage,
  strategyName = null
}) {
  return (
    <>
      <div
        style={{
          border: '0px',
          height: '120px',
          color: 'gray',
          fontSize: '16px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'flex-end'
        }}>
        {strategyName
          ? `Images for "${strategyName}" Strategy`
          : 'No images found'}
      </div>
      <div
        className="card"
        style={{
          maxHeight: isPage ? '100%' : '59vh',
          border: 'none',
          width: '95vw',
          marginLeft: '2.5vw'
        }}>
        <div
          className="card-body"
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            overflowX: 'hidden'
          }}>
          {images.length > 0 ? (
            images.map((img, idx) => {
              return (
                <div key={idx + img?.SK}>
                  <div>
                    <div>
                      <Image
                        onDeleteImage={onDeleteImage}
                        style={{
                          width: '18vw',
                          marginLeft: '20px',
                          marginRight: '20px'
                        }}
                        img={img}
                      />
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <>
              {Array.from({ length: 8 }).map((item, idx) => (
                <div
                  key={idx}
                  style={{
                    width: '20vw',
                    margin: '20px',
                    minHeight: '14vw',
                    backgroundColor: '#E7E7E7',
                    borderRadius: '5px'
                  }}></div>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ImageGallery;
