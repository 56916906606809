import React from 'react';
import { useState, useEffect } from 'react';

import { API_GET_LINKS } from '../../utils';

const Links = (props) => {
  const [links, setLinks] = useState(null);

  useEffect(() => {
    if (!links) {
      const clientSocialNetworksLinks = async (clientId) => {
        try {
          const { Links } = await (
            await fetch(API_GET_LINKS + new URLSearchParams({ clientId }))
          ).json();
          if (links === null) {
            setLinks(Links);
          }
        } catch (error) {
          console.error('The link have an error', error);
        }
      };
      if (props.selectedClient.id && links === null) {
        clientSocialNetworksLinks(props.selectedClient.id);
      }
    }
  }, [props.selectedClient.id, links]);

  return (
    <div className="d-flex">
      {!props.facebook ? (
        ''
      ) : (
        <div>
          <a target="n_blank" href={links?.Facebook_URL}>
            <i
              className="fab fa-2x fa-fw fa-facebook"
              style={{ color: '#3b5998' }}
            />
          </a>

          {props.statePost !== 1 ? (
            ''
          ) : props.facebookResponse ? (
            <i
              className="fas fa-2x fa-fw fa-check"
              style={{ color: '#008000' }}
            />
          ) : (
            <i
              className="fas fa-2x fa-fw fa-times"
              style={{ color: '#b22222' }}
            />
          )}
        </div>
      )}
      {!props.googleMyBusiness ? (
        ''
      ) : (
        <div>
          <a
            target="n_blank"
            href={
              links?.Google_Business_Listing_URL
                ? links?.Google_Business_Listing_URL
                : links?.GMB_Google_2_URL
            }>
            <i
              className="fab fa-2x fa-fw fa-google"
              style={{ color: '#4284f5' }}
            />
          </a>

          {props.statePost !== 1 ? (
            ''
          ) : props.gmbResponse ? (
            <i
              className="fas fa-2x fa-fw fa-check"
              style={{ color: '#008000' }}
            />
          ) : (
            <i
              className="fas fa-2x fa-fw fa-times"
              style={{ color: '#b22222' }}
            />
          )}
        </div>
      )}
      {!props.twitter ? (
        ''
      ) : (
        <div>
          <a target="n_blank" href={links?.Twitter_URL}>
            <i
              className="fab fa-2x fa-fw fa-twitter"
              style={{ color: '#1da1f2' }}
            />
          </a>
          {props.statePost !== 1 ? (
            ''
          ) : props.twResponse ? (
            <i
              className="fas fa-2x fa-fw fa-check"
              style={{ color: '#008000' }}
            />
          ) : (
            <i
              className="fas fa-2x fa-fw fa-times"
              style={{ color: '#b22222' }}
            />
          )}
        </div>
      )}
      {!props.instagram ? (
        ''
      ) : (
        <div>
          <a target="n_blank" href={links?.Instagram_URL}>
            <i
              className="fab fa-2x fa-fw fa-instagram"
              style={{ color: '#8A3AB9' }}
            />
          </a>
          {props.statePost !== 1 ? (
            ''
          ) : props.igResponse ? (
            <i
              className="fas fa-2x fa-fw fa-check"
              style={{ color: '#008000' }}
            />
          ) : (
            <i
              className="fas fa-2x fa-fw fa-times"
              style={{ color: '#b22222' }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Links;
