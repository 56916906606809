import React from 'react';
import './Tag.css';

const Tag = ({ tagtext, setTags }) => {
  const handleDelete = () => {
    setTags((tags) => tags.filter((tag) => tag !== tagtext));
  };
  return (
    <>
      <li className="tag">
        {tagtext}
        <span
          onClick={handleDelete}
          className="remove-tag"
          data-role="remove-tag"></span>
      </li>
    </>
  );
};

export default Tag;
