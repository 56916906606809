import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { REACT_APP_API_NOTIFICATIONS } from '../../../utils/constants';

export const NotificationsGridItem = ({ notification, handleTotal }) => {
  const [isRead, setIsRead] = useState(notification.is_read);

  const handleReadMessage = async (notificationId) => {
    setIsRead(true);
    const options = {
      method: 'PUT',
      body: JSON.stringify({
        id: notificationId
      })
    };

    if (!isRead) {
      handleTotal();
    }
    await fetch(REACT_APP_API_NOTIFICATIONS, options);
  };

  return (
    <Link
      onClick={() => handleReadMessage(notification.id)}
      to={`/logs`}
      className="nav-link">
      <div className="media">
        <div
          className={
            'avatar avatar-sm ' + (!isRead ? 'avatar-online' : 'avatar-offline')
          }></div>
        <div className="media-body mg-l-15">
          <p dangerouslySetInnerHTML={{ __html: notification.title }} />
          <span>
            {notification.createdAt} by {notification.author}
          </span>
        </div>
      </div>
    </Link>
  );
};
