import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
};
const firebaseTwitterConfig = {
  apiKey: process.env.REACT_APP_TWITTER_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_TWITTER_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_TWITTER_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_TWITTER_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_TWITTER_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_TWITTER_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_TWITTER_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_TWITTER_FIREBASE_MEASSUREMENT_ID
};

export const myFirebase = firebase.initializeApp(firebaseConfig);
export const myTwitterFirebase = firebase.initializeApp(
  firebaseTwitterConfig,
  'Ohhh yes'
);
export const dbFirestore = myFirebase.firestore();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const twitterProvider = new firebase.auth.TwitterAuthProvider();
export const firebaseAuth = firebase.auth();
