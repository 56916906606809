import React from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

export const ItemCustomers = ({ customer }) => {
  return (
    <ListItem>
      <ListItemText>
        <Link to={{ pathname: `/client/${customer?.accountName}` }}>
          {customer?.accountName}
        </Link>
      </ListItemText>
    </ListItem>
  );
};
