const initialState = {
  shouldGenerateContent: false
};

export const generalStateReducer = (state = initialState, action) => {
  switch (action?.type) {
    case 'general/ia':
      return {
        ...state,
        shouldGenerateContent: true
      };
    case 'general/reset':
      return {
        ...state,
        shouldGenerateContent: false
      };
    default:
      return state;
  }
};
