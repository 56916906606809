import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Done from './Done';
import ScheduleColumn from './ScheduleColumn';
import Unassign from './Unassign';

const sortAssignment = (rowA, rowB) => {
  if (rowA?.assignment?.email === null && rowB?.assignment?.email === null) {
    return 0;
  }

  if (rowA?.assignment?.email !== null && rowB?.assignment?.email === null) {
    return -1;
  }

  if (rowB?.assignment?.email !== null && rowA?.assignment?.email === null) {
    return 1;
  }

  const a = rowA?.assignment?.name?.toLowerCase();
  const b = rowB?.assignment?.name?.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const renderColumns = (open, handleAssignModal) => {
  return [
    {
      name: 'Client Name',
      selector: (row) => row?.clientName,
      cell: (row) => (
        <Link to={`/calendar/${row?.clientName}`}>{row?.clientName}</Link>
      ),
      sortable: true
    },
    {
      name: 'Last Post Scheduled',
      selector: (row) => row?.lastScheduledPost,
      cell: (row) => <ScheduleColumn>{row?.lastScheduledPost}</ScheduleColumn>,
      sortable: true
    },
    {
      name: 'Number Posts Scheduled',
      selector: (row) => row?.totalPosts,
      cell: (row) => row?.totalPosts,
      sortable: true
    },
    {
      name: 'Assigned',
      selector: (row) => row?.assignment?.name,
      sortFunction: sortAssignment,
      cell: (row) => (
        <>
          {row?.assignment?.email && row?.assignment?.name ? (
            <>
              {row?.assignment?.name}
              <Unassign client={row} />
            </>
          ) : (
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => handleAssignModal(open, row)}
              style={{ backgroundColor: '#3766A8' }}>
              Assign To
            </Button>
          )}
        </>
      ),
      sortable: true
    },
    {
      name: 'Done',
      cell: (row) => <Done client={row} />
    }
  ];
};
