import React, { useState } from 'react';
import './styles.css';

const InfoField = ({ title, children, isEditing, onFieldChange, canEdit }) => {
  const [localValue, setLocalValue] = useState(children);

  // MinusIcon is used for empty fields
  const isMinusIcon =
    children.props?.className?.includes('fas fa-minus') ||
    (typeof children === 'string' && children === 'fas fa-minus');

  const handleInputChange = (e) => {
    const newValue = e.target.value;

    if (isMinusIcon) {
      setLocalValue(localValue);
    }

    if (isEditing && canEdit && !isMinusIcon) {
      setLocalValue(newValue);
      onFieldChange(newValue);
    }
  };

  return (
    <div className="info-field">
      <div>
        <div>
          <strong>{title}:</strong>
        </div>
      </div>
      <div style={{ marginTop: '14px' }}>
        {isEditing && canEdit && !isMinusIcon ? (
          <>
            <input
              type="text"
              value={localValue}
              onChange={handleInputChange}
              className="form-control"
            />
          </>
        ) : (
          <div>
            {isEditing && canEdit && isMinusIcon ? (
              <>
                <input
                  type="text"
                  value={''}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </>
            ) : (
              <>
                <span>{localValue}</span>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default InfoField;
