import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Filter from '../Filter';
import AutoAssignmentModal from './AutoAssignmentModal';
import MonthlyReportModal from './MonthlyReportModal';
import DailyReportModal from './DailyReportModal';
import { REPORTS_FLAG } from '../../../utils/constants';

const AssignmentHeader = ({ filterText, handleFilterText, searchBar }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const [openContentCreatorModal, setOpenContentCreatorModal] = useState(false);
  const [openMonthlyReportModal, setOpenMonthlyReportModal] = useState(false);
  const [openDailyReportModal, setOpenDailyReportModal] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const handleContentCreatorsModal = useCallback((open) => {
    setOpenContentCreatorModal(!open);
  }, []);

  const handleMonthlyReportModal = useCallback((open) => {
    setOpenMonthlyReportModal(!open);
  }, []);

  const handleDailyReportModal = useCallback((open) => {
    setOpenDailyReportModal(!open);
  }, []);

  const filterComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        handleFilterText('');
      }
    };
    return (
      <Filter
        onFilter={(e) => handleFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, handleFilterText, resetPaginationToggle]);

  return (
    <>
      <section className="content content-fixed content-profile p-0">
        <div className="df-table justify-content-end shadow-sm">
          <>{searchBar && filterComponentMemo}</>
          {(userInfo.role === 'superAdmin' || userInfo.role === 'admin') &&
            REPORTS_FLAG === 'ON' && (
              <Button
                variant="contained"
                size="small"
                color="primary"
                className="ml-2 p-2"
                style={{
                  backgroundColor: '#3766A8',
                  fontSize: 11
                }}
                onClick={() => handleDailyReportModal(openDailyReportModal)}>
                Daily Report
              </Button>
            )}
          {(userInfo.role === 'superAdmin' || userInfo.role === 'admin') &&
            REPORTS_FLAG === 'ON' && (
              <Button
                variant="contained"
                size="small"
                color="primary"
                className="ml-2 p-2"
                onClick={() => handleMonthlyReportModal(openMonthlyReportModal)}
                style={{
                  backgroundColor: '#3766A8',
                  fontSize: 11
                }}>
                Monthly Report
              </Button>
            )}
          {userInfo.role === 'superAdmin' && (
            <Button
              variant="contained"
              size="small"
              color="primary"
              className="ml-2 p-2"
              onClick={() =>
                handleContentCreatorsModal(openContentCreatorModal)
              }
              style={{
                backgroundColor: '#3766A8',
                fontSize: 11
              }}>
              Content creators for auto assignment
            </Button>
          )}
        </div>
      </section>
      <AutoAssignmentModal
        open={openContentCreatorModal}
        handleModal={handleContentCreatorsModal}
      />
      <MonthlyReportModal
        open={openMonthlyReportModal}
        handleModal={handleMonthlyReportModal}
      />
      <DailyReportModal
        open={openDailyReportModal}
        handleModal={handleDailyReportModal}
      />
    </>
  );
};

AssignmentHeader.protoType = {
  searchBar: PropTypes.bool.isRequired
};

AssignmentHeader.defaultProps = {
  searchBar: false
};

export default AssignmentHeader;
