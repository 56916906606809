import React, { useState, useEffect } from 'react';
import './styles.css';

const OpeningDateField = ({
  title,
  openingDate,
  isEditing,
  onFieldChange,
  canEdit,
  children
}) => {
  const [localValue, setLocalValue] = useState(children);
  const [localYear, setLocalYear] = useState(openingDate?.year || '');
  const [localMonth, setLocalMonth] = useState(openingDate?.month || '');
  const [localDay, setLocalDay] = useState(openingDate?.day || '');

  // MinusIcon is used for empty fields
  const isMinusIcon =
    children.props?.className?.includes('fas fa-minus') ||
    (typeof children === 'string' && children === 'fas fa-minus');

  useEffect(() => {
    if (
      localYear !== openingDate?.year ||
      localMonth !== openingDate?.month ||
      localDay !== openingDate?.day
    ) {
      onFieldChange({ year: localYear, month: localMonth, day: localDay });
    }
  }, [localYear, localMonth, localDay, openingDate]);

  const handleInputChange = (e, field) => {
    const newValue = e.target.value;

    if (isMinusIcon) {
      setLocalValue(localValue);
    }

    if (isEditing && canEdit && !isMinusIcon) {
      switch (field) {
        case 'year':
          setLocalYear(newValue);
          break;
        case 'month':
          setLocalMonth(newValue);
          break;
        case 'day':
          setLocalDay(newValue);
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className="info-field">
      <div>
        <div>
          <strong>{title}:</strong>
        </div>
      </div>
      <div style={{ marginTop: '14px' }}>
        {isEditing && canEdit && !isMinusIcon ? (
          <div className="openingDate">
            <div>
              <label>Year:</label>
              <input
                type="text"
                value={localYear}
                onChange={(e) => handleInputChange(e, 'year')}
                className="form-control"
              />
            </div>
            <div>
              <label>Month:</label>
              <input
                type="text"
                value={localMonth}
                onChange={(e) => handleInputChange(e, 'month')}
                className="form-control"
              />
            </div>
            <div>
              <label>Day:</label>
              <input
                type="text"
                value={localDay}
                onChange={(e) => handleInputChange(e, 'day')}
                className="form-control"
              />
            </div>
          </div>
        ) : (
          <div>
            {isEditing && canEdit && isMinusIcon ? (
              <div className="flex">
                <label>Year:</label>
                <input
                  type="text"
                  value={''}
                  onChange={(e) => handleInputChange(e, 'year')}
                  className="form-control"
                />
                <br />
                <label>Month:</label>
                <input
                  type="text"
                  value={''}
                  onChange={(e) => handleInputChange(e, 'month')}
                  className="form-control"
                />
                <br />
                <label>Day:</label>
                <input
                  type="text"
                  value={''}
                  onChange={(e) => handleInputChange(e, 'day')}
                  className="form-control"
                />
              </div>
            ) : (
              <>
                {!isMinusIcon && openingDate ? (
                  <>
                    <span>
                      {openingDate.year}/{openingDate.month}/{openingDate.day}
                    </span>
                  </>
                ) : (
                  <>
                    <span>{localValue}</span>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OpeningDateField;
