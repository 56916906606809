import React, { useState, useEffect } from 'react';
import './styles.css';

const DaysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
];

const Hours = ({
  title,
  children,
  isEditing,
  onFieldChange,
  canEdit,
  googleProfileInfo
}) => {
  const [localValue, setLocalValue] = useState(children);
  const [localHours, setLocalHours] = useState(googleProfileInfo);

  const defaultHours = {
    periods: Object.fromEntries(
      DaysOfWeek.map((day) => [
        day,
        {
          openDay: day,
          openTime: { hours: 8 },
          closeDay: day,
          closeTime: { hours: 17 }
        }
      ])
    )
  };

  // useEffect(() => {
  //   if (googleProfileInfo) {
  //     // const hours = completeDefaultHours(googleProfileInfo);
  //     setLocalHours(googleProfileInfo);
  //   }
  // }, [googleProfileInfo]);

  // MinusIcon is used for empty fields
  const isMinusIcon =
    children.props?.className?.includes('fas fa-minus') ||
    (typeof children === 'string' && children === 'fas fa-minus');

  const handleInputChange = (e, day, timeType) => {
    const newValue = e.target.value;

    if (isMinusIcon) {
      setLocalValue(localValue);
    }

    if (isEditing && canEdit && !isMinusIcon) {
      setLocalHours((prevHours) => ({
        ...prevHours,
        periods: {
          ...prevHours.periods,
          [day]: {
            ...prevHours.periods[day],
            [timeType]: {
              hours: parseInt(newValue)
            }
          }
        }
      }));
      onFieldChange(localHours);
    }
  };

  // const handleInputChange = (e, day, timeType) => {
  //   const newValue = e.target.value;

  //   console.log('Input changed:', day, timeType, newValue);

  //   if (isMinusIcon) {
  //     setLocalValue(localValue);
  //   }

  //   if (isEditing && canEdit && !isMinusIcon) {
  //     // const updatedHours = {
  //     //   ...localHours,
  //     //   periods: {
  //     //     ...localHours.periods,
  //     //     [day]: {
  //     //       ...localHours.periods[day],
  //     //       [timeType]: {
  //     //         hours: parseInt(newValue)
  //     //       }
  //     //     }
  //     //   }
  //     // };
  //     // console.log('Updated hours:', updatedHours);
  //     setLocalHours(localHours);
  //     onFieldChange(localHours);
  //   }
  // };

  return (
    <div className="info-field">
      <div>
        <div>
          <strong>{title}:</strong>
        </div>
      </div>
      <div style={{ marginTop: '14px' }}>
        {isEditing && canEdit && !isMinusIcon ? (
          <div>
            {Object.keys(localHours?.periods).map((day, index) => (
              <div key={index} className="hours">
                <div className="label">
                  <label>{day.charAt(0).toUpperCase() + day.slice(1)}:</label>
                </div>
                <div>
                  <input
                    type="number"
                    value={localHours?.periods[day].openTime.hours}
                    onChange={(e) => handleInputChange(e, day, 'openTime')}
                    className="form-control"
                  />
                </div>
                <span> - </span>
                <div>
                  <input
                    type="number"
                    value={localHours?.periods[day].closeTime.hours}
                    onChange={(e) => handleInputChange(e, day, 'closeTime')}
                    className="form-control"
                  />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>
            {isEditing && canEdit && isMinusIcon ? (
              <div>
                <span>Chao</span>
                <div>
                  {Object.keys(localHours.periods).map((day, index) => (
                    <div key={index}>
                      <span>
                        {day.charAt(0).toUpperCase() + day.slice(1)}:{' '}
                        {localHours.periods[day].openTime.hours}:00 -{' '}
                        {localHours.periods[day].closeTime.hours}:00
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <>
                <span>{localValue}</span>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Hours;
