import Swal from 'sweetalert2';

export const loadingAlert = (callback) => {
  Swal.fire({
    title: 'Loading...',
    text: 'Please wait',
    allowOutsideClick: false,
    allowEscapeKey: false,
    onBeforeOpen: async () => {
      Swal.showLoading();
      return await callback();
    }
  });
};
