import { GET_REVIEWS_PER_CLIENT } from "../../../../../utils";
import axios from "axios";

export const getReviewsPerClient = async ( id, accessToken) => {
  const url = GET_REVIEWS_PER_CLIENT;

  try {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${url}id=${id}`,
      headers: { 
        'Authorization': `bearer ${accessToken}`,
        'Access-Control-Allow-Origin': '*'
      },
    };
    const { data } = await axios(config);
    return {
      loading: false,
      data: data
    };
  } catch (error) {
    console.error(error);
    return {
      loading: false,
      data: null
    };
  }
}