import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveList,
  getInactiveList
} from '../../../../../store/slices/assignments';
import { API_AUTO_ASSIGN } from '../../../../../utils/constants';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { errorAlert } from '../../../../../utils/alerts/errorAlert';

export const ItemContentCreator = ({
  id,
  name,
  email,
  status,
  cancelMouseEvents
}) => {
  const dispatch = useDispatch();
  const { inactive, active } = useSelector((state) => state.assignments);
  const [checked, setChecked] = useState(status === 'active' ? true : false);
  const [isLoading, setIsLoad] = useState(false);

  const findIndex = (contentCreators, id) => {
    return contentCreators.findIndex(
      (contentCreator) => contentCreator.id === id
    );
  };

  const handleAssign = async (id, status) => {
    setIsLoad(true);
    cancelMouseEvents(true);
    const checked = status === 'active' ? false : true;
    const contentCreator = {
      id,
      name,
      email,
      status: status === 'active' ? 'inactive' : 'active'
    };
    setChecked(checked);
    try {
      await fetch(API_AUTO_ASSIGN, {
        method: 'POST',
        body: JSON.stringify({
          id,
          status: checked
        })
      });

      if (status === 'active') {
        const index = findIndex(active, id);
        const newActive = active.filter(
          (element, activeIndex) => activeIndex !== index
        );
        dispatch(getActiveList(contentCreator, newActive));
      }

      if (status === 'inactive') {
        const index = findIndex(inactive, id);
        const newInactive = inactive.filter(
          (element, inactiveIndex) => inactiveIndex !== index
        );
        dispatch(getInactiveList(contentCreator, newInactive));
      }
    } catch (error) {
      console.error('Error: ', error);
      errorAlert('An error has ocurred, please try again');
    } finally {
      setIsLoad(false);
      setTimeout(cancelMouseEvents(false), 500);
      clearTimeout(cancelMouseEvents);
    }
  };

  return (
    <ListItem>
      <ListItemIcon>
        <Checkbox
          edge="start"
          color={'primary'}
          checked={checked}
          onChange={() => {
            handleAssign(id, status);
          }}
          onDoubleClick={() => null}
        />
      </ListItemIcon>
      <ListItemText primary={name} />
      {isLoading && <CircularProgress size={5} color={'primary'} />}
    </ListItem>
  );
};
