import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import DataTables from '../../components/DataTables';
import NewClientOnboarding from '../../components/NewClientOnboarding/NewClientOnboarding';
import NewFacebookPageConnection from '../../components/NewFacebookPageConnection';
import NewZohoConnection from '../../components/NewZohoConnection';
import NewGoogleConnection from '../../components/NewGoogleConnection';
import NewInstagramConnection from '../../components/NewInstagramConnection';
import NewPost from '../../components/NewPost';
import { setSelectedClient } from '../../store/slices/selectedClient';
import { twitterProvider, myTwitterFirebase } from '../../firebase/firebase';
import AppRegister from '../../components/AppRegister';
import { API_TWITTER_ACCOUNTS } from '../../utils';
import BulkModal from '../../components/BulkModal';
import { getClients } from '../../store/slices/clients';

const Dashboard = (props) => {
  const [currentClientId, setCurrentClientId] = useState(null);
  const [currentClientTimeZone, setCurrentClientTimeZone] = useState(null);
  const [currentClientBestTimes, setCurrentClientBestTimes] = useState(null);
  const [currentClientMergeFields, setCurrentClientMergeFields] =
    useState(null);
  const [currentClientSocialNetworks, setCurrentClientSocialNetworks] =
    useState([]);
  const [currentClient, setCurrentClient] = useState(null);
  const instagramPages = [];
  const [appRegister, setAppRegister] = useState({
    show: false,
    data: null
  });
  const [loading, setLoading] = useState(false);
  const [newPost, setNewPost] = useState({ show: false });
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const validNetworks = (socialnetworks) => {
    return (
      socialnetworks.googleMyBusiness ||
      socialnetworks.facebook ||
      socialnetworks.twitter ||
      socialnetworks.instagram
    );
  };
  const callBackClose = () => {
    setNewPost({ show: false });
  };

  const handleCalendarClick = (row) => {
    props.dispatch(setSelectedClient(row));
    let newUrl = row.clientName
      .replace(/\s+/g, '%20')
      .replace(/\//g, '%2F')
      .replace(/'/g, '%1D')
      .replace(/’/g, '%1E');
    props.history.push(`/calendar/${newUrl}`);
  };

  const clientActions = (cell, row) => {
    if (row.id) {
      let validAnySn = validNetworks(row?.clientSocialNetworks);
      return (
        <>
          <nav className="nav nav-icon-only d-flex justify-content-center">
            {row.clientSocialNetworks ? (
              <a
                href={validAnySn ? '#newPost' : ''}
                className="btn-icon"
                data-toggle="modal"
                data-animation="effect-scale"
                data-backdrop="static"
                data-keyboard="false"
                onClick={() => {
                  setNewPost({ show: true });
                  dataClientHandler(
                    row.id,
                    row.clientSocialNetworks,
                    row.timeZone,
                    row.bestTimes,
                    row.mergeFields
                  );
                }}>
                <i className="far fa-2x fa-fw fa-plus-square" />
              </a>
            ) : (
              <a
                href="#noSocialNetworkAvailable"
                className="btn-icon"
                data-toggle="modal"
                data-animation="effect-scale"
                data-backdrop="static"
                data-keyboard="false">
                <i className="far fa-2x fa-fw fa-plus-square" />
              </a>
            )}
            <button
              className="btn-icon button-like-a"
              type="button"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Go to client calendar"
              onClick={() => handleCalendarClick(row)}>
              <i className="fa fa-2x fa-fw fa-calendar-alt" />
            </button>

            <a
              onClick={() =>
                dataClientHandler(
                  row.id,
                  row.clientSocialNetworks,
                  row.timeZone,
                  row.bestTimes
                )
              }
              href={validAnySn ? '#BulkModal' : ''}
              className="btn-icon"
              data-toggle="modal"
              data-animation="effect-scale"
              data-backdrop="static"
              data-keyboard="false"
              title="Bulk posts">
              <i className="fa fa-2x fa-fw fa-file-excel" />
            </a>
          </nav>
        </>
      );
    }
  };

  const renderZohoIcon = (active) => {
    let iconStyle = {
      width: 23,
      height: 23,
      backgroundImage: `url(${
        active
          ? `${process.env.PUBLIC_URL}/assets/img/logo-crm.png`
          : `${process.env.PUBLIC_URL}/assets/img/logo-crm-disconnected.png`
      })`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    };
    return <div style={iconStyle} />;
  };

  const clientSocialNetworks = (cell, row) => {
    if (row.id) {
      return (
        <>
          <nav className="nav nav-icon-only d-flex justify-content-center">
            {row.clientSocialNetworks ? (
              <>
                <div className="dropdown dropdown-message">
                  <a
                    href="#newFacebookPageConnection"
                    className="dropdown-link new-indicator btn-icon"
                    style={
                      row.clientSocialNetworks.facebook
                        ? { color: '#3b5998' }
                        : { color: '#8392a5' }
                    }
                    data-toggle="modal"
                    data-animation="effect-scale"
                    onClick={() => {
                      setCurrentClientId(row.id);
                      setCurrentClient(row);
                    }}
                    data-backdrop="static"
                    data-keyboard="false">
                    <i className="fab fa-2x fa-fw fa-facebook" />
                    {row.clientSocialNetworks.fbFailing &&
                    row.clientSocialNetworks.facebook ? (
                      <span>!</span>
                    ) : null}
                  </a>
                </div>
                <div className="dropdown dropdown-message">
                  <a
                    href="#newGoogleConnection"
                    className="dropdown-link new-indicator btn-icon"
                    style={
                      row.clientSocialNetworks.googleMyBusiness
                        ? { color: '#4284F5' }
                        : { color: '#8392a5' }
                    }
                    data-toggle="modal"
                    data-animation="effect-scale"
                    onClick={() => {
                      setCurrentClientId(row.id);
                      setCurrentClient(row);
                    }}
                    data-backdrop="static"
                    data-keyboard="false">
                    <i className="fab fa-2x fa-fw fa-google" />
                    {row.clientSocialNetworks.gmbFailing &&
                    row.clientSocialNetworks.googleMyBusiness ? (
                      <span>!</span>
                    ) : null}
                  </a>
                </div>
                <div className="dropdown dropdown-message">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="#"
                    className="dropdown-link new-indicator btn-icon"
                    style={
                      row.clientSocialNetworks.twitter
                        ? { color: '#1DA1F2' }
                        : { color: '#8392a5' }
                    }
                    data-toggle="tooltip"
                    title="Edit client"
                    onClick={() => twitterAuthHandler(row.id)}>
                    <i className="fab fa-2x fa-fw fa-twitter" />
                    {row.clientSocialNetworks.twFailing &&
                    row.clientSocialNetworks.twitter ? (
                      <span>!</span>
                    ) : null}
                  </a>
                </div>
                <div className="dropdown dropdown-message">
                  <a
                    href="#newInstagramConnection"
                    className="dropdown-link new-indicator btn-icon"
                    style={
                      row.clientSocialNetworks.instagram
                        ? { color: '#8a3ab9' }
                        : { color: '#8392a5' }
                    }
                    data-toggle="modal"
                    data-animation="effect-scale"
                    onClick={() => {
                      setCurrentClientId(row.id);
                      setCurrentClient(row);
                    }}
                    data-backdrop="static"
                    data-keyboard="false">
                    <i className="fab fa-2x fa-fw fa-instagram" />
                    {row.clientSocialNetworks.igFailing &&
                    row.clientSocialNetworks.instagram ? (
                      <span>!</span>
                    ) : null}
                  </a>
                </div>
                <a
                  href="#newZohoConnection"
                  className="dropdown-link new-indicator btn-icon"
                  style={{
                    marginLeft: '5px',
                    marginTop: '3px'
                  }}
                  data-toggle="modal"
                  data-animation="effect-scale"
                  onClick={() => setCurrentClientId(row.id)}
                  data-backdrop="static"
                  data-keyboard="false">
                  <button
                    className="btn-icon button-like-a"
                    data-toggle="modal"
                    data-animation="effect-scale"
                    onClick={() => setCurrentClientId(row.id)}>
                    {renderZohoIcon(row.clientSocialNetworks?.zoho)}
                  </button>
                </a>
              </>
            ) : (
              <p style={{ color: 'red' }}>
                Error trying to load social networks, please contact support
              </p>
            )}
          </nav>
        </>
      );
    }
  };

  //show likes number if exist value
  const likesPerClient = (cell, row) => {
    return (
      <>
        {row.likesFacebook === undefined ||
        row.likesFacebook.likes === undefined
          ? ' - '
          : row.likesFacebook.likes}
      </>
    );
  };

  const ReachedPerFB = (cell, row) => {
    return (
      <>
        {row.reachedFacebook === undefined ||
        row.reachedFacebook.reached === undefined ? (
          ' - '
        ) : row.reachedFacebook.reachedPercentage > 0 ? (
          <span>
            {' '}
            {row.reachedFacebook.reached} (
            <span style={{ color: '#1ebf19' }}>&#x25B2;</span>{' '}
            {row.reachedFacebook.reachedPercentage} %)
          </span>
        ) : (
          <span>
            {' '}
            {row.reachedFacebook.reached} (
            <span style={{ color: '#fc1100' }}>&#x25BC;</span>{' '}
            {Math.abs(row.reachedFacebook.reachedPercentage)}%){' '}
          </span>
        )}
      </>
    );
  };

  const EngagementPerFB = (cell, row) => {
    return (
      <>
        {row.engagementFacebook === undefined ||
        row.engagementFacebook.engagement === undefined ? (
          ' - '
        ) : row.engagementFacebook.engagementPercentage > 0 ? (
          <span>
            {' '}
            {row.engagementFacebook.engagement} (
            <span style={{ color: '#1ebf19' }}>&#x25B2;</span>{' '}
            {row.engagementFacebook.engagementPercentage}%){' '}
          </span>
        ) : (
          <span>
            {' '}
            {row.engagementFacebook.engagement} (
            <span style={{ color: '#fc1100' }}>&#x25BC;</span>{' '}
            {Math.abs(row.engagementFacebook.engagementPercentage)}%){' '}
          </span>
        )}
      </>
    );
  };

  const clientName = (cell, row) => {
    if (row.id) {
      let newUrl = row.clientName
        .replace(/\s+/g, '%20')
        .replace(/\//g, '%2F')
        .replace(/'/g, '%1D')
        .replace(/’/g, '%1E');
      return (
        <div>
          <Link
            onClick={() => props.dispatch(setSelectedClient(row))}
            onMouseDown={() => props.dispatch(setSelectedClient(row))}
            to={{
              pathname: `/client/${newUrl}`,
              state: {
                clientId: row.id,
                clientGroups: row.clientGroups,
                socialNetworks: row['clientSocialNetworks']
              }
            }}>
            <span>{row['clientName']}</span>
          </Link>
        </div>
      );
    }
  };

  const renderAppIcon = (active) => {
    let iconStyle = {
      width: 30,
      height: 30,
      backgroundImage: `url(${
        active
          ? `${process.env.PUBLIC_URL}/assets/img/sms.png`
          : `${process.env.PUBLIC_URL}/assets/img/smsf.png`
      })`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    };
    return <div style={iconStyle} />;
  };

  const mobileApp = (cell, row) => {
    if (row.id) {
      return (
        <>
          <nav className="nav nav-icon-only d-flex justify-content-center">
            <button
              className="btn-icon button-like-a"
              style={{ marginRight: 10 }}
              onClick={() => setAppRegister({ show: true, data: row })}>
              {renderAppIcon(row.appActive)}
            </button>
            <div
              style={{
                width: 27,
                height: 30,
                margin: 0,
                position: 'relative',
                color: '#8493a5',
                textAlign: 'center'
              }}>
              <p>{typeof row.smsCount === 'undefined' ? '0' : row.smsCount}</p>
            </div>
            <div
              style={{
                width: 27,
                height: 30,
                margin: 0,
                color: '#8493a5',
                textAlign: 'center'
              }}>
              <p>{row.countYelp ? row.countYelp.clicks : 0}</p>
            </div>
            <div
              style={{
                width: 27,
                height: 30,
                margin: 0,
                color: '#8493a5',
                textAlign: 'center'
              }}>
              <p>{row.countGmb ? row.countGmb.clicks : 0}</p>
            </div>
          </nav>
        </>
      );
    }
  };
  // Update table only if differences un clients list

  // Fetch all clients to listen for changes
  const undercoverGetClients = async () => {
    setLoading(true);
    try {
      await props.dispatch(getClients());
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    undercoverGetClients();
    // eslint-disable-next-line
  }, []);

  const myCallback = (dataFromChild) => {
    undercoverGetClients();
  };

  const formatWithIcon = (cell, row) => {
    return (
      <span>
        <span>PAGE LIKES </span>
        <svg
          width="9px"
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="2 0 19 31"
          xmlns="http://www.w3.org/2000/svg">
          {' '}
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.5"
            d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"></path>
        </svg>
      </span>
    );
  };

  const clientColumns = [
    {
      dataField: 'id',
      text: 'Id',
      hidden: true
    },
    {
      dataField: 'clientName',
      text: 'Client Name',
      headerStyle: () => {
        return {
          width: '281px',
          textAlign: 'left'
        };
      },
      style: () => {
        return {
          paddingTop: '12px',
          textAlign: 'left'
        };
      },
      formatter: clientName
    },
    {
      dataField: 'clientGroups',
      text: 'Client Group',
      headerStyle: () => {
        return {};
      },
      style: () => {
        return {
          paddingTop: '12px'
        };
      }
    },
    {
      dataField: 'reachedFacebook',
      text: 'People Reached',
      headerStyle: () => {
        return {};
      },
      style: () => {
        return {
          paddingTop: '12px'
        };
      },
      formatter: ReachedPerFB
    },
    {
      dataField: 'engagementFacebook',
      text: 'Posts Engagements',
      headerStyle: () => {
        return {};
      },
      style: () => {
        return {
          paddingTop: '12px'
        };
      },
      formatter: EngagementPerFB
    },
    {
      dataField: 'likesPerClient',
      text: 'PAGE LIKES',
      headerStyle: () => {
        return {};
      },
      style: () => {
        return {
          paddingTop: '12px'
        };
      },
      formatter: likesPerClient,
      headerFormatter: formatWithIcon
    },
    {
      dataField: 'dummy-sn',
      text: 'Social Networks',
      formatter: clientSocialNetworks,
      headerStyle: () => {
        return {
          width: '200px',
          textAlign: 'center'
        };
      }
    },
    {
      dataField: 'dummy-actions',
      text: 'Actions',
      formatter: clientActions,
      headerStyle: () => {
        return {
          width: '150px',
          textAlign: 'center'
        };
      }
    },
    {
      dataField: 'appActive',
      text: 'App - #SMS  Yelp  GMB',
      headerStyle: () => {
        return { width: '160px', textAlign: 'center' };
      },
      formatter: mobileApp
    }
  ];

  const twitterAuthHandler = (clientId) => {
    twitterProvider.setCustomParameters({
      force_login: 'true'
    });

    myTwitterFirebase
      .auth()
      .signInWithPopup(twitterProvider)
      .then(function (result) {
        const newClientUrl = API_TWITTER_ACCOUNTS;

        const clientDataSaved = {
          user_id: result.additionalUserInfo.profile.id_str,
          clientId: clientId,
          screen_name: result.additionalUserInfo.username,
          oauth_token: result.credential.accessToken,
          oauth_token_secret: result.credential.secret
        };

        axios({
          method: 'post',
          url: newClientUrl,
          data: clientDataSaved
        })
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'The twitter account was set successfully.'
              }).then(() => {
                myCallback();
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'The twitter account was not set successfully due an error.'
            });
          });
      })
      .catch(function (error) {
        // Handle Errors here.
        console.error(error);
      });
  };

  const connectSnCallback = () => {
    myCallback();
  };

  const dataClientHandler = (
    clientId,
    socialnetworks,
    timeZone,
    bestTimes,
    mergeFields
  ) => {
    setCurrentClientId(clientId);
    setCurrentClientSocialNetworks(socialnetworks);
    setCurrentClientTimeZone(timeZone);
    setCurrentClientBestTimes(bestTimes);
    setCurrentClientMergeFields(mergeFields);
    let validSn = validNetworks(socialnetworks);
    if (!validSn) {
      Swal.fire({
        type: 'error',
        title: 'Error!',
        text: 'Sync Any Social Network.'
      });
    }
  };
  const userRole = props.role;

  return (
    <>
      <Header loading={props.loadingClients || loading} />
      <div
        className="content content-fixed"
        style={{
          height: 'calc(100vh - 60px)',
          overflow: 'auto',
          padding: '0 1px 0 1px'
        }}>
        <DataTables
          buttonsCallback={connectSnCallback}
          userRole={userRole}
          data={props.customerList}
          columns={clientColumns}
          noDataIndication={'No Clients'}
          handleModal={handleShow}
          showTotal={true}
        />
      </div>

      <NewClientOnboarding
        callbackFromParent={myCallback}
        showModal={showModal}
        handleClose={handleClose}
      />
      <NewFacebookPageConnection
        currentClientId={currentClientId}
        currentClient={currentClient}
        connectSnCallback={connectSnCallback}
      />
      <NewZohoConnection
        currentClientId={currentClientId}
        currentClient={currentClient}
        connectSnCallback={connectSnCallback}
      />
      <NewGoogleConnection
        currentClientId={currentClientId}
        currentClient={currentClient}
        connectSnCallback={connectSnCallback}
      />
      <NewInstagramConnection
        currentClientId={currentClientId}
        currentClient={currentClient}
        instagramPages={instagramPages != null}
        connectSnCallback={connectSnCallback}
      />
      {newPost.show && (
        <NewPost
          show={newPost.show}
          currentClientId={currentClientId}
          currentClientSocialNetworks={currentClientSocialNetworks}
          currentClientTimeZone={currentClientTimeZone}
          currentClientBestTimes={currentClientBestTimes}
          currentClientMergeFields={currentClientMergeFields}
          currentCustomerName={props.customerName}
          callBackClose={callBackClose}
        />
      )}

      {appRegister.show && (
        <AppRegister
          data={appRegister.data}
          onUpdate={() => {
            myCallback();
          }}
          handleClose={() => setAppRegister({ appRegister: { show: false } })}
        />
      )}
      {/* <NoSocialNetworkAvailable /> */}
      <BulkModal
        title={'posts'}
        currentClientId={currentClientId}
        currentClientSocialNetworks={currentClientSocialNetworks}
        currentClientTimeZone={currentClientTimeZone}
        currentCustomerName={props.customerName}
      />
      <Footer />
    </>
  );
};

function mapStateToProps(state) {
  let { errorLogin, loading, userInfo } = state.auth;
  let { loadingClients, errorClients, customerList } = state.clients;
  return {
    customerName: userInfo?.displayName,
    customerEmail: userInfo?.email,
    customerPhoto: userInfo?.photoURL,
    role: userInfo?.role,
    isLoggingOut: loading,
    logoutError: errorLogin,
    loadingClients,
    errorClients,
    customerList
  };
}
export default connect(mapStateToProps)(Dashboard);
