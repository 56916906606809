import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { useFetchImages } from '../../../../hooks/useFetchImages';
import { getPixabayImages } from '../../../../utils/api-images/getPixabayImages';
import { useSearchQuery } from '../../../../hooks/useSearchQuery';
import getUrlImage from '../../../../utils/api-images/getUrlImage';
import SectionTitle from './Partials/SectionTitle';
import LoadingSpinner from './Partials/LoadingSpinner';
import ImagesContainer from './Partials/ImagesContainer';
import Image from './Partials/Image';
import DescriptionSection from './Partials/DescriptionSection';

const Pixabay = ({ setAppImage }) => {
  const [query, handleInputQuery] = useSearchQuery();
  const [detail, setDetail] = useState(null);
  const [{ images, loading }, more] = useFetchImages(query, getPixabayImages);

  const handleSelect = (img) => {
    setDetail({
      name: img.user,
      authorPage: img.pageURL
    });
    const url = img.largeImageURL;
    getUrlImage(url, setAppImage);
  };

  useEffect(() => {
    return () => {
      setDetail('');
    };
  }, []);

  return (
    <>
      <main className="card card-app-photos pd-5">
        <SectionTitle title={'Pixabay Gallery'} />
        <TextField
          id="search"
          label="Search"
          value={query}
          onChange={handleInputQuery}
          className="ml-2"
          autoComplete="off"
        />
        <ImagesContainer more={more}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div className="row">
              {images.map((img, index) => {
                return (
                  <Image
                    key={index}
                    alt={img.user}
                    src={img.largeImageURL}
                    userPage={img.pageURL}
                    selectImage={() => handleSelect(img)}
                  />
                );
              })}
            </div>
          )}
        </ImagesContainer>
      </main>
      <DescriptionSection
        title={'Pixabay Gallery Description'}
        detail={detail}
      />
    </>
  );
};

export default Pixabay;
