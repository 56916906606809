import React from 'react';
import NoteContent from '../../../components/Notes/NoteContent';

const NotesSection = ({ id }) => {
  return (
    <div className="card" id="posts-section">
      <div className="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-between">
        <h6 className="tx-uppercase tx-semibold mg-b-0">Notes</h6>
      </div>
      <div className="card-body pd-25">
        <NoteContent id={id} />
      </div>
    </div>
  );
};

export default NotesSection;
