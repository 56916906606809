import React, { useEffect, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { API_GET_CONTENT_STRATEGIES } from '../../../utils/constants';
import axios from 'axios';
import './ContentStrategy.css';
import NewClientOnboarding from '../../../components/NewClientOnboarding/NewClientOnboarding';

const ContentStrategiesSection = ({ userData }) => {
  const [name, setName] = useState('');
  const [showModal, setShowModal] = useState();
  const [defaultData, setDefaultData] = useState({});

  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setShowModal(false);
    setDefaultData({});
  };
  useEffect(() => {
    axios
      .get(`${API_GET_CONTENT_STRATEGIES}?id=${userData?.contentStrategy}`, {})
      .then((res) => {
        setName(res?.data?.contentStrategies?.strategyName);
      });
  }, [userData?.contentStrategy]);

  return (
    <div className="card" id="posts-section">
      <div className="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-between">
        <h6 className="tx-semibold mg-b-0">Content Strategies</h6>{' '}
      </div>
      <div className="pd-25" style={{ height: '150px' }}>
        <h6>Current Content Strategy</h6>

        <p style={{ color: '#8392A5' }}>
          <>
            {userData?.contentStrategy ? <i>{name}</i> : 'None'}{' '}
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleShow(true);
                setDefaultData({ ...userData });
              }}>
              <EditIcon style={{ color: '#3B5998' }} />
            </span>
          </>
        </p>
      </div>
      <div className="card-footer pd-y-15 pd-x-20 d-flex align-items-center justify-content-between"></div>
      <NewClientOnboarding
        showModal={showModal}
        defaultData={{ ...defaultData }}
        handleClose={handleClose}
        callbackFromParent={() => {}}
      />
    </div>
  );
};

export default ContentStrategiesSection;
