import React from 'react';
import Select from 'react-select';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

const SelectContentCreator = ({
  loading,
  contentCreators,
  assignContentCreator,
  height,
  all
}) => {
  const customStyles = {
    control: (base) => ({
      ...base,
      height,
      minHeight: 35
    })
  };

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center">
          <CircularProgress size={30} />
        </div>
      ) : (
        <Select
          styles={customStyles}
          options={contentCreators.map((contentCreator) => ({
            value: contentCreator.email,
            label: contentCreator.name
          }))}
          onChange={(option) =>
            assignContentCreator({ email: option?.value, name: option?.label })
          }
          isSearchable
          isClearable
          defaultValue={
            all && {
              value: 'All',
              label: 'All'
            }
          }
        />
      )}
    </>
  );
};

SelectContentCreator.propTypes = {
  height: PropTypes.number,
  loading: PropTypes.bool,
  contentCreators: PropTypes.array,
  assignContentCreator: PropTypes.func,
  all: PropTypes.bool
};

SelectContentCreator.defaultProps = {
  height: 38
};

export default SelectContentCreator;
