import { API_GET_NOTES } from '../../../../utils';

export const getNotesInfo = async (
  clientId,
  accessToken,
  contentGuideline = false
) => {
  const url = API_GET_NOTES;

  try {
    const { Note } = await (
      await fetch(url + new URLSearchParams({ clientId, contentGuideline }), {
        method: 'GET',
        headers: {
          Authorization: `bearer ${accessToken}`
        }
      })
    ).json();

    if (contentGuideline) {
      const { Content_Guidelines_Notes, Account_Status } = Note;
      return { Content_Guidelines_Notes, Account_Status };
    }

    return {
      loading: false,
      data: Note
    };
  } catch (error) {
    console.error('An unknown error occurred while fetching notes info', error);
    return {
      loading: false,
      data: null
    };
  }
};
