import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { authSelector } from '../../../store/slices/auth';
import { assignCreator } from './services';
import { errorAlert } from '../../../utils/alerts/errorAlert';
import { FilterContext } from '../../../contexts/FilterContext';
import { loadingAlert } from '../../../utils/alerts/loadingAlert';
import { successAlert } from '../../../utils/alerts/successAlert';
import { useContentCreators } from '../SelectContentCreators/services';
import SelectContentCreator from '../SelectContentCreators';

const AssignmentModal = ({ client, open, handleModal }) => {
  const { contentCreator, ...selectContentCreatorProps } =
    useContentCreators(open);
  const { userInfo } = useSelector(authSelector);
  const {
    clientsTableData: { clients },
    getClientsData
  } = useContext(FilterContext);

  const handleAssignmentContentCreator = async (contentCreator, client) => {
    if (contentCreator && client) {
      handleModal(open, null);
      loadingAlert(async () => {
        const response = await assignCreator(
          client.id,
          contentCreator,
          userInfo.displayName
        );
        if (response.ok) {
          successAlert('Content creator was assigned successfully');
          getClientsData(clients, {
            ...client,
            assignment: {
              email: contentCreator.email,
              name: contentCreator.name,
              done: false
            }
          });
          return;
        }
        errorAlert('An error has ocurred, please try again...');
        return;
      });
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={() => handleModal(open, null)}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ style: { overflowY: 'visible' } }}>
      <DialogTitle id="alert-dialog-title">Content Creators</DialogTitle>
      <DialogContent style={{ overflowY: 'visible' }}>
        <SelectContentCreator {...selectContentCreatorProps} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleModal(open, null)}
          variant="outlined"
          color="inherit">
          Close
        </Button>
        <Button
          onClick={() => handleAssignmentContentCreator(contentCreator, client)}
          variant="outlined"
          color="primary"
          style={{ marginRight: 15 }}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignmentModal;
