import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import { months } from '../AssignmentHeader/MonthlyReportModal/months';
import {
  assignMonth,
  assignYear,
  getContentCreatorsMonthly,
  validateIfIsWeekend
} from './services';
import { AssignmentLayout } from '../../../pages/AssignmentPage/layout';
import PostByContentCreator from './MonthlyReportCells/PostByContentCreator';

const headersStyle = { border: '1px solid rgb(37, 79, 138)', color: 'white' };

export const AssignmentMonthlyReport = () => {
  const { month, year } = useParams();
  const [days, setDays] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [contentCreators, setContentCreators] = useState([]);

  const getContentCreatorsCallback = useCallback(async (month, year) => {
    const contentCreatorsMonthly = await getContentCreatorsMonthly(month, year);
    setContentCreators(contentCreatorsMonthly);
  }, []);

  useEffect(() => {
    const monthValidated = assignMonth(month, year);
    const yearValidated = assignYear(year);

    /**
     * Generate array with the total of month days
     */
    const totalDays = new Date(yearValidated, monthValidated, 0).getDate();
    const days = [...Array(totalDays).keys()].map((day) => {
      return {
        dayOfMonth: day + 1,
        isWeekend: validateIfIsWeekend(
          yearValidated,
          monthValidated - 1,
          day + 1
        )
      };
    });
    const selectedMonth = Object.keys(months)[monthValidated - 1];

    getContentCreatorsCallback(monthValidated, yearValidated);

    setSelectedMonth(selectedMonth);
    setSelectedYear(yearValidated);
    setDays(days);
    return () => {
      setSelectedMonth('');
      setSelectedYear('');
      setDays([]);
    };
  }, [month, year, getContentCreatorsCallback]);
  return (
    <AssignmentLayout>
      <h1 className="mt-5">
        Monthly Reports - {`${selectedMonth} ${selectedYear}`}
      </h1>
      <TableContainer component={Paper} style={{ marginBottom: '50px' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead style={{ backgroundColor: 'rgb(55, 102, 168)' }}>
            <TableRow>
              <TableCell style={headersStyle}>Content Creator</TableCell>
              {days.map((day) => (
                <TableCell
                  key={day.dayOfMonth}
                  style={headersStyle}
                  className="text-center">
                  {day.dayOfMonth + ' '}
                </TableCell>
              ))}
              <TableCell style={headersStyle}>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contentCreators.map((contentCreator) => (
              <PostByContentCreator
                key={contentCreator.name.replace(/\s/g, '')}
                days={days}
                contentCreator={contentCreator}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </AssignmentLayout>
  );
};
