import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'selectedClient',
  initialState: {
    selectedClient: {
      clientName: "",
      timeZone: "",
      currentClientBestTimes: "",
      currentClientMergeFields: "",
      currentClientId:"",
      clientSocialNetworks: {
        facebook:false,
        google: false,
        twitter: false,
      }
    }
  },
  reducers: {
    setCurrentClient: (state, { payload }) => {
      state.selectedClient = payload;
    }
  }
});

export default slice.reducer;

const { setCurrentClient } = slice.actions;

// Current client selector
export const selectedClientSelector = (state) => state.selectedClient;

export const setSelectedClient = (payload) => (dispatch) => {
  dispatch(setCurrentClient(payload));
};
