import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { GCP_API_KEY } from '../../utils';
import './styles.css';

const ServiceArea = ({
  title,
  children,
  isEditing,
  onFieldChange,
  canEdit,
  googleProfileInfo
}) => {
  const [localValue, setLocalValue] = useState(children);
  const [places, setPlaces] = useState([]);
  const [gettingPlaces, setGettingPlaces] = useState(false);

  const getPlaces = async () => {
    setGettingPlaces(true);
    try {
      const mapScript = document.createElement('script');
      mapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GCP_API_KEY}&libraries=places`;
      mapScript.async = true;
      mapScript.onload = initMap;
      document.head.appendChild(mapScript);

      setGettingPlaces(false);
      return () => {
        document.head.removeChild(mapScript);
      };
    } catch (e) {
      console.error('An error has ocurred retrieving the google categories', e);
      setGettingPlaces(false);
      return [];
    }
  };

  useEffect(() => {
    if (places.length === 0 && !gettingPlaces) {
      try {
        getPlaces();
      } catch (e) {
        console.error(e);
      }
    }
  }, [places]);

  const initMap = () => {
    const service = new window.google.maps.places.PlacesService(
      document.createElement('div')
    );

    const request = {
      location: { lat: 40.7128, lng: -74.006 }, // Coordenadas de Nueva York
      radius: '50000', // Aumentamos el radio para buscar ciudades en un área más grande
      type: 'cities' // Tipo de lugar para ciudades
    };

    service.textSearch(request, (results, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setPlaces(
          results.map((place) => ({
            ...place,
            value: place.id,
            label: place.name
          }))
        );
      }
    });
  };

  // MinusIcon is used for empty fields
  const isMinusIcon =
    children.props?.className?.includes('fas fa-minus') ||
    (typeof children === 'string' && children === 'fas fa-minus');

  const handleInputChange = (e) => {
    const newValue = e.target.value;

    if (isMinusIcon) {
      setLocalValue(localValue);
    }

    if (isEditing && canEdit && !isMinusIcon) {
      setLocalValue(newValue);
      onFieldChange(newValue);
    }
  };

  return (
    <div className="info-field">
      <div>
        <div>
          <strong>{title}:</strong>
        </div>
      </div>
      <div style={{ marginTop: '14px' }}>
        {isEditing && canEdit && !isMinusIcon ? (
          <>
            <Select
              id="places"
              options={places}
              value={''}
              onChange={handleInputChange}
              styles={{
                singleValue: (base) => ({
                  ...base,
                  display: 'flex',
                  alignItems: 'center'
                })
              }}
            />
          </>
        ) : (
          <div>
            {isEditing && canEdit && isMinusIcon ? (
              <>
                <input
                  type="text"
                  value={''}
                  onChange={handleInputChange}
                  className="form-control"
                />
              </>
            ) : (
              <>
                <span>{localValue}</span>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceArea;
