import React from 'react';
import List from '@material-ui/core/List';
import { ItemCustomers } from './ItemCustomers';

export const ListCustomers = ({ customers }) => {
  return (
    <div>
      <List style={{ overflowY: 'visible' }}>
        {customers.length === 0 ? (
          <p className="text-center m-5 h4 font-weight-light">No clients</p>
        ) : (
          customers.map((customer) => (
            <ItemCustomers
              key={customer.accountName.replace(/\s/, '')}
              customer={customer}
            />
          ))
        )}
      </List>
    </div>
  );
};
