import Swal from 'sweetalert2';

export const successAlert = (msg) => {
  Swal.fire({
    icon: 'success',
    title: 'Success!',
    text: msg,
    allowOutsideClick: false,
    allowEscapeKey: false
  });
};
