import { API_ASSIGN_CREATOR } from '../../../../utils/constants';

export const assignCreator = async (clientId, assignment, author) => {
  const options = {
    method: 'POST',
    body: JSON.stringify({
      id: clientId,
      email: assignment.email,
      name: assignment.name,
      author: author,
      done: false
    })
  };
  const response = await fetch(API_ASSIGN_CREATOR, options);
  return response;
};
