import React from 'react';
import ReviewContent from './ReviewContent';

const ReviewsSection = ({ id }) => {
  return (
    <div className="card" id="posts-section">
      <div className="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-between">
        <h6 className="tx-uppercase tx-semibold mg-b-0">Reviews</h6>
      </div>
      <div className="card-body pd-25">
        <ReviewContent id={id} />
      </div>
    </div>
  );
}

export default ReviewsSection;
