import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { useContentCreators } from '../../SelectContentCreators/services';
import SelectContentCreator from '../../SelectContentCreators';

const currentDate = moment().format().split('T')[0];

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    height: 110,
    marginTop: 30
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240
  }
}));

const DailyReportModal = ({ open, handleModal }) => {
  const history = useHistory();
  const { contentCreator, ...selectContentCreatorProps } = useContentCreators(
    open,
    true
  );
  const classes = useStyles();
  const [date, setDate] = useState(currentDate);

  const handleGenerate = (e) => {
    e.preventDefault();
    const formatedDate = date;
    if (contentCreator?.name === 'All' || contentCreator == null) {
      history.push(`/assignment/daily/${formatedDate}`);
    } else {
      history.push(`/assignment/daily/${contentCreator?.name}/${formatedDate}`);
    }
    handleModal(open);
  };

  useEffect(() => {
    if (open) {
      setDate(currentDate);
    } else {
      setDate('');
    }
    return () => {
      setDate('');
    };
  }, [open]);

  return (
    <Dialog
      open={open}
      maxWidth={'md'}
      onClose={() => handleModal(open)}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ style: { overflowY: 'visible' } }}>
      <DialogTitle id="alert-dialog-title" className="text-center">
        Daily Report
      </DialogTitle>
      <DialogContent style={{ overflowY: 'visible' }}>
        <Grid container justifyContent="center" direction="row">
          {/* Content Modal */}
          <form className={classes.container}>
            <Grid item>
              <FormControl className={classes.formControl} variant="outlined">
                <TextField
                  id="date"
                  label="Date"
                  value={date === null ? currentDate : date}
                  type="date"
                  className={classes.textField}
                  variant="outlined"
                  onChange={(e) => setDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    inputProps: {
                      min: '2022-04-01',
                      max: currentDate
                    }
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl className={classes.formControl} variant="outlined">
                <SelectContentCreator
                  height={56.4}
                  {...selectContentCreatorProps}
                />
              </FormControl>
            </Grid>
          </form>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleModal(open)}
          variant="outlined"
          color="inherit"
          style={{ marginRight: 15 }}>
          Close
        </Button>
        <Button
          onClick={handleGenerate}
          variant="outlined"
          color="primary"
          style={{ marginRight: 15 }}>
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DailyReportModal;
