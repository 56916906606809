import React, { useState, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import AssignmentModal from './AssignmentModal';
import { renderColumns } from './Columns/renderColumns';
import { FilterContext } from '../../contexts/FilterContext';
import { AssignmentLayout } from '../../pages/AssignmentPage/layout';
import useGetClientData from '../../pages/AssignmentPage/services/useGetClientData';

const LinearIndeterminate = () => {
  return (
    <div className="mt-3">
      <p> No assignments </p>
    </div>
  );
};

const initialState = {
  loading: true,
  clients: []
};

export const AssignmentsMain = () => {
  const [open, setOpen] = useState(false);
  const [client, setClient] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [clientsTableData, getClientsData] = useGetClientData(initialState);

  /**
   * Function for filter clients data
   * according to filter criteria
   */
  const filterClients = clientsTableData.clients.filter(
    (client) =>
      (client?.clientName &&
        client.clientName?.toLowerCase().includes(filterText.toLowerCase())) ||
      (client?.assignment?.email &&
        client?.assignment?.name
          ?.toLowerCase()
          .includes(filterText.toLowerCase()))
  );

  const handleFilterText = useCallback((filterText) => {
    setFilterText(filterText);
  }, []);

  const handleModal = useCallback((open, client) => {
    setOpen(!open);
    setClient(client);
  }, []);

  return (
    <AssignmentLayout
      loading={clientsTableData.loading}
      filterText={filterText}
      handleFilterText={handleFilterText}
      searchBar={true}>
      <FilterContext.Provider value={{ clientsTableData, getClientsData }}>
        <DataTable
          columns={renderColumns(open, handleModal)}
          data={filterClients}
          highlightOnHover={true}
          noHeader={true}
          progressPending={clientsTableData.loading}
          progressComponent={<LinearIndeterminate />}
          pagination
          paginationPerPage={150}
          paginationRowsPerPageOptions={[150, 300, 450, 600]}
          subHeader
          striped={true}
        />
        <AssignmentModal
          client={client}
          open={open}
          handleModal={handleModal}
        />
      </FilterContext.Provider>
    </AssignmentLayout>
  );
};
