import React from 'react';
import './ButtonLoad.scss';

const ButtonLoad = (props) => {
  return (
    <button
      type={props.type || 'button'}
      className={`btn ${props.btn} tx-13`}
      onClick={(e) => {
        props.callback(e);
      }}
      disabled={props.state || props.disabled}>
      <span
        className={`spinner-border spinner-border-sm mr-1 ${
          !props.state ? 'hidden' : ''
        }`}
        role="status"
        aria-hidden="true"></span>
      <i
        className={`fas fa-fw ${props.icon} ${
          props.state ? 'hidden' : ''
        }`}></i>
      <span className={!props.state ? 'hidden' : ''}> Loading...</span>
      <span className={props.state ? 'hidden' : ''}>{props.text}</span>
    </button>
  );
};

export default ButtonLoad;
