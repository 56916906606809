import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../pages/Calendar/GallerySection/StockImagesComponents/Partials/LoadingSpinner';
import { authSelector } from '../../../store/slices/auth';
import { getNotesInfo } from '../services';

const AccountStatus = ({ clientId }) => {
  const { userInfo } = useSelector(authSelector);
  const [accountStatus, setAccountStatus] = useState('');
  const isMounted = useRef(false);

  const getAccountStatus = useCallback(async () => {
    if (isMounted && clientId) {
      const { Account_Status } = await getNotesInfo(
        clientId,
        userInfo.accessToken,
        true
      );
      setAccountStatus(Account_Status);
    }
  }, [clientId, userInfo.accessToken]);

  useEffect(() => {
    getAccountStatus();
    return () => {
      setAccountStatus('');
      isMounted.current = false;
    };
  }, [getAccountStatus]);

  return (
    <div className="pd-y-20 pd-x-15">
      <label className="tx-uppercase tx-sans tx-10 tx-medium tx-spacing-1 tx-color-03 pd-l-10 mg-b-10">
        Account Status
      </label>
      <h6 className="ml-2">{accountStatus || <LoadingSpinner />}</h6>
    </div>
  );
};

export default AccountStatus;
