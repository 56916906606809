import React, { useState, useEffect } from 'react';
import { AWS_REGION } from '../../../utils/constants';

const Mobile = (props) => {
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const handleReplace = (clientName, message) => {
      if (!message) {
        let empty = '⠀';
        setMessage(empty);
        return;
      }
      if (message.includes('#name')) {
        message = message.split('#name').join('John');
      }
      if (message.includes('#Name')) {
        message = message.split('#Name').join('John');
      }
      if (message.includes('#company')) {
        message = message.split('#company').join(`${clientName}`);
      }
      if (message.includes('#Company')) {
        message = message.split('#Company').join(clientName);
      }
      if (props?.yelpLink?.length > 0 && props?.gmbLink?.length > 0) {
        message = message.concat(
          ' Google: ',
          props.gmbLink,
          ' Yelp: ',
          props.yelpLink
        );
      } else if (props?.gmbLink?.length > 0) {
        message = message.concat(' Google: ', props.gmbLink);
      } else if (props?.yelpLink?.length > 0) {
        message = message.concat(' Yelp: ', props.yelpLink);
      }
      setMessage(message);
    };
    handleReplace(props.clientName, props.message);
  }, [
    props.message,
    props.isLoading,
    props.clientName,
    props.gmbLink,
    props.yelpLink
  ]);
  return (
    <div className="device-emulator-container">
      <div className="mobile-chrome">
        <div className="frame mobile-frame">
          <div
            style={{
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column'
            }}>
            <div
              className={
                AWS_REGION === 'us-east-2' ? 'bg-development' : 'bg-production'
              }
              style={{
                display: 'flex',
                width: '100%',
                height: 30,
                color: 'white',
                fontWeight: '600',
                justifyContent: 'center',
                alignContent: 'center',
                paddingTop: 5
              }}></div>
            <div style={{ flex: 1, paddingLeft: 10 }}>
              <div className="chat">
                {props.isLoading || (props.image && !props.message) ? (
                  props.isLoading ? null : (
                    'Please add an text message'
                  )
                ) : (
                  <div className="yours messages">
                    <div className="message last">
                      {props.image ? (
                        <>
                          <img
                            alt=""
                            src={props.image}
                            style={{
                              maxHeight: 120,
                              maxWidth: '100%',
                              objectFit: 'contain'
                            }}
                          />
                          <br></br>
                        </>
                      ) : null}

                      {message}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: 30,
                width: '100%',
                borderTop: '1px solid black',
                backgroundColor: 'white'
              }}>
              <div>
                <svg
                  version="1.1"
                  width="20"
                  height="20"
                  x="0"
                  y="0"
                  viewBox="0 0 512 512">
                  <g>
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="m226 512c-49.626 0-90-40.374-90-90v-302c0-66.168 53.832-120 120-120s120 53.832 120 120v302h-30v-302c0-49.626-40.374-90-90-90s-90 40.374-90 90v302c0 33.084 26.916 60 60 60s60-26.916 60-60v-242c0-16.542-13.458-30-30-30s-30 13.458-30 30v242h-30v-242c0-33.084 26.916-60 60-60s60 26.916 60 60v242c0 49.626-40.374 90-90 90z"
                      fill="#7da7ff"
                      data-original="#000000"
                    />
                  </g>
                </svg>
              </div>
              <div
                style={{
                  display: 'flex',
                  color: 'gray',
                  paddingTop: 20,

                  justifyContent: 'center'
                }}>
                <p>Reply Message...</p>
              </div>
              <div>
                <svg
                  width="20"
                  height="20"
                  version="1.1"
                  id="Capa_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512">
                  <g>
                    <g>
                      <path
                        d="M437.019,74.981C388.668,26.629,324.38,0,256,0S123.332,26.629,74.981,74.981C26.628,123.332,0,187.62,0,256
			s26.628,132.668,74.981,181.019C123.332,485.371,187.62,512,256,512s132.668-26.629,181.019-74.981
			C485.372,388.668,512,324.38,512,256S485.372,123.332,437.019,74.981z M256,481.524c-124.354,0-225.524-101.17-225.524-225.524
			S131.646,30.476,256,30.476S481.524,131.646,481.524,256S380.354,481.524,256,481.524z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M200.622,188.396c-24.953-24.955-65.556-24.953-90.509,0c-5.951,5.95-5.951,15.599,0,21.55
			c5.952,5.95,15.601,5.95,21.551,0c13.072-13.071,34.34-13.07,47.41,0c2.976,2.976,6.875,4.464,10.774,4.464
			s7.8-1.488,10.774-4.464C206.573,203.995,206.573,194.347,200.622,188.396z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M401.884,188.396c-24.953-24.953-65.556-24.955-90.509,0c-5.951,5.95-5.951,15.599,0,21.55
			c5.952,5.95,15.601,5.95,21.551,0c13.07-13.071,34.338-13.072,47.41,0c2.976,2.976,6.875,4.464,10.774,4.464
			s7.8-1.488,10.774-4.464C407.835,203.995,407.835,194.347,401.884,188.396z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M391.111,267.175H120.889c-8.416,0-15.238,6.823-15.238,15.238c0,82.902,67.447,150.349,150.349,150.349
			s150.349-67.447,150.349-150.349C406.349,273.997,399.527,267.175,391.111,267.175z M256,402.286
			c-60.938,0-111.402-45.703-118.909-104.635H374.91C367.402,356.583,316.938,402.286,256,402.286z"
                      />
                    </g>
                  </g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              </div>
              <div>
                <svg
                  version="1.1"
                  id="Capa_1"
                  width="20"
                  height="20"
                  x="0px"
                  y="0px"
                  viewBox="0 0 488.721 488.721">
                  <g>
                    <g>
                      <path
                        d="M483.589,222.024c-5.022-10.369-13.394-18.741-23.762-23.762L73.522,11.331C48.074-0.998,17.451,9.638,5.122,35.086
			C-1.159,48.052-1.687,63.065,3.669,76.44l67.174,167.902L3.669,412.261c-10.463,26.341,2.409,56.177,28.75,66.639
			c5.956,2.366,12.303,3.595,18.712,3.624c7.754,0,15.408-1.75,22.391-5.12l386.304-186.982
			C485.276,278.096,495.915,247.473,483.589,222.024z M58.657,446.633c-8.484,4.107-18.691,0.559-22.798-7.925
			c-2.093-4.322-2.267-9.326-0.481-13.784l65.399-163.516h340.668L58.657,446.633z M100.778,227.275L35.379,63.759
			c-2.722-6.518-1.032-14.045,4.215-18.773c5.079-4.949,12.748-6.11,19.063-2.884l382.788,185.173H100.778z"
                      />
                    </g>
                  </g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Mobile;
