import React, { useState } from 'react';
import '../style.css';
import axios from 'axios';
import { API_REVIEW_APPROVE } from '../../../utils';

const Approve = ({ approved = false, reviewId, callback }) => {
  const [isApproved, setIsApproved] = useState(approved);
  const [isLoading, setIsLoading] = useState(false);

  const handleApproveClick = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${API_REVIEW_APPROVE}/`, {
        id: reviewId
      });

      if (response.status === 200) {
        setIsApproved(true);
        callback(true);
      }
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  return (
    <div className="approve-container">
      <button
        className={`btn ${isApproved ? 'approved' : 'not-approved'}`}
        onClick={handleApproveClick}
        disabled={isApproved}>
        {isApproved && !isLoading
          ? 'Approved'
          : isLoading
          ? 'Approving...'
          : isApproved
          ? 'Approved'
          : 'Approve'}
      </button>
    </div>
  );
};

export default Approve;
