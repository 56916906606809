import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoadingModal = ({ open, handleModal, percent = 0, error = '' }) => {
  useEffect(() => {
    if (!open) {
    }
    return () => {};
  }, [open]);
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (open) {
        e.preventDefault();
        e.returnValue =
          'You still have a process in progress, are you sure you want to close this tab?';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [open]);
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
      disableEscapeKeyDown={true}>
      <DialogContent>
        <Grid container justifyContent="center" direction="row">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '45px',
              marginBottom: '65px'
            }}>
            <DialogTitle>
              Please wait while the content is being generated.
            </DialogTitle>
            {error.length > 0 ? (
              <>
                {error}
                <div onClick={handleModal} className="btn btn-primary">
                  Exit
                </div>
              </>
            ) : (
              <>
                <p>{percent}%</p>
                <p>
                  Please don't close this window until the process is finished
                </p>
                <CircularProgress />
              </>
            )}
          </div>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingModal;
