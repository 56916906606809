import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../../store/slices/auth';
import { FilterContext } from '../../../../contexts/FilterContext';
import { setUnassginContentCreator } from './services';
import { infoAlert } from '../../../../utils/alerts/infoAlert';
import { errorAlert } from '../../../../utils/alerts/errorAlert';
import { CloseIcon } from '../../../../Icons/index';
import CircularProgress from '@material-ui/core/CircularProgress';
import './unassign.css';

const roles = ['superAdmin', 'admin'];

const Unassign = ({ client }) => {
  const { userInfo } = useSelector(authSelector);
  const isMounted = useRef(false);
  const [load, setLoad] = useState(false);
  const {
    clientsTableData: { clients },
    getClientsData
  } = useContext(FilterContext);

  const handleUnassign = async () => {
    if (!client?.assignment?.done && client.id && isMounted.current) {
      setLoad(true);
      try {
        const response = await setUnassginContentCreator(
          client?.assignment,
          client.id
        );
        if (response.ok && isMounted.current) {
          getClientsData(clients, {
            ...client,
            assignment: {
              ...client?.assignment,
              id: null,
              email: null,
              name: null
            }
          });
          infoAlert('Content creator was unassigned');
        } else {
          errorAlert('An error has ocurred, please try again...');
        }
      } catch (error) {
        errorAlert('An error has ocurred, please try again...');
        throw new Error('An error has ocurred: ', error);
      } finally {
        setLoad(false);
      }
    } else if (client?.assignment?.done && client?.assignment?.email) {
      infoAlert(
        "You can't remove a content creator if the task is checked as done"
      );
    }
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      setLoad(false);
      isMounted.current = false;
    };
  }, []);

  return (
    <>
      {roles.includes(userInfo.role) && !load && (
        <CloseIcon
          width={15}
          height={15}
          stroke={'gray'}
          strokeWidth={'50'}
          className="unassign"
          style={{
            border: '1px solid gray',
            marginLeft: 7,
            marginBottom: 2,
            padding: 2,
            borderRadius: 3
          }}
          onClick={handleUnassign}
        />
      )}
      {load && (
        <CircularProgress
          size={15}
          style={{ marginLeft: 5, marginBottom: 2 }}
        />
      )}
    </>
  );
};

export default Unassign;
