import React, { useState } from 'react';
import Select from 'react-select';
import './styles.css';

const Categories = ({
  title,
  children,
  isEditing,
  onFieldChange,
  canEdit,
  categories,
  googleProfileInfo
}) => {
  const [localValue, setLocalValue] = useState(children);

  // MinusIcon is used for empty fields
  const isMinusIcon =
    children.props?.className?.includes('fas fa-minus') ||
    (typeof children === 'string' && children === 'fas fa-minus');

  const handleInputChange = (e) => {
    const newValue = e.target.value;

    if (isMinusIcon) {
      setLocalValue(localValue);
    }

    if (isEditing && canEdit && !isMinusIcon) {
      setLocalValue(newValue);
      onFieldChange(newValue);
    }
  };

  return (
    <div className="info-field">
      <div>
        <div>
          <strong>{title}:</strong>
        </div>
      </div>
      <div style={{ marginTop: '14px' }}>
        {isEditing && canEdit && !isMinusIcon ? (
          <>
            <Select
              id="categories"
              options={categories}
              value={categories.find(
                (option) => option.value === googleProfileInfo
              )}
              onChange={async (selectedOption) => {
                console.log('newValue: ', selectedOption);
                setLocalValue(selectedOption);
                onFieldChange(selectedOption);
              }}
              styles={{
                singleValue: (base) => ({
                  ...base,
                  display: 'flex',
                  alignItems: 'center'
                })
              }}
            />
          </>
        ) : (
          <div>
            {isEditing && canEdit && isMinusIcon ? (
              <>
                <Select
                  id="categories"
                  options={categories}
                  value={''}
                  onChange={handleInputChange}
                  styles={{
                    singleValue: (base) => ({
                      ...base,
                      display: 'flex',
                      alignItems: 'center'
                    })
                  }}
                />
              </>
            ) : (
              <>
                <span>{localValue}</span>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Categories;
