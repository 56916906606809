import React, { useRef, useState, useEffect } from 'react';
import Chartjs from 'chart.js';

const DoughnutChart = (props) => {
  const chartContainer = useRef(null);
  // eslint-disable-next-line
  const [chartInstance, setChartInstance] = useState(null);

  const chartConfig = {
    type: 'doughnut',
    data: {
      labels: ['Errors', 'Scheduled', 'Published'],
      datasets: [
        {
          label: '# of Votes',
          data: [
            props.data['post-error'],
            props.data['post-succeded'],
            props.data['post-published']
          ],
          backgroundColor: ['#dc3545', '#7695c1', '#2b5197']
        }
      ]
    },
    options: {
      legend: {
        labels: {
          fontSize: 15,
          padding: 20
        }
      },
      cutoutPercentage: 50,
      circumference: Math.PI,
      rotation: -Math.PI
    }
  };

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chartjs(chartContainer.current, chartConfig);
      setChartInstance(newChartInstance);
    }
    //eslint-disable-next-line
  }, [chartContainer]);

  return (
    <div>
      <canvas height="100%" ref={chartContainer} />
    </div>
  );
};

export default DoughnutChart;
