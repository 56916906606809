import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { AssignmentLayout } from '../../../pages/AssignmentPage/layout';
import { columns } from './DailyReportCells/columns';
import { getContentCreatorsDaily } from './services';
import { Customers } from './DailyReportCells/Customers';

export const AssignmentDailyReport = () => {
  const { contentCreator, date } = useParams();
  const [contentCreatorsDaily, setcontentCreatorsDaily] = useState([]);
  const getContentCreatorsCallback = useCallback(async () => {
    const contentCreatorsDaily = await getContentCreatorsDaily(
      contentCreator,
      date
    );
    setcontentCreatorsDaily(contentCreatorsDaily);
  }, [contentCreator, date]);

  useEffect(() => {
    getContentCreatorsCallback();
    return () => {
      setcontentCreatorsDaily([]);
    };
  }, [getContentCreatorsCallback]);

  return (
    <AssignmentLayout>
      <h1 className="mt-5">Daily Reports - {moment(date).format('ll')}</h1>
      <DataTable
        striped={true}
        highlightOnHover={true}
        noHeader={true}
        pagination
        columns={columns}
        data={contentCreatorsDaily}
        expandableRows
        expandableRowsComponent={Customers}
      />
    </AssignmentLayout>
  );
};
