import React, { useState, useEffect } from 'react';
import './IGTags.css';
import Tag from './Tag/Tag';

const IGTags = ({
  idClient,
  show,
  handleShow,
  btnTagRef,
  tagsPhrasesRef,
  size = 'bigger',
  instagram = false
}) => {
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState('');

  const consultIgTable = async (methodLambda = 'GET', idCli = '') => {
    if (instagram === 'false') {
      return;
    }
    const url = process.env.REACT_APP_INSTAGRAM_TAGS;
    const tagsUpload = tags.join('-n-');
    const body = JSON.stringify({
      body: {
        id: idCli,
        tags: tagsUpload
      },
      httpMethod: methodLambda
    });

    let res;
    try {
      res = await fetch(url, {
        method: 'POST',
        body: body
      });
      res = await res.json();
      if (methodLambda === 'GET') {
        const tagstext = res?.body.split('-n-');
        if (res?.statusCode === 200 || res?.statusCode === 201) {
          setTags(tagstext);
        }
      }
    } catch (e) {
      console.warn('No there instagram');
    }
  };

  useEffect(() => {
    setTags([]);
    consultIgTable('GET', idClient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idClient]);

  const handleTag = (e) => {
    const { key } = e;
    if (key === 'Enter' && tags.length < 30) {
      if (tag.length > 0) {
        const tagtext = tag[0] !== '#' ? `#${tag}` : tag;
        const exist = tags.find((item) => item === tagtext);
        if (exist === undefined) {
          setTags([tagtext, ...tags]);
          setTag('');
        }
      }
    } else if (key === 'Delete' || key === 'Backspace') {
      if (tag === '' && tags.length > 0) {
        const len = tags.length;
        const tagToInput = tags[0];
        const listTags = tags.slice(1, len);

        setTags(listTags);
        setTag(tagToInput);
      }
    }
  };
  return (
    <div className="col-sm-6 col-md-5 col-lg mt-3 merge-fields-table">
      <div className="d-flex align-items-center justify-content-between">
        <h6 className="tx-13 tx-spacng-1 tx-uppercase tx-semibold mb-2">
          # Instagram
        </h6>
        <button
          className="button-like-a link-03"
          ref={btnTagRef}
          onClick={(e) => {
            e.preventDefault();
            handleShow();
            if (show) {
              consultIgTable('POST', idClient);
            }
          }}>
          {show ? 'Save' : 'Edit'}
        </button>
      </div>
      <div className="border pt-3 pb-3 mr-1 ml-1 mb-3">
        <ul
          ref={tagsPhrasesRef}
          className={`d-flex flex-wrap m-0 pl-1 pr-1 scroll-tags ${
            size === 'bigger' ? 'bigger-box-ig-tag' : 'smaller-box-ig-tag'
          }`}>
          {tags && tags.length > 0 && tags[0] !== ''
            ? tags.map((tag) => {
                return <Tag tagtext={tag} key={tag} setTags={setTags} />;
              })
            : null}
        </ul>
        {show && instagram ? (
          <input
            type="text"
            className="input-tags mr-2 ml-2"
            value={tag}
            onChange={(e) => {
              setTag(e.target.value);
            }}
            onKeyUp={handleTag}
            placeholder="Enter some tag"
            data-role="tagsinput"
          />
        ) : null}
      </div>
    </div>
  );
};

export default IGTags;
