import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, IconButton, makeStyles } from '@material-ui/core';
import { PenIcon, SaveIcon, CloseIcon } from '../../../Icons';
import { editGroupAPI } from './services';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  noFocus: {
    '&:focus': {
      outline: 'none'
    }
  }
}));

const GroupNameColumn = ({ id, groupName, length }) => {
  const classes = useStyles();
  const { userInfo } = useSelector((state) => state.auth);
  const [editedGroupName, setEditedGroupName] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const editedGroupNameRef = useRef(groupName);

  const changeToEditInput = () => {
    setIsEditing(!isEditing);
    setEditedGroupName(editedGroupName || editedGroupNameRef.current);
  };

  const editGroup = async () => {
    setIsSaving(true);
    try {
      await editGroupAPI(
        userInfo.accessToken,
        id,
        editedGroupName || editedGroupNameRef.current
      );
      if (editedGroupName !== '') editedGroupNameRef.current = editedGroupName;
    } catch (error) {
      console.error(error);
      setEditedGroupName(editedGroupNameRef.current);
    } finally {
      setIsEditing(false);
      setIsSaving(false);
    }
  };

  const cancelEdit = () => {
    setIsEditing(false);
    setEditedGroupName(editedGroupNameRef.current);
  };

  const cleanAllStates = () => {
    setEditedGroupName('');
    setIsEditing(false);
    setIsSaving(false);
    editedGroupNameRef.current = '';
  };

  useEffect(() => {
    return () => {
      cleanAllStates();
    };
  }, []);

  return (
    <div className={'row'}>
      <span className={'col-9'}>
        {isEditing ? (
          <input
            type={'text'}
            value={editedGroupName}
            className={classes.noFocus}
            onChange={(event) => setEditedGroupName(event.target.value)}
          />
        ) : (
          <span>
            {editedGroupName || editedGroupNameRef.current} ({length})
          </span>
        )}
      </span>
      <span className={'col-3 text-right'}>
        {isEditing ? (
          <section>
            {!isSaving ? (
              <>
                <IconButton
                  className={`p-0 mr-2 mb-1 mt-1 ${classes.noFocus}`}
                  onClick={editGroup}>
                  <SaveIcon width={15} height={15} fill={'#8a93a1'} />
                </IconButton>
                <IconButton
                  className={`p-0 mb-1 mt-1 ${classes.noFocus}`}
                  onClick={cancelEdit}>
                  <CloseIcon width={15} height={15} fill={'#8a93a1'} />
                </IconButton>
              </>
            ) : (
              <CircularProgress className={'text-center mb-1 mt-1'} size={15} />
            )}
          </section>
        ) : (
          <IconButton
            className={`p-0 ${classes.noFocus}`}
            onClick={changeToEditInput}>
            <PenIcon width={16} height={16} fill={'#8a93a1'} />
          </IconButton>
        )}
      </span>
    </div>
  );
};

export default GroupNameColumn;
