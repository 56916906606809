import React from 'react';
import moment from 'moment-timezone';
import { FacebookIcon, GoogleIcon, TwitterIcon } from '../../../../Icons';

const SinglePost = ({ post }) => {
  return (
    <>
      <div className="media mt-2">
        <div
          className="content-image-posts wd-80 ht-80 bg-ui-04 rounded d-flex align-items-center justify-content-center"
          style={{ backgroundImage: `url(${post['images'][0]['url']})` }}>
          <i data-feather="book-open" className="tx-white-7 wd-40 ht-40"></i>
        </div>
        <div className="media-body pd-l-25">
          <span className={'tx-color-03 tx-13 mg-b-5'}>
            {moment.unix(post['schedule']).format('MMMM D YYYY, h:mm a')}
          </span>
          <div className="tx-12 tx-color-03">
            {post['socialNetworks']['facebook'] && (
              <FacebookIcon width="15" height="15" />
            )}
            {post['socialNetworks']['twitter'] && (
              <TwitterIcon className="ml-2" width="15" height="15" />
            )}
            {post['socialNetworks']['googleMyBusiness'] && (
              <GoogleIcon className="ml-2" width="15" height="15" />
            )}
          </div>
          <p className="tx-12 mb-0">{post['message']}</p>
        </div>
      </div>
      <hr />
    </>
  );
};

export default SinglePost;
