import React from 'react';
import SinglePost from './SinglePost/SinglePosts';
import { useSelector } from 'react-redux';
import { postsSelector } from '../../../store/slices/posts';

const moment = require('moment-timezone');

const PostsSection = (props) => {
  const { loadingPosts } = useSelector(postsSelector);
  let posts = props.postsListData.filter((post) => {
    let dateNow = moment.utc(new Date()).unix();
    if (post.schedule) {
      return post.schedule < dateNow;
    } else {
      return post.createdAt < dateNow;
    }
  });
  let contentPosts = [];

  for (let i = 0; i < posts.length; i++) {
    contentPosts.unshift(<SinglePost post={posts[i]} key={i} />);
  }

  let contentPostsFinal = contentPosts.slice(0, props.limit);

  const renderPictures = () => (
    <>
      {contentPostsFinal.length > 0 ? (
        <>
          <div className="card-body pd-25" style={{ border: 'none' }}>
            <div className="row">
              <div className="col-sm col-lg-12 col-xl">{contentPostsFinal}</div>
            </div>
          </div>
          <div
            className="card-footer bg-transparent pd-y-15 pd-x-20"
            style={{ border: 'none' }}>
            <nav className="nav nav-with-icon tx-13">
              <button
                className="button-like-a nav-link"
                onClick={() =>
                  props.limit <= contentPostsFinal.length ? (
                    props.changeView()
                  ) : (
                    <div></div>
                  )
                }>
                {props.limit <= contentPostsFinal.length
                  ? 'Show More Posts'
                  : 'No More Posts'}
                <i
                  data-feather="chevron-down"
                  className="mg-l-2 mg-r-0 mg-t-2"></i>
              </button>
            </nav>
          </div>
        </>
      ) : (
        <span className="d-flex align-items-center justify-content-center pd-y-13 tx-gray-400">
          No posts
        </span>
      )}
    </>
  );

  const loadingPictures = (
    <span className="d-flex align-items-center justify-content-center pd-y-13 tx-gray-400">
      Loading...
    </span>
  );

  return (
    <div className="card card-profile-interest" style={{ border: 'none' }}>
      <div
        className="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-between"
        style={{ border: 'none' }}>
        <h6
          className="tx-uppercase tx-sans tx-10 tx-medium tx-spacing-1 tx-color-03 pd-l-10 mg-b-10"
          style={{ border: 'none' }}>
          Latest Pictures
        </h6>
      </div>
      {loadingPosts ? loadingPictures : renderPictures()}
    </div>
  );
};

export default PostsSection;
