import { API_GROUPS } from '../../../../../utils';
import { errorAlert } from '../../../../../utils/alerts/errorAlert';
import { infoAlert } from '../../../../../utils/alerts/infoAlert';

export const editGroupAPI = async (accessToken, groupId, newGroupName) => {
  try {
    const response = await fetch(API_GROUPS, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${accessToken}`
      },
      body: JSON.stringify({
        groupId,
        newGroupName
      })
    });
    const { msg, isNotActive } = await response.json();

    if (isNotActive) infoAlert(msg);
  } catch (error) {
    errorAlert('An error has ocurred trying to edit the group');
    throw new Error(error);
  }
};
