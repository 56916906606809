import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import NoteContent from '../NoteContent';

const NotesModal = ({ id, open, handleNotesModal }) => {
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'lg'}
      onClose={() => handleNotesModal(open)}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{'Notes'}</DialogTitle>
      <DialogContent>
        {open && <NoteContent id={id} open={open} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleNotesModal(open)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotesModal;
