import React from 'react';
import store, { persistor } from './store/store';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import App from './App';

const NotFound = () => <p>sdsa</p>;
function Root() {
  return (
    <Provider store={store}>
      <PersistGate loading={<NotFound />} persistor={persistor}>
        <Router>
          <App />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default Root;
