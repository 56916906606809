import {
  myFirebase,
  googleProvider,
  dbFirestore
} from '../../firebase/firebase';

const validateEmail = (email) => {
  var re = /^\s*[\w\-+_]+(\.[\w\-+_]+)*@[\w\-+_]+\.[\w\-+_]+(\.[\w\-+_]+)*\s*$/;
  if (re.test(email)) {
    if (
      email.indexOf('@samxlabs.com') !== -1 ||
      email.indexOf('@adlersocial.com') !== -1
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const loginUser = () => {
  return async (dispatch) => {
    dispatch({ type: 'auth/loginPending' });

    const { user } = await myFirebase.auth().signInWithPopup(googleProvider);

    if (!validateEmail(user.email)) {
      dispatch({
        type: 'auth/loginError',
        payload: {
          error: 'Email not valid'
        }
      });
      return;
    }

    const userFirebase = await dbFirestore
      .collection('users')
      .doc(user.email)
      .get();
    const userData = userFirebase.data();

    if (!userData?.status) {
      dispatch({
        type: 'auth/loginError',
        payload: {
          error: 'User not found'
        }
      });
      return;
    }

    dispatch({
      type: 'auth/loginUser',
      payload: {
        user,
        role: userData?.role
      }
    });
    return;
  };
};

export const logoutUser = () => {
  return async (dispatch) => {
    try {
      await myFirebase.auth().signOut();
      dispatch({ type: 'auth/logoutUser' });
    } catch (error) {
      dispatch({
        type: 'auth/loginError',
        payload: {
          error: 'Cannot logout'
        }
      });
      throw new Error(error);
    }
  };
};

export const validateUserAuth = (user, idToken) => ({
  type: 'auth/validateUserAuth',
  payload: {
    user,
    idToken
  }
});

// Login selector
export const authSelector = (state) => state.auth;
