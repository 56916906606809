import React from 'react';
import PropTypes from 'prop-types';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import AssignmentHeader from '../../../components/Assignments/AssignmentHeader';
import '../index.css';

export const AssignmentLayout = ({
  loading,
  filterText,
  handleFilterText,
  searchBar,
  children
}) => {
  return (
    <>
      <Header loading={loading} />
      <AssignmentHeader
        filterText={filterText}
        handleFilterText={handleFilterText}
        searchBar={searchBar}
      />
      <main className="content content-fixed">{children}</main>
      <Footer />
    </>
  );
};

AssignmentLayout.protoType = {
  searchBar: PropTypes.bool.isRequired
};

AssignmentLayout.defaultProps = {
  searchBar: false
};
