const initialState = {
  userInfo: null,
  loading: false,
  errorLogin: '',
  isAuthenticated: false
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'auth/loginUser':
      return {
        ...state,
        userInfo: {
          email: action.payload.user.email,
          displayName: action.payload.user.displayName,
          photoURL: action.payload.user.photoURL,
          accessToken: action.payload.user?.Aa,
          role: action.payload.role
        },
        loading: false,
        isAuthenticated: true
      };
    case 'auth/loginPending':
      return {
        ...state,
        loading: true,
        isAuthenticated: false
      };
    case 'auth/loginError':
      return {
        userInfo: null,
        loading: false,
        errorLogin: action.payload.error,
        isAuthenticated: false
      };
    case 'auth/validateUserAuth':
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          email: action.payload.user.email,
          displayName: action.payload.user.displayName,
          photoURL: action.payload.user.photoURL,
          accessToken: action.payload.idToken
        },
        isAuthenticated: true
      };
    case 'auth/logoutUser':
      return initialState;
    default:
      return state;
  }
};
