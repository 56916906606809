import React from 'react';

const SpecialHours = ({ specialHourPeriods }) => {
  return (
    <div>
      {specialHourPeriods.map((period, index) => (
        <div key={index}>
          {period.closed ? (
            <p>{`Closed on ${period.startDate.month}/${period.startDate.day}/${period.startDate.year}`}</p>
          ) : (
            <p>{`Open from ${period.startDate.month}/${period.startDate.day}/${period.startDate.year} to ${period.endDate.month}/${period.endDate.day}/${period.endDate.year}`}</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default SpecialHours;
