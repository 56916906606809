import React, { useEffect, useState, useContext, useRef } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../../store/slices/auth';
import { FilterContext } from '../../../../contexts/FilterContext';
import { setDoneTask } from './services';
import { successAlert } from '../../../../utils/alerts/successAlert';
import { infoAlert } from '../../../../utils/alerts/infoAlert';
import { errorAlert } from '../../../../utils/alerts/errorAlert';
import './done.css';

const roles = ['superAdmin', 'admin'];

const Done = ({ client }) => {
  const { userInfo } = useSelector(authSelector);
  const isMounted = useRef(false);
  const [load, setLoad] = useState(false);
  const [checked, setChecked] = useState(
    client?.assignment !== undefined && client?.assignment?.done
  );
  const {
    clientsTableData: { clients },
    getClientsData
  } = useContext(FilterContext);

  const handleCheckDone = async (assignment) => {
    if (assignment?.done && !roles.includes(userInfo.role)) return;

    if (!isMounted.current) return;

    if (assignment?.email) {
      setLoad(true);
      try {
        const response = await setDoneTask(assignment, client.id, checked);

        if (response.ok && isMounted.current) {
          setLoad(false);
          setChecked(!checked);
          getClientsData(clients, {
            ...client,
            assignment: {
              ...assignment,
              done: !checked
            }
          });
          if (assignment?.done && checked) {
            infoAlert('The task was unchecked');
            return;
          }
          successAlert('Task checked as done successfully!');
          return;
        }
      } catch (error) {
        errorAlert('An error has ocurred, please try again...');
        throw new Error('An error has ocurred: ', error);
      } finally {
        setLoad(false);
      }
    }
  };

  useEffect(() => {
    setChecked(checked);
    isMounted.current = true;
    return () => {
      setChecked(false);
      isMounted.current = false;
    };
  }, [checked]);

  return (
    <>
      <input
        type="checkbox"
        className="checked"
        checked={checked}
        disabled={load}
        onChange={() => handleCheckDone(client?.assignment, client.id)}
      />
      {load && <CircularProgress size={15} style={{ marginLeft: 5 }} />}
    </>
  );
};

export default Done;
