import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFetchListContentCreators } from '../../../../store/slices/assignments';

export const useContentCreators = (open, all = false) => {
  const { loading, active, inactive } = useSelector(
    (state) => state.assignments
  );
  const dispatch = useDispatch();
  const [contentCreator, setContentCreator] = useState(null);
  const [contentCreators, setContentCreators] = useState([]);
  const activeRef = useRef([]);
  const inactiveRef = useRef([]);

  const reset = () => {
    setContentCreator(null);
    setContentCreators([]);
  };

  const assignContentCreator = useCallback((contentCreator) => {
    setContentCreator(contentCreator);
  }, []);

  const assignContentCreators = useCallback(() => {
    dispatch(getFetchListContentCreators());
    if (!loading) {
      setContentCreators([...activeRef.current, ...inactiveRef.current]);
    }
    if (all) {
      setContentCreators((state) => [{ name: 'All', email: null }, ...state]);
    }
  }, [dispatch, loading, all]);

  useEffect(() => {
    activeRef.current = active;
    inactiveRef.current = inactive;
  }, [active, inactive]);

  useEffect(() => {
    if (open) {
      assignContentCreators();
    } else {
      reset();
    }
    return () => {
      reset();
    };
  }, [open, assignContentCreators]);

  return {
    loading,
    contentCreator,
    contentCreators,
    assignContentCreator,
    all
  };
};
