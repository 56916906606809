import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ItemContentCreator } from './';
import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';

export const ListContentCreators = ({
  title,
  contentCreators,
  margin = ''
}) => {
  const { loading } = useSelector((state) => state.assignments);
  const [pointerEvents, setPointerEvents] = useState(false);
  const cancelMouseEvents = useCallback((pointerEvents) => {
    setPointerEvents(pointerEvents);
  }, []);

  return (
    <div
      style={{
        width: '48%',
        pointerEvents: `${pointerEvents ? 'none' : 'all'}`
      }}>
      <h3 className="text-center">{title}</h3>
      {loading ? (
        <div className="text-center mt-5">
          <CircularProgress size={20} />
        </div>
      ) : (
        <List style={{ overflowY: 'visible' }} className={margin}>
          {contentCreators.length === 0 ? (
            <p className="text-center m-5 h4 font-weight-light">
              No {title} Content Creators
            </p>
          ) : (
            contentCreators.map((contentCreator) => (
              <ItemContentCreator
                key={contentCreator.id}
                cancelMouseEvents={cancelMouseEvents}
                {...contentCreator}
              />
            ))
          )}
        </List>
      )}
    </div>
  );
};
