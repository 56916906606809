import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { useFetchImages } from '../../../../hooks/useFetchImages';
import { useSearchQuery } from '../../../../hooks/useSearchQuery';
import { getUnsplashImages } from '../../../../utils/api-images/getUnplashImages';
import getUrlImage from '../../../../utils/api-images/getUrlImage';
import SectionTitle from './Partials/SectionTitle';
import LoadingSpinner from './Partials/LoadingSpinner';
import ImagesContainer from './Partials/ImagesContainer';
import Image from './Partials/Image';
import DescriptionSection from './Partials/DescriptionSection';

const Unsplash = ({ setAppImage }) => {
  const [query, handleInputQuery] = useSearchQuery();
  const [detail, setDetail] = useState(null);
  const [{ images, loading }, more] = useFetchImages(query, getUnsplashImages);

  const handleSelect = (img) => {
    setDetail({
      name: img.user.name,
      authorPage: img.links.html,
      description: img.description
    });
    const url = img.urls.regular;
    getUrlImage(url, setAppImage);
  };

  useEffect(() => {
    return () => {
      setDetail('');
    };
  }, []);

  return (
    <>
      <main className="card card-app-photos pd-5">
        <SectionTitle title={'Unsplash Gallery'} />
        <TextField
          id="search"
          label="Search"
          value={query}
          onChange={handleInputQuery}
          className="ml-2"
          autoComplete="off"
        />
        <ImagesContainer more={more}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div className="row">
              {images.map((img, index) => {
                return (
                  <Image
                    key={index}
                    alt={img.user.name}
                    src={img.urls.regular}
                    userPage={img.links.html}
                    selectImage={() => handleSelect(img)}
                  />
                );
              })}
            </div>
          )}
        </ImagesContainer>
      </main>
      <DescriptionSection
        title={'Unplash Gallery Description'}
        detail={detail}
      />
    </>
  );
};

export default Unsplash;
