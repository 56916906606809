import { useState, useEffect, useCallback } from 'react';
import { API_ADMIN_CLIENTS } from '../../../utils/constants';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../store/slices/auth';

const useGetClientData = (initialState) => {
  const { userInfo } = useSelector(authSelector);
  const [clientsTableData, setClientsTableData] = useState(initialState);
  const email = userInfo.email;
  const role = userInfo.role;

  const getFetchClientsData = useCallback(async () => {
    const clients = await (
      await fetch(`${API_ADMIN_CLIENTS}?email=${email}&role=${role}`)
    ).json();

    setClientsTableData({
      loading: false,
      clients: clients
    });
  }, [email, role]);

  const findIndex = (clients, clientId) => {
    return clients.findIndex((client) => client.id === clientId);
  };

  const getClientsData = useCallback(async (clients, client) => {
    try {
      const clientIndex = findIndex(clients, client.id);
      clients[clientIndex] = client;

      setClientsTableData({
        clients,
        loading: false
      });
    } catch (error) {
      console.error('An error has ocurred: ', error);
    }
  }, []);

  useEffect(() => {
    getFetchClientsData();
    return () => {
      setClientsTableData({
        loading: true,
        clients: []
      });
    };
  }, [getFetchClientsData]);

  return [clientsTableData, getClientsData];
};

export default useGetClientData;
