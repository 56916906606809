const textRatingToNumber = ( text ) => {
  const numberMap = {
    "one": 1,
    "two": 2,
    "three": 3,
    "four": 4,
    "five": 5
  };

  const lowerCaseText = text.toLowerCase();
  const number = numberMap[lowerCaseText];
  
  if (number === undefined) {
    throw new Error("Input must be one of 'one', 'two', 'three', 'four', 'five'");
  }
  
  return number;
};

export default textRatingToNumber;