import { useContext } from 'react';
import { SearchContext } from '../contexts/SearchContext';

export const useSearchQuery = () => {
  const { query, setQuery } = useContext(SearchContext);

  const handleInputQuery = (e) => {
    setQuery(e.target.value);
  };

  return [query, handleInputQuery];
};
