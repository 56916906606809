import React from 'react';

const ImagesContainer = (props) => {
  const { children, more } = props;
  const imageContainer = document.querySelector('#image-container');

  const handleScroll = (e) => {
    if (
      imageContainer?.offsetHeight + imageContainer?.scrollTop >=
      imageContainer?.scrollHeight
    ) {
      more();
    }
  };

  return (
    <section
      id="image-container"
      className="images-wrapper mt-3"
      onScroll={() => handleScroll(more)}>
      <div className="container">{children}</div>
    </section>
  );
};

export default ImagesContainer;
