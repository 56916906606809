import Swal from 'sweetalert2';

export const errorAlert = (msg) => {
  Swal.fire({
    icon: 'error',
    title: 'Error!',
    text: msg,
    allowOutsideClick: false,
    allowEscapeKey: false
  });
};
