import React, { useEffect, useState } from 'react';

const ProgressBar = (props) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    // if (props.progress !== progress) {
    //   setProgress(props.progress);
    // }

    if (props.isActive !== isActive) {
      setIsActive(props.isActive);
    }
  }, [props, isActive]);

  return (
    <div>
      {props.isActive && (
        <div
          className="progress ht-2 w-100 mg-b-10"
          style={{
            margin: '0px',
            bottom: '68px',
            position: 'absolute',
            left: '0px'
          }}>
          <div
            className="progress-bar"
            role="progressbar"
            style={{
              width: `${Math.floor(props.progress * 5) / 100}%`
            }}></div>
          <div
            className="progress-bar"
            role="progressbar"
            style={{
              width: `${Math.floor(props.progress * 95) / 100}%`
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
