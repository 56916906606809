import React, { useEffect, useState } from 'react';

import axios from 'axios';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import DataTables from '../../components/DataTables';
import './style.css';
import DateFilter from './Filters/DateFilter';
import StarIcon from '@material-ui/icons/Star';
import Approve from './Actions/approve';
import { useSelector, useDispatch } from 'react-redux';
import { authSelector } from '../../store/slices/auth';
import textRatingToNumber from '../Client/ReviewsSection/services/textRatingToNumber';
import { v4 as uuid } from 'uuid';
import {
  API_REVIEW_UPDATE_MSG_REPLY,
  API_GET_GOOGLE_REVIEWS_V2,
  API_GET_YELP_REVIEWS
} from '../../utils';

const Actions = ({ cell, row }) => {
  const reviews = useSelector((state) => state.reviews);
  const dispatch = useDispatch();
  const handleClick = async () => {
    if (reviews?.editable?.id === row.id && reviews?.editable?.editable) {
      dispatch({
        type: 'reviews/setEditable',
        payload: { id: row.id, editable: false }
      });
      dispatch({
        type: 'reviews/save',
        payload: {
          loading: true,
          reviews: reviews?.reviews.map((review) =>
            review.id === row.id
              ? { ...review, msgReply: reviews?.editable?.message }
              : review
          )
        }
      });

      try {
        const response = await axios.post(`${API_REVIEW_UPDATE_MSG_REPLY}/`, {
          id: row.id,
          msgReply: reviews?.editable?.message
        });

        if (response.status === 200) {
          dispatch({
            type: 'reviews/save',
            payload: { loading: false }
          });
        }
      } catch (err) {
        console.error(err);
      }
    } else if (!reviews?.editable?.editable) {
      dispatch({
        type: 'reviews/setEditable',
        payload: { id: row.id, editable: true, message: row.msgReply }
      });
    }
  };
  const handleApproved = (value) => {
    dispatch({
      type: 'reviews/approve',
      payload: reviews?.reviews.map((review) =>
        review.id === row.id ? { ...review, checked: value } : review
      )
    });
  };

  return (
    <div>
      {!row?.checked ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          {reviews?.editable?.editable && reviews?.editable?.id === row.id ? (
            <button className="button-like-a nav-link" onClick={handleClick}>
              <i className="fas fa-lg fa-check" />
              <span style={{ color: 'gray', marginLeft: '10px' }}>Confirm</span>
            </button>
          ) : reviews?.loading ? (
            <>
              <div
                className="spinner-border spinner-border-sm ml-2"
                role="status"></div>
              <span style={{ color: 'gray', marginLeft: '10px' }}>loading</span>
            </>
          ) : (
            <button className="button-like-a nav-link" onClick={handleClick}>
              <i className="fas fa-fw fa-lg fa-pencil-alt feather" />
              <span style={{ color: 'gray', marginLeft: '10px' }}>Edit</span>
            </button>
          )}
        </div>
      ) : (
        <></>
      )}
      {(reviews?.editable?.editable && reviews?.editable?.id === row.id) ||
      reviews?.loading ? (
        <></>
      ) : (
        <div style={{}}>
          <Approve
            approved={row?.checked}
            reviewId={row?.reviewId}
            callback={handleApproved}
          />
        </div>
      )}
    </div>
  );
};

const Answer = ({ cell, row }) => {
  const reviews = useSelector((state) => state.reviews);
  const editable =
    reviews?.editable?.editable && reviews?.editable?.id === row.id;
  const dispatch = useDispatch();

  const handleEditMessage = (msg) => {
    dispatch({ type: 'reviews/editMessage', payload: msg });
  };

  if (editable && reviews?.editable?.id === row.id) {
    return (
      <div className="flex-grow-1 flex-shrink-1">
        <p className="mb-1 event-desc tx-gray-900 mg-b-40">
          <textarea
            type="text"
            disabled={!editable}
            className="form-control"
            placeholder="Edit the review answer message here"
            defaultValue={cell}
            value={!editable ? cell : reviews.message}
            style={{
              borderRadius: '3px',
              paddingLeft: '12px',
              paddingRight: '12px',
              paddingBottom: '10px',
              marginLeft: '5px',
              marginBottom: '20px',
              minHeight: '100px',
              maxHeight: '100%',
              width: '97%',
              resize: editable ? 'none' : 'none',
              border: editable ? '1px solid #ced4da' : 'none',
              backgroundColor: 'inherit'
            }}
            onChange={(e) => {
              handleEditMessage(e.target.value);
            }}
            onFocus={(e) => {
              handleEditMessage(e.target.value);
            }}
          />
        </p>
      </div>
    );
  } else {
    return <>{cell}</>;
  }
};

const Reviews = (props) => {
  const { userInfo } = useSelector(authSelector);
  const [showReplied, setShowReplied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(null);
  const [showGoogleReviews, setShowGoogleReviews] = useState(true);

  const handleSetShowGoogleReviews = () => {
    setShowGoogleReviews(!showGoogleReviews);
  };

  const defaultDates = {
    startDate: getMonthAgoUnix(),
    endDate: getCurrentUnix()
  };
  const reviews = useSelector((state) => state?.reviews?.reviews);
  const dispatch = useDispatch();

  const setStateReviews = (reviews) => {
    dispatch({ type: 'reviews/setReviews', payload: reviews });
  };
  const handleFilterChange = (newFilter) => {
    console.log('newFilter: ', newFilter);
    setFilter(newFilter);
  };

  const accountManagers = [
    {
      id: '0',
      full_name: 'All'
    },
    {
      id: '3359678000000161021',
      full_name: 'Stefan Singer'
    },
    {
      id: '3359678000000189001',
      full_name: 'Claire Sabando'
    },
    {
      id: '3359678000002719001',
      full_name: 'Dj Hargrove'
    },
    {
      id: '3359678000003451001',
      full_name: 'Robin Lincoln-Codjoe'
    },
    {
      id: '3359678000043808001',
      full_name: 'Esther Rowe'
    },
    {
      id: '3359678000046853103',
      full_name: 'Daniel Dominguez'
    },
    {
      id: '3359678000057925001',
      full_name: 'Paula Halfond'
    },
    {
      id: '3359678000094902026',
      full_name: 'Jose Valera'
    },
    {
      id: '3359678000157782001',
      full_name: 'Lauren  Turkovic'
    },
    {
      id: '3359678000163193001',
      full_name: 'Michael Ognar-Pettersson'
    },
    {
      id: '3359678000163912001',
      full_name: 'Heather DeMorris'
    },
    {
      id: '3359678000164337001',
      full_name: 'Phillippa Sirdar'
    },
    {
      id: '3359678000176335001',
      full_name: 'Deborah Moxam'
    },
    {
      id: '3359678000177646027',
      full_name: 'DeAnnie Baker'
    },
    {
      id: '3359678000177656001',
      full_name: 'Jorge Ramirez'
    },
    {
      id: '3359678000182709001',
      full_name: 'Christiana Daskalakis'
    },
    {
      id: '865239221',
      full_name: 'Zachary Marsh'
    },
    {
      id: '865239223',
      full_name: 'Emily Betancourt'
    }
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getReviews = async (queryParams) => {
    setStateReviews([]);
    setLoading(true);

    let url = showGoogleReviews
      ? API_GET_GOOGLE_REVIEWS_V2
      : API_GET_YELP_REVIEWS;

    axios.get(url, { params: { ...queryParams } }).then((res) => {
      console.log(res?.data);
      let reviews = res.data;
      reviews = reviews.map((item) => {
        item.id = item.reviewId;
        // Search the account manager by his id
        const manager = accountManagers.find(
          (manager) => manager.id === item.accountOwner
        );

        // Verify if the manager exists
        if (manager) {
          item.assignedTo = manager.full_name;
        } else {
          console.info(
            `There's no account manager with the id: ${item.accountOwner}`
          );
        }

        return item;
      });
      const sortedData = reviews.sort(
        (a, b) => b.createdTimeUnix - a.createdTimeUnix
      );
      setStateReviews(sortedData);
      setLoading(false);
    });
  };

  function getMonthAgoUnix() {
    const today = new Date();
    today.setMonth(today.getMonth() - 1);
    return Math.floor(today.getTime()); // Convertir a segundos
  }

  function getCurrentUnix() {
    return Math.floor(new Date().getTime()); // Convertir a segundos
  }

  useEffect(() => {
    console.log('has occured a change!');
  }, [reviews.editable]);

  const Rating = (cell, row) => {
    const stars = [];
    const rating = showGoogleReviews
      ? textRatingToNumber(row.starRating)
      : row.starRating;

    for (let i = 0; i < rating; i++) {
      stars.push(<StarIcon key={uuid()} className="star" />);
    }

    return <div>{stars}</div>;
  };
  const RepliedBy = (cell, row) => {
    if (!row?.checked) {
      return <div style={{ color: '#8392A5' }}>Pending by Account Owner</div>;
    } else if (!row.replied && row.schedule && !row?.rejected) {
      return <div style={{ color: '#8392A5' }}>Pending (scheduled)</div>;
    } else if (row.rejected) {
      return <div style={{ color: '#8392A5' }}>Denied by Client</div>;
    } else if (row.replied && !row.schedule) {
      return <div style={{ color: '#8392A5' }}>Sent By AI</div>;
    } else if (
      (row.replied && row.schedule > Math.floor(Date.now() / 1000)) ||
      (row.replied && row.approved)
    ) {
      return <div style={{ color: '#8392A5' }}>Approved by Client</div>;
    } else if (!row.replied && !row.schedule && row?.checked) {
      return <div style={{ color: '#8392A5' }}>Pending by scheduler</div>;
    } else if (!row.replied && !row.schedule) {
      return <div style={{ color: '#8392A5' }}>Pending by Client</div>;
    } else if (row.replied && !row.rev_comment) {
      return <div style={{ color: '#8392A5' }}>Replied by Client</div>;
    } else {
      return <div style={{ color: '#8392A5' }}>Sent By AI</div>;
    }
  };

  const DateFormat = (cell, row) => {
    if (cell && !row.rejected) {
      if (showGoogleReviews) {
        return new Date(cell * 1000).toLocaleDateString();
      } else {
        return new Date(cell).toLocaleDateString();
      }
    } else {
      return <>--</>;
    }
  };
  useEffect(() => {
    if (filter !== null) {
      console.log('changed', filter);
    }

    const currentUserZohoInfo = () => {
      const userName = userInfo?.displayName?.toLowerCase();

      return (
        accountManagers.find((element) =>
          userName.toLowerCase().includes(element.full_name.toLowerCase())
        ) || null
      );
    };

    const defaultFilters = {
      manager:
        userInfo.role !== 'superAdmin' ? currentUserZohoInfo()?.id || -1 : 0
    };

    getReviews({ replied: showReplied, ...defaultFilters, ...filter });
  }, [filter, showReplied, showGoogleReviews]);

  const clientColumns = [
    {
      dataField: 'reviewId',
      text: 'Id',
      hidden: true
    },
    {
      dataField: 'businessName',
      text: 'Client Name',
      hidden: showGoogleReviews,
      headerStyle: () => {
        return {
          width: '250px',
          textAlign: 'left'
        };
      },
      style: () => {
        return {
          paddingTop: '12px',
          textAlign: 'left'
        };
      }
    },
    {
      dataField: 'locationName',
      text: 'Client Name',
      hidden: !showGoogleReviews,
      headerStyle: () => {
        return {
          width: '250px',
          textAlign: 'left'
        };
      },
      style: () => {
        return {
          paddingTop: '12px',
          textAlign: 'left'
        };
      },
      formatter: (cell, row) => {
        return (
          <div>
            {row?.googleLink1 || row?.googleLink2 ? (
              <a target="n_blank" href={row?.googleLink2 || row?.googleLink1}>
                {cell}
              </a>
            ) : (
              cell
            )}{' '}
          </div>
        );
      }
    },
    {
      dataField: 'reviewer.name',
      text: 'Name',
      hidden: showGoogleReviews,
      headerStyle: () => {
        return {
          width: '120px'
        };
      },
      style: () => {
        return {
          paddingTop: '12px',
          textAlign: 'center'
        };
      }
    },
    {
      dataField: 'reviewer.displayName',
      text: 'Name',
      hidden: !showGoogleReviews,
      headerStyle: () => {
        return {
          width: '120px'
        };
      },
      style: () => {
        return {
          paddingTop: '12px',
          textAlign: 'center'
        };
      }
    },
    {
      dataField: 'createdTimeUnix',
      text: 'Created On',
      headerStyle: () => {
        return {
          width: '100px'
        };
      },
      style: () => {
        return {
          paddingTop: '12px',
          textAlign: 'center'
        };
      },
      formatter: DateFormat
    },
    {
      dataField: 'starRating',
      text: 'stars',
      headerStyle: () => {
        return {
          width: '145px'
        };
      },
      style: () => {
        return {
          paddingTop: '12px'
        };
      },
      formatter: Rating
    },
    {
      dataField: 'rev_comment',
      text: 'comment',
      headerStyle: () => {
        return {
          width: '270px'
        };
      },
      style: (cell, row) => {
        return {
          paddingTop: '12px',
          textAlign: !cell ? 'center' : 'left'
        };
      },
      formatter: (cell, row) => {
        if (cell) {
          return <>{cell}</>;
        } else {
          return <>--</>;
        }
      }
    },
    {
      dataField: 'msgReply',
      text: 'Answer',
      hidden: !showGoogleReviews,
      headerStyle: () => {
        return {
          width: '270px'
        };
      },
      style: () => {
        return {
          paddingTop: '12px'
        };
      },
      formatter: (cell, row) => <Answer cell={cell} row={row} />
    },
    {
      dataField: 'assignedTo',
      text: 'Assigned to',
      hidden: !showGoogleReviews,
      headerStyle: () => {
        return {
          width: '100px'
        };
      },
      style: () => {
        return {
          paddingTop: '12px',
          textAlign: 'center'
        };
      }
    },
    {
      dataField: 'replied',
      text: 'Replied by',
      hidden: !showGoogleReviews,
      headerStyle: () => {
        return {
          width: '85px'
        };
      },
      style: () => {
        return {
          paddingTop: '12px',
          textAlign: 'center'
        };
      },
      formatter: RepliedBy
    },
    {
      dataField: 'schedule',
      text: 'scheduled for',
      hidden: !showGoogleReviews,
      headerStyle: () => {
        return {
          width: '115px'
        };
      },
      style: () => {
        return {
          paddingTop: '12px',
          textAlign: 'center'
        };
      },
      formatter: DateFormat
    },
    {
      dataField: 'actions',
      text: 'actions',
      hidden: showReplied || !showGoogleReviews,
      headerStyle: () => {
        return {
          width: '110px',
          textAlign: 'center'
        };
      },
      style: () => {
        return {
          width: '100%',
          minHeight: '100px',
          marginBottom: '-2px',
          display: 'flex',
          justifyContent: 'center',
          padding: '3px',
          borderTop: '1px solid #e5e9f2',
          borderBottom: 'none',
          borderLeft: 'none',
          borderRight: 'none'
        };
      },
      formatter: (cell, row) => <Actions row={row} cell={cell} />
    }
  ];

  return (
    <div>
      <Header loading={loading} />
      <div>
        <div
          className="content-fixed"
          style={{
            overflow: 'auto'
          }}>
          <div
            style={{ display: 'flex', flexDirection: 'row', height: '50px' }}>
            <DateFilter
              filter={filter}
              showGoogleReviews={showGoogleReviews}
              showReplied={showReplied}
              defaultDates={defaultDates}
              onFilterChange={handleFilterChange}
              accountManagers={accountManagers}
              loading={loading}
              setShowReplied={setShowReplied}
              userInfo={userInfo}
            />
          </div>
        </div>
        <DataTables
          buttonsCallback={() => {}}
          userRole={userInfo.role}
          data={reviews}
          columns={clientColumns}
          noDataIndication={loading ? 'Loading...' : 'No Reviews'}
          handleModal={() => handleSetShowGoogleReviews()}
          title={`${showGoogleReviews ? 'Google' : 'Yelp'} Reviews ${
            reviews?.length > 0
              ? `(${reviews?.length}) ${showReplied ? 'replied' : ''}`
              : ``
          }`}
          loading={loading}
          showTotal={false}
          view={'reviews'}
          viewState={{ showGoogleReviews }}
        />
      </div>
      <Footer />
    </div>
  );
};

export default Reviews;
