import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import { SearchIcon } from '../../../Icons';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
    width: 300
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: '0.844rem'
  },
  iconButton: {
    padding: 10,
    '&:focus': {
      outline: 'none'
    }
  },
  divider: {
    height: 48
  }
}));

const Filter = ({ filterText, onFilter, onClear }) => {
  const classes = useStyles();

  return (
    <Paper component={'form'} className={classes.root}>
      <SearchIcon />
      <InputBase
        className={classes.input}
        placeholder="Search clients"
        inputProps={{ 'aria-label': 'search by client name' }}
        value={filterText}
        onChange={onFilter}
      />
      <Divider className={classes.divider} orientation="vertical" />
    </Paper>
  );
};

export default Filter;
