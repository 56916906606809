import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from 'react-router-dom';
import { months as initialStateMonths } from './months';
import { REPORTS_START_YEAR } from '../../../../utils/constants';

const start = Number(REPORTS_START_YEAR),
  end = new Date().getFullYear();

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    height: 100,
    marginTop: 30
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240
  }
}));

const MonthlyReportModal = ({ open, handleModal }) => {
  const classes = useStyles();
  const history = useHistory();
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const handleMonth = (e) => {
    const month = Number(e.target.value);
    setMonth(month);
  };

  const handleYear = (e) => {
    const year = Number(e.target.value);
    setYear(year);
  };

  const handleGenerate = (e) => {
    e.preventDefault();
    history.push(`/assignment/monthly/${month}/${year}`);
    handleModal(open);
  };

  const cleanUp = () => {
    setMonths([]);
    setYears([]);
    setMonth('');
    setYear('');
  };

  useEffect(() => {
    if (open) {
      setMonths(Object.keys(initialStateMonths));
      setYears([...Array(end - start + 1).keys()].map((x) => x + start));
      setMonth(`${new Date().getMonth() + 1}`);
      setYear(`${new Date().getFullYear()}`);
    } else {
      cleanUp();
    }
    return () => {
      cleanUp();
    };
  }, [open]);

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
      onClose={() => handleModal(open)}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" className="text-center">
        Monthly Report
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center">
          <form className={classes.container}>
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel>Month</InputLabel>
              <Select value={month} label="Month" onChange={handleMonth}>
                {months.map((month, index) => (
                  <MenuItem
                    key={month}
                    value={Object.values(initialStateMonths)[index]}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel>Year</InputLabel>
              <Select value={year} label="Year" onChange={handleYear}>
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        </Grid>
      </DialogContent>
      <DialogActions className="mb-3">
        <Button
          onClick={() => handleModal(open)}
          variant="outlined"
          color="inherit"
          style={{ marginRight: 15 }}>
          Close
        </Button>
        <Button
          onClick={handleGenerate}
          variant="outlined"
          color="primary"
          style={{ marginRight: 15 }}>
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MonthlyReportModal;
