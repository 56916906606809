import { API_ASSIGN_CREATOR } from '../../../../../utils/constants';

export const setUnassginContentCreator = async (assignment, clientId) => {
  const options = {
    method: 'DELETE',
    body: JSON.stringify({
      id: clientId,
      createdAt: assignment.createdAt,
      done: assignment.done,
      email: assignment.email,
      name: null
    })
  };
  try {
    const response = await fetch(API_ASSIGN_CREATOR, options);
    return response;
  } catch (error) {
    throw new Error('An error has ocurred when trying to fetch: ', error);
  }
};
