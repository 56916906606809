export const generateContent = () => {
  return async (dispatch) => {
    dispatch({ type: 'general/ia' });
  };
};

export const resetContentState = () => {
  return async (dispatch) => {
    return dispatch({ type: 'general/reset' });
  };
};
