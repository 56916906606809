import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { calculateTotalByContentCreator } from '../services';

const weekendDayStyle = {
  border: '1px solid #ddd',
  backgroundColor: '#f2f2f2',
  color: 'gray'
};

const workDayStyle = {
  border: '1px solid #ddd',
  backgroundColor: 'white'
};

const constentCreatorStyle = {
  backgroundColor: 'rgb(55, 102, 168)',
  border: '1px solid rgb(37, 79, 138)',
  color: 'white'
};

const PostByContentCreator = ({ days, contentCreator }) => {
  const [contentCreatorDays, setContentCreatorDays] = useState([]);
  const [total, setTotal] = useState(0);

  const populateMonthDays = useCallback(() => {
    const total = calculateTotalByContentCreator(contentCreator);
    setTotal(total);

    const contentCreatorDays = days.map(({ dayOfMonth, isWeekend }) => {
      const date = new Date(contentCreator.done_assignments[0]?.date).getDate();
      if (date !== dayOfMonth) {
        return { date: '', total: 0, isWeekend };
      }
      return {
        ...contentCreator.done_assignments.shift(),
        isWeekend
      };
    });
    setContentCreatorDays(contentCreatorDays);
  }, [days, contentCreator]);

  useEffect(() => {
    populateMonthDays();
    return () => {
      setContentCreatorDays([]);
      setTotal(0);
    };
  }, [populateMonthDays]);

  return (
    <TableRow>
      <TableCell style={constentCreatorStyle}>{contentCreator.name}</TableCell>
      {contentCreatorDays.map((contentCreatorDay, index) => {
        return (
          <TableCell
            style={contentCreatorDay.isWeekend ? weekendDayStyle : workDayStyle}
            className="text-center"
            key={contentCreator.name + index}>
            {contentCreatorDay.total !== 0 && contentCreatorDay.total}
          </TableCell>
        );
      })}
      <TableCell style={workDayStyle} className="text-center">
        {total}
      </TableCell>
    </TableRow>
  );
};

PostByContentCreator.protoTypes = {
  days: PropTypes.array.isRequired,
  contentCreator: PropTypes.object.isRequired
};

export default PostByContentCreator;
