import { useState, useEffect } from 'react';

const initialState = {
  images: [],
  loading: true
};

export const useFetchImages = (query, getImages) => {
  const [page, setPage] = useState(1);
  const [state, setState] = useState(initialState);

  /**
   * Function for load more images when scrolling
   */
  const more = async () => {
    const moreImages = await getImages(query, page + 1);
    if (moreImages.length > 0) {
      const { images } = state;
      setPage((prevPage) => prevPage + 1);
      setState({ images: [...images, ...moreImages] });
    }
  };

  useEffect(() => {
    setState(initialState);
    getImages(query).then((images) => setState({ images, loading: false }));
  }, [query, getImages]);

  return [state, more];
};
