import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListContentCreators } from './ContentCreator';
import { getFetchListContentCreators } from '../../../../store/slices/assignments';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

const AutoAssignmentModal = ({ open, handleModal }) => {
  const dispatch = useDispatch();
  const { inactive, active } = useSelector((state) => state.assignments);

  useEffect(() => {
    if (open) {
      dispatch(getFetchListContentCreators());
    }
  }, [open, dispatch]);

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'md'}
      onClose={() => handleModal(open)}
      disableEscapeKeyDown={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" className="text-center">
        Available Content Creators
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center">
          <ListContentCreators title={'Inactive'} contentCreators={inactive} />
          <Divider orientation="vertical" flexItem />
          <ListContentCreators title={'Active'} contentCreators={active} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleModal(open)}
          variant="outlined"
          color="inherit"
          style={{ marginRight: 15 }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AutoAssignmentModal;
