import React from 'react';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../store/slices/auth';
import Swal from 'sweetalert2';

const LogOut = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    Swal.fire({
      title: 'Sign Out',
      text: 'Are you sure you want to log out?',
      showCloseButton: true,
      showCancelButton: true
    }).then((res) => {
      if (res.value) {
        dispatch(logoutUser());
      }
    });
  };

  return (
    <button
      className="dropdown-item button-like-a"
      onClick={() => handleLogout()}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-log-out">
        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
        <polyline points="16 17 21 12 16 7"></polyline>
        <line x1="21" y1="12" x2="9" y2="12"></line>
      </svg>
      Sign Out
    </button>
  );
};

export default LogOut;
