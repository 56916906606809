import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import SinglePost from './SinglePost';
import { useSelector, useDispatch } from 'react-redux';
import {
  postsSelector,
  getPosts,
  clearPosts
} from '../../../store/slices/posts';
import { selectedClientSelector } from '../../../store/slices/selectedClient';
import './PostsSection.css';

const PostsSection = (props) => {
  const [monthFilterPosts, setMonthFilterPosts] = useState(
    new Date().getMonth() + 1
  );
  const [yearFilterPosts, setYearFilterPosts] = useState(
    new Date().getFullYear()
  );
  const { loadingPosts, postsLists } = useSelector(postsSelector);
  const { selectedClient } = useSelector(selectedClientSelector);

  let contentPosts = [];
  let contentPosts2 = [];
  const dispatch = useDispatch();

  postsLists.forEach((post, i) => {
    ((i / 2) % 1 === 0 ? contentPosts : contentPosts2).push(
      <SinglePost post={post} key={i} />
    );
  });

  // Fetch posts by date
  const fetchPosts = async (date) => {
    try {
      await dispatch(getPosts(date));
    } catch (e) {
      console.error(e);
    }
  };

  const handleClick = () => {
    let month = monthFilterPosts - 1 < 1 ? 12 : monthFilterPosts - 1;
    let year = monthFilterPosts - 1 < 1 ? yearFilterPosts - 1 : yearFilterPosts;
    setYearFilterPosts(year);
    setMonthFilterPosts(month);
  };

  useEffect(() => {
    fetchPosts({
      clientId: selectedClient.id,
      month: String(monthFilterPosts),
      year: String(yearFilterPosts),
      timeZone: String(selectedClient.timeZone)
    });
    // eslint-disable-next-line
  }, [yearFilterPosts, monthFilterPosts]);

  useEffect(() => {
    dispatch(clearPosts());
    setMonthFilterPosts(new Date().getMonth() + 1);
    setYearFilterPosts(new Date().getFullYear());
    if (!loadingPosts) {
      fetchPosts({
        clientId: selectedClient.id,
        month: String(new Date().getMonth() + 1),
        year: String(new Date().getFullYear()),
        timeZone: String(selectedClient.timeZone)
      });
    }
    // eslint-disable-next-line
  }, [selectedClient]);

  return (
    <div className="card" id="posts-section">
      <div className="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-between">
        <h6 className="tx-uppercase tx-semibold mg-b-0">Posts</h6>
      </div>
      {contentPosts.length + contentPosts2.length > 0 ? (
        <div className="card-body pd-25">
          <div className="row">
            <div className="col-sm col-lg-12 col-xl">{contentPosts}</div>
            <div className="col-sm col-lg-12 col-xl mg-t-25 mg-sm-t-0 mg-lg-t-25 mg-xl-t-0">
              {contentPosts2}
            </div>
          </div>
        </div>
      ) : (
        <span className="d-flex align-items-center justify-content-center pd-y-13 tx-gray-400">
          No posts
        </span>
      )}

      <div className="card-footer bg-transparent pd-y-15 pd-x-20">
        <nav className="nav nav-with-icon tx-13">
          <button
            className="button-like-a nav-link"
            onClick={() => handleClick()}>
            {loadingPosts ? (
              <>
                Loading...
                <div
                  className="spinner-border spinner-border-sm ml-2"
                  role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </>
            ) : (
              'Show more pictures'
            )}
            <i data-feather="chevron-down" className="mg-l-2 mg-r-0 mg-t-2"></i>
          </button>
          <a href="# " className={'sect-post-ind-date pt-2'}>
            <span>
              <strong>{moment.months(monthFilterPosts - 1)}</strong>{' '}
              {yearFilterPosts} to{' '}
              <strong>{moment.months(new Date().getMonth())}</strong>{' '}
              {new Date().getFullYear()}
            </span>
          </a>
        </nav>
      </div>
    </div>
  );
};

export default PostsSection;
