import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { useFetchImages } from '../../../../hooks/useFetchImages';
import { useSearchQuery } from '../../../../hooks/useSearchQuery';
import { getPexelsImages } from '../../../../utils/api-images/getPexelsImages';
import getUrlImage from '../../../../utils/api-images/getUrlImage';
import SectionTitle from './Partials/SectionTitle';
import LoadingSpinner from './Partials/LoadingSpinner';
import ImagesContainer from './Partials/ImagesContainer';
import Image from './Partials/Image';
import DescriptionSection from './Partials/DescriptionSection';

const Pexels = ({ setAppImage }) => {
  const [query, handleInputQuery] = useSearchQuery();
  const [detail, setDetail] = useState(null);
  const [{ images, loading }, more] = useFetchImages(query, getPexelsImages);

  const handleSelect = (img, photographer, photographer_url) => {
    setDetail({
      name: photographer,
      authorPage: photographer_url
    });
    const url = img.landscape;
    getUrlImage(url, setAppImage);
  };

  useEffect(() => {
    return () => {
      setDetail('');
    };
  }, []);

  return (
    <>
      <main className="card card-app-photos pd-5">
        <SectionTitle title={'Pexels Gallery'} />
        <TextField
          id="search"
          label="Search"
          value={query}
          onChange={handleInputQuery}
          className="ml-2"
          autoComplete="off"
        />
        <ImagesContainer more={more}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div className="row">
              {images.map(
                ({ src: img, photographer, photographer_url }, index) => {
                  return (
                    <Image
                      key={index}
                      alt={photographer}
                      src={img.landscape}
                      userPage={photographer_url}
                      selectImage={() =>
                        handleSelect(img, photographer, photographer_url)
                      }
                    />
                  );
                }
              )}
            </div>
          )}
        </ImagesContainer>
      </main>
      <DescriptionSection
        title={'Pexels Gallery Description'}
        detail={detail}
      />
    </>
  );
};

export default Pexels;
