import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './styles.css';

const PhoneField = ({
  title,
  children,
  isEditing,
  onFieldChange,
  canEdit,
  googleProfileInfo
}) => {
  const [localValue, setLocalValue] = useState(children);

  // MinusIcon is used for empty fields
  const isMinusIcon =
    children.props?.className?.includes('fas fa-minus') ||
    (typeof children === 'string' && children === 'fas fa-minus');

  const handlePhoneChange = (e) => {
    const newValue = e.target.value;

    if (isMinusIcon) {
      setLocalValue(localValue);
    }

    if (isEditing && canEdit && !isMinusIcon) {
      setLocalValue(newValue);
      onFieldChange(newValue);
    }
  };

  return (
    <div className="info-field">
      <div>
        <div>
          <strong>{title}:</strong>
        </div>
      </div>
      <div style={{ marginTop: '14px' }}>
        {isEditing && canEdit && !isMinusIcon ? (
          <>
            <PhoneInput
              className="form-control phoneField client-profile input"
              placeholder="Enter phone number"
              value={googleProfileInfo?.primaryPhone}
              onChange={handlePhoneChange}
              defaultCountry="US"
            />
          </>
        ) : (
          <div>
            {isEditing && canEdit && isMinusIcon ? (
              <>
                <PhoneInput
                  placeholder="Enter phone number"
                  value={''}
                  onChange={handlePhoneChange}
                  defaultCountry="US"
                />
              </>
            ) : (
              <>
                <span>{localValue}</span>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PhoneField;
