import React, { useState } from 'react';

import Select from 'react-select';
import { BUSINESSHOURS } from '../../../utils';

const customMultiSelectorStyle = {
  control: (provided) => ({
    ...provided,
    width: '88px',
    cursor: 'pointer'
  }),

  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#3B5998',
    color: 'black',
    borderRadius: '0px'
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '205px'
  }),
  valueContainer: (provided) => ({
    ...provided,
    maxHeight: '35px',
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollbarWidth: 'thin',
    scrollbarColor: 'white white',
    '&::-webkit-scrollbar': {
      width: '8px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'white',
      borderRadius: '8px'
    }
  })
};
const Schedule = ({
  dayName,
  values,
  setFieldValue,
  errors,
  touched,
  onBlur
}) => {
  const [isOpen, setIsOpen] = useState(
    !(values?.businessHours[dayName.toLowerCase()] === '/')
  );

  const toggleState = () => {
    if (isOpen) {
      setFieldValue(`businessHours.${dayName.toLowerCase()}`, '/');
    }
    if (!isOpen) {
      setFieldValue(`businessHours.${dayName.toLowerCase()}`, '9/18');
    }
    setIsOpen(!isOpen);
  };

  const [startDate, setStartDate] = useState(
    values?.businessHours[dayName.toLowerCase()].split('/')[0] || '9'
  );
  const [endDate, setEndDate] = useState(
    values?.businessHours[dayName.toLowerCase()].split('/')[1] || '9'
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: '2px'
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row',
          width: '100%'
        }}>
        <div className="font" style={{ display: 'flex', width: '75px' }}>
          {dayName}
        </div>
        <span
          style={{
            height: 'calc(1em + 0.9375rem + 2px)',
            display: 'flex',
            alignItems: 'center'
          }}
          className={`font clickable ${isOpen ? 'open' : 'closed'}`}
          onClick={toggleState}>
          {isOpen ? 'Open' : 'Closed'}
        </span>
        {isOpen && (
          <div
            className="font"
            style={{
              display: 'flex',
              width: '203.5px',
              justifyContent: 'flex-end'
            }}>
            <Select
              required
              options={BUSINESSHOURS}
              className="basic-single"
              isSearchable={false}
              value={BUSINESSHOURS.find((bh) => bh.value === Number(startDate))}
              onChange={(selectedOption) => {
                setStartDate(selectedOption.value);
                setFieldValue(
                  `businessHours.${dayName.toLowerCase()}`,
                  `${selectedOption.value}/${
                    values?.businessHours[dayName.toLowerCase()].split('/')[1]
                  }`
                );
              }}
              styles={{
                ...customMultiSelectorStyle,
                control: (provided) => ({
                  ...provided,
                  ...customMultiSelectorStyle.control(),
                  ...(touched.serviceArea && errors.serviceArea
                    ? {
                        borderColor: 'red'
                      }
                    : {})
                })
              }}
            />
            <span className="divider">-</span>
            <Select
              required
              options={BUSINESSHOURS}
              className="basic-single"
              isSearchable={false}
              value={BUSINESSHOURS.find((bh) => bh.value === Number(endDate))}
              onChange={(selectedOption) => {
                setEndDate(selectedOption.value);
                setFieldValue(
                  `businessHours.${dayName.toLowerCase()}`,
                  `${
                    values?.businessHours[dayName.toLowerCase()].split('/')[0]
                  }/${selectedOption.value}`
                );
              }}
              styles={{
                ...customMultiSelectorStyle,
                control: (provided) => ({
                  ...provided,
                  ...customMultiSelectorStyle.control(),
                  ...(touched.serviceArea && errors.serviceArea
                    ? {
                        borderColor: 'red'
                      }
                    : {})
                })
              }}
            />
          </div>
        )}
      </div>
      <div
        style={{
          alignSelf: 'center'
        }}>
        {touched.businessHours?.[dayName.toLowerCase()] &&
          errors.businessHours?.[dayName.toLowerCase()] && (
            <div className="invalid-feedback d-inline">
              {errors.businessHours?.[dayName.toLowerCase()]}
            </div>
          )}
      </div>
    </div>
  );
};
const BusinessHours = ({ values, setFieldValue, errors, touched, onBlur }) => {
  const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ];
  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '100px',
          flexDirection: 'row'
        }}
        className="font">
        <p>Business Hours</p>
      </div>
      {days.map((day) => {
        return (
          <Schedule
            key={day}
            dayName={day}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            onBlur={onBlur}
          />
        );
      })}
    </>
  );
};
export default BusinessHours;
