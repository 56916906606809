import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import Select from 'react-select';
import axios from 'axios';
import {
  API_GROUPS,
  API_CREATE_CONTENT_STRATEGIES
} from '../../utils/constants';
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { selectedClientSelector } from '../../store/slices/selectedClient';
import './styles.css';
const NewContentStrategyModal = ({
  isUser,
  showModal,
  handleClose,
  callbackFromParent,
  defaultData
}) => {
  const { selectedClient } = useSelector(selectedClientSelector);
  const [currStep, setCurrStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [optionsData, setOptionsData] = useState([]);

  const validationSchema = yup.object().shape({
    strategyName: yup
      .string()
      // .matches(
      //   /^[a-zA-Z0-9áéíóúÁÉÍÓÚ][a-zA-Z0-9áéíóúÁÉÍÓÚ\s',.]*$/,
      //   'Invalid format'
      // )
      .required('Strategy name is required'),

    industry: yup
      .array()
      .of(
        yup.string()
        // .matches(
        //   /^[a-zA-Z0-9áéíóúÁÉÍÓÚ][a-zA-Z0-9áéíóúÁÉÍÓÚ\s',.]*$/,
        //   'An industry has an invalid format'
        // )
      )
      .min(1, 'Must have at least 1 Industry selected')
  });

  const initialValues = {
    strategyName: '', // String required
    industry: [] // String required
  };

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const reset = (resetForm) => {
    resetForm();
  };

  const handlePut = async (data = {}) => {
    const newCStrategySave = async (data) => {
      const newClientUrl = API_CREATE_CONTENT_STRATEGIES;
      //setSavingClient(true);// TODO: implement a loading animation
      if (false) {
        // TODO: improve validations
        Swal.fire({
          title: 'Error!',
          text: 'Incomplete Values.'
        }).then((r) => {});
        return;
      }

      const clientData = {
        ...data
      };

      await axios({
        method: 'post',
        url: newClientUrl,
        data: clientData
      })
        .then(function (response) {
          if (response.status === 200) {
            Swal.fire({
              type: 'success',
              title: 'Success!',
              text: 'The strategy was created successfully.'
            }).then(() => {
              setLoading(false);
              setCurrStep(1);
              handleClose();
              someFn(clientData);
              // TODO: maybe we should handle logic to reset the modal state
            });
          }
        })
        .catch(function (xhr, ajaxOptions, thrownError) {
          Swal.fire({
            type: 'error',
            title: 'Error!',
            text: 'The strategy was not created due an error.'
          }).then(() => {
            // TODO: Handle error
          });
        });
    };
    setLoading(true);
    await newCStrategySave(data);
    setCurrStep(1);
    handleClose();
    someFn({});
  };

  const ContentStep = ({ current, title, children, values }) => (
    <>
      <h5
        id="wizard1-h-0"
        tabIndex="-1"
        className={cx('title', {
          current,
          'd-none': !current
        })}>
        {title}
      </h5>
      <section
        id="wizard1-p-0"
        className={cx('body', {
          current,
          'd-none': !current
        })}>
        {children}
      </section>
    </>
  );
  const Step = ({ children, step, current }) => (
    <li
      className={cx({
        first: step === 1,
        done: step < currStep || currStep === steps.length,
        current: currStep === step,
        disabled: !current
      })}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
      <a>
        <span className="number">{step}</span>
        <span style={{ maxWidth: '120px' }} className="title">
          {children}
        </span>
      </a>
    </li>
  );

  const IndustryContentPrompts = ({
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue
  }) => (
    <>
      <div style={{ display: 'flex', marginTop: '28px', alignItems: 'center' }}>
        <h6 style={{ marginRight: '18px', marginTop: '8px' }}>
          Name of Content Strategy
        </h6>
        <div
          style={{
            width: '33%',
            height: '35px'
          }}>
          <textarea
            id="strategyName"
            className="form-control"
            placeholder="Type a name"
            type="text"
            defaultValue={values.strategyName}
            onChange={handleChange}
            onBlur={handleBlur}
            style={{
              height: '35px',
              resize: 'none',
              borderRadius: '6px',
              border: 'none',
              outline: 'none',
              backgroundColor: '#F3F4F7',
              color: '#3B5998',
              fontWeight: '500'
            }}></textarea>
          {errors.strategyName && (
            <div className="invalid-feedback d-inline">
              {errors.strategyName}
            </div>
          )}
        </div>
      </div>
    </>
  );
  const customStyles = {
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#3B5998' // Change the background color of the selected item
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'white' // Change the text color of the selected item
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: 'white',
      ':hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        color: 'white'
      }
    })
  };

  const LinkToGroups = ({
    setFieldValue,
    values,
    errors,
    touched,
    optionsData,
    setOptionsData
  }) => {
    const [industry, setIndustry] = useState([]);

    const userInfo = useSelector((state) => state.auth.userInfo);
    useEffect(() => {
      if (optionsData.length === 0) {
        axios
          .get(API_GROUPS, {
            headers: {
              Authorization: `bearer ${userInfo.accessToken}`
            }
          })
          .then((response) => {
            const groupsData = response.data.groups;
            setOptionsData(groupsData);
          })
          .catch((error) => {
            console.error('error ' + error);
          });
      }
    }, [optionsData, setOptionsData, userInfo.accessToken]);
    return (
      <div style={{ width: '360px' }}>
        <div className="form-group">
          <Select
            id="industry"
            className="basic-single"
            classNamePrefix="select"
            styles={customStyles}
            options={optionsData?.map((option, idx) => ({
              value: option.id,
              label: option.groupName
            }))}
            isMulti
            value={
              industry?.length > 0
                ? industry
                : values?.industry?.map((igt, index) => {
                    return { value: index, label: igt };
                  })
            }
            onChange={(selectedOption) => {
              setIndustry(selectedOption ? selectedOption : []);
              setFieldValue(
                'industry',
                selectedOption ? selectedOption?.map((igt) => igt.label) : []
              );
            }}
          />
          {errors.industry && touched.industry && (
            <div className="invalid-feedback d-inline">{errors.industry}</div>
          )}
        </div>
      </div>
    );
  };

  const RenderContentSteps = ({ ...props }) => {
    return steps.map((step, index) => {
      return (
        <ContentStep
          key={index}
          current={currStep === index + 1}
          title={step.title}>
          {step.content({ ...props })}
        </ContentStep>
      );
    });
  };

  const steps = [
    {
      title: 'Industry Content Prompts',
      content: ({ ...props }) => <IndustryContentPrompts {...props} />
    },
    ...(!isUser
      ? [
          {
            title: 'Link to Industries',
            content: ({ ...props }) => <LinkToGroups {...props} />
          }
        ]
      : []),
    ...(isUser
      ? [
          {
            title: 'Photo Library',
            content: ({ ...props }) => <></> // @nicolas Here you can add the photo library component
          }
        ]
      : [])
  ];

  const someFn = (listInfo) => {
    callbackFromParent(listInfo);
  };
  return (
    <Modal
      id="modal-dialog-onboarding"
      backdrop="static"
      keyboard={false}
      show={showModal}
      onHide={handleClose}>
      <Formik
        onSubmit={(data) => {
          handlePut({ ...data, id: defaultData?.id });
        }}
        validateOnBlur={true}
        initialValues={defaultData || initialValues}
        validationSchema={validationSchema}>
        {(props) => {
          const { handleSubmit, handleReset, setFieldTouched, values } = props;
          return (
            <Form
              autoComplete="off"
              onKeyDown={onKeyDown}
              className="needs-validation"
              noValidate>
              <div id="wizard1" className="wizard vertical">
                <Modal.Header>
                  <h5 className="modal-title" id="addNewClientLabel">
                    {defaultData?.strategyName ? 'Edit' : 'Create'} Content
                    Strategy for{' '}
                    {isUser ? (
                      <span style={{ color: '#3B5998' }}>
                        {selectedClient.clientName}
                      </span>
                    ) : (
                      'Industries'
                    )}
                  </h5>
                  <button
                    onClick={() => {
                      setLoading(false);
                      setCurrStep(1);
                      reset.bind(null, handleReset());
                      handleClose();
                    }}
                    className="close"
                    type="button">
                    <span aria-hidden="true">×</span>
                  </button>
                </Modal.Header>
                <div className="steps clearfix">
                  <ul>
                    {steps.map((step, index) => {
                      return (
                        <Step
                          key={index}
                          step={index + 1}
                          current={currStep === index + 1}>
                          {step.title}
                        </Step>
                      );
                    })}
                  </ul>
                </div>
                <div
                  className="content clearfix"
                  style={{ minHeight: '605px' }}>
                  <RenderContentSteps
                    {...{ ...props, optionsData, setOptionsData }}
                  />
                </div>
                <div className="actions clearfix">
                  <ul>
                    <li
                      className="disabled"
                      style={{
                        visibility: currStep !== 1 ? 'visible' : 'hidden'
                      }}>
                      <button
                        type="button"
                        className="btn"
                        disabled={loading}
                        onClick={() =>
                          setCurrStep(currStep !== 1 ? currStep - 1 : 1)
                        }>
                        Previous
                      </button>
                    </li>
                    <li>
                      <button
                        disabled={loading}
                        type={
                          currStep === steps.length + 1 ? 'submit' : 'button'
                        }
                        className={cx('btn', [
                          { laststep: currStep === steps.length }
                        ])}
                        data-dismiss={currStep === steps.length ? 'modal' : ''}
                        onClick={() => {
                          const fieldsToValidate = [
                            ...(currStep === 1 ? ['strategyName'] : []),
                            ...(currStep === 2 ? ['industry'] : [])
                          ];

                          const subFormErrors = {};
                          fieldsToValidate.forEach((field) => {
                            try {
                              validationSchema.validateSyncAt(field, values);
                            } catch (error) {
                              subFormErrors[field] = error.message;
                              setFieldTouched(field, true);
                            }
                          });
                          if (Object.keys(subFormErrors).length === 0) {
                            if (currStep === steps.length) {
                              setLoading(true);
                              handleSubmit();
                            } else {
                              setCurrStep((prevStep) => prevStep + 1);
                            }
                          } else {
                            // warning to the user that some fields have errors
                          }
                        }}>
                        {currStep < steps.length
                          ? 'Next'
                          : loading
                          ? 'Saving...'
                          : 'Save Template'}
                        {loading && (
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                          />
                        )}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default NewContentStrategyModal;
