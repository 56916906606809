import Swal from 'sweetalert2';

export const infoAlert = (msg) => {
  Swal.fire({
    icon: 'info',
    title: 'Info!',
    text: msg,
    allowOutsideClick: false,
    allowEscapeKey: false
  });
};
