/**
 * Unsplash API documentation: https://unsplash.com/documentation
 */
import { createApi } from 'unsplash-js';
const unsplashKey = process.env.REACT_APP_UNSPLASH_KEY;

export const getUnsplashImages = async (query, page = 1) => {
  try {
    if (query !== '') {
      const unsplash = createApi({ accessKey: unsplashKey });
      const { response } = await unsplash.search.getPhotos({
        query,
        page,
        perPage: 20,
        orderBy: 'latest'
      });
      const images = response.results;
      return images;
    }
    return [];
  } catch (err) {
    throw new Error(err);
  }
};
