import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { NotesIcon } from '../../../Icons';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { getNotesInfo } from '../services';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../store/slices/auth';

const useStyles = makeStyles(() => ({
  noFocus: {
    '&:focus': {
      outline: 'none'
    }
  }
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 800,
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9'
  }
}))(Tooltip);

const ContentGuidelineTooltip = ({ newPostProps }) => {
  const { userInfo } = useSelector(authSelector);
  const classes = useStyles();
  const [contentGuideLine, setContentGuideLine] = useState('');
  const isMounted = useRef(false);

  const getContentGuideline = useCallback(async () => {
    const { currentClientId } = newPostProps;

    if (isMounted && currentClientId) {
      const { Content_Guidelines_Notes } = await getNotesInfo(
        currentClientId,
        userInfo.accessToken,
        true
      );
      setContentGuideLine(Content_Guidelines_Notes);
    }
  }, [newPostProps, userInfo.accessToken]);

  useEffect(() => {
    getContentGuideline();
    return () => {
      isMounted.current = false;
      setContentGuideLine('');
    };
  }, [getContentGuideline]);

  return (
    <HtmlTooltip
      title={contentGuideLine || 'No content guideline available'}
      placement="right-start"
      arrow>
      <Button size="small" className={`mb-3 p-0 ${classes.noFocus}`}>
        <NotesIcon className="mr-1" width={'22'} height={'22'} />
        CONTENT GUIDELINE
      </Button>
    </HtmlTooltip>
  );
};

export default ContentGuidelineTooltip;
