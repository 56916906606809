export const columns = [
  {
    name: 'Content Creator',
    sortable: true,
    grow: 2,
    selector: (row) => row.contentCreator,
    cell: (row) => row.contentCreator
  },
  {
    name: 'Done',
    sortable: true,
    grow: 2,
    selector: (row) =>
      row.customers.filter((customers) => customers.done === true).length,
    cell: (row) =>
      row.customers.filter((customers) => customers.done === true).length
  },
  {
    name: 'Total Clients',
    sortable: true,
    grow: 2,
    selector: (row) => row.customers.length,
    cell: (row) => row.customers.length
  },
  {
    name: 'Done',
    sortable: true,
    grow: 2,
    selector: (row) =>
      row.customers.filter((customers) => customers.done === true).length,
    cell: (row) =>
      row.customers.filter((customers) => customers.done === true).length
  }
];
