import { API_GROUPS } from '../../utils/constants';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const axios = require('axios');

// Get all the groups
export const getGroups = createAsyncThunk(
  'groups/getGroups',
  async (payload, { getState, rejectWithValue }) => {
    try {
      const { loadingGroups } = getState().groups;
      const { userInfo } = getState().auth;

      if (!loadingGroups) {
        return;
      }
      let res = await axios.get(API_GROUPS, {
        headers: {
          Authorization: `bearer ${userInfo.accessToken}`
        }
      });
      return res.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const slice = createSlice({
  name: 'groups',
  initialState: {
    groupsList: [],
    currentRequestId: undefined,
    loadingGroups: false,
    errorGroups: null
  },
  reducers: {},
  extraReducers: {
    [getGroups.pending]: (state, action) => {
      if (!state.loadingGroups) {
        state.loadingGroups = true;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [getGroups.fulfilled]: (state, { payload }) => {
      if (state.loadingGroups) {
        state.groupsList = payload.groups;
        state.loadingGroups = false;
        state.errorGroups = null;
      }
    },
    [getGroups.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loadingGroups && state.currentRequestId === requestId) {
        state.groupsList = null;
        state.loadingGroups = false;
        state.errorGroups = action.error;
        state.currentRequestId = undefined;
      }
    }
  }
});

export default slice.reducer;

// const { setCurrentClient } = slice.actions;

// Clients selector
export const groupsSelector = (state) => state.groups;

// export const setSelectedClient = (payload) => (dispatch) => {
//   dispatch(setCurrentClient(payload));
// };
