import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { ListCustomers } from './ListCustomers';

export const Customers = ({ data }) => {
  const { customers } = data;

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-around"
        style={{ paddingTop: '15px' }}>
        <Box>
          <h5>To-Do</h5>
        </Box>
        <Box>
          <h5>Done</h5>
        </Box>
      </Box>
      <Grid container justifyContent="center">
        <Grid item xs style={{ paddingLeft: '50px' }}>
          <ListCustomers
            customers={customers.filter(
              (customers) => customers.done === false
            )}
          />
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs style={{ paddingLeft: '50px' }}>
          <ListCustomers
            customers={customers.filter((customers) => customers.done === true)}
          />
        </Grid>
      </Grid>
    </>
  );
};
