import React from 'react';
import moment from 'moment-timezone';

const SinglePost = (props) => {
  const { post } = props;

  return (
    <div className="media">
      <div
        className="content-image-posts wd-80 ht-80 bg-ui-04 rounded d-flex align-items-center justify-content-center"
        style={{
          backgroundImage: `url(${post?.images ? post?.images[0]?.url : ''})`
        }}>
        <i data-feather="book-open" className="tx-white-7 wd-40 ht-40" />
      </div>
      <div className="media-body pd-l-25">
        <div className={'ds-row-sb'}>
          <span className={'tx-color-03 tx-13 mg-b-5'}>
            {moment.unix(post['schedule']).format('MMMM D YYYY, h:mm a')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SinglePost;
