import React from 'react';

const NoteField = ({ title, children: text }) => {
  return (
    <p className="ml-2 mt-4">
      <strong>{title}</strong>
      <span className="pl-2">{text}</span>
    </p>
  );
};

export default NoteField;
