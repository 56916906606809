import {
  REPORTS_DAILY,
  REPORTS_MONTHLY,
  REPORTS_START_YEAR
} from '../../../../utils/constants';

/**
 * CONSTANTS
 */
const CURRENT_MONTH = new Date().getMonth() + 1,
  CURRENT_YEAR = new Date().getFullYear(),
  CURRENT_DATE = new Date().getDate();
const START_YEAR = Number(REPORTS_START_YEAR),
  END = new Date().getFullYear();
const JANUARY = 1,
  DECEMBER = 12;

/**
 *
 * @param {*} month
 * @param {*} year
 * @returns The month selected with ranges from  the start year
 * and the current month
 */
export const assignMonth = (month, year) => {
  if (isNaN(month) && isNaN(year)) {
    return CURRENT_MONTH;
  }

  if (Number(year) > CURRENT_YEAR && Number(month) > CURRENT_MONTH) {
    return CURRENT_MONTH;
  }

  if (Number(year) < START_YEAR) {
    return CURRENT_MONTH;
  }

  if (Number(month) >= JANUARY && Number(month) <= DECEMBER) {
    return Number(month);
  }
};

/**
 *
 * @param {*} year
 * @returns The year selected with ranges from the start year
 * and the current year
 */
export const assignYear = (year) => {
  if (!isNaN(year) && Number(year) >= START_YEAR && Number(year) <= END) {
    return Number(year);
  }

  return CURRENT_YEAR;
};

/**
 *
 * @param {*} year
 * @param {*} month
 * @param {*} day
 * @returns A boolean that detect a weekend day
 */
export const validateIfIsWeekend = (year, month, day) => {
  const dayOfWeek = new Date(year, month, day).getDay();
  if (dayOfWeek === 0 || dayOfWeek === 6) {
    return true;
  }
  return false;
};

/**
 *
 * @param {*} contentCreator
 * @returns The total quantity of post that a content creator did
 * in a month
 */
export const calculateTotalByContentCreator = (contentCreator) => {
  let total = 0;
  for (const day of contentCreator.done_assignments) {
    total += Number(day.total);
  }
  return total;
};

/**
 *
 * @param {*} contentCreator
 * @param {*} date
 * @returnsReturn the list of content creators report daily
 */
export const getContentCreatorsDaily = async (
  contentCreator,
  date = new Date(CURRENT_YEAR, CURRENT_MONTH, CURRENT_DATE)
) => {
  let response;
  if (contentCreator === undefined) {
    response = await fetch(
      REPORTS_DAILY +
        new URLSearchParams({
          date
        })
    );
  } else {
    response = await fetch(
      REPORTS_DAILY +
        new URLSearchParams({
          contentCreator,
          date
        })
    );
  }
  const contentCreatorsDaily = await response.json();
  return contentCreatorsDaily;
};

/**
 *
 * @param {*} month
 * @param {*} year
 * @returns Return the list of content creators report monthly
 */
export const getContentCreatorsMonthly = async (month, year) => {
  const response = await fetch(
    REPORTS_MONTHLY +
      new URLSearchParams({
        month,
        year
      })
  );
  const contentCreatorsMonthly = await response.json();
  return contentCreatorsMonthly;
};
