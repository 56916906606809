import React from 'react';
import { useHistory } from 'react-router-dom';

function NotFound() {
  const history = useHistory();
  return (
    <div className="content content-fixed content-auth-alt">
      <div className="container ht-100p tx-center">
        <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
          <div className="wd-70p wd-sm-250 wd-lg-300 mg-b-15 mt-5 pt-3">
            <img src={`${process.env.PUBLIC_URL}/assets/img/logo.svg`} className="img-fluid" alt="logo" />
          </div>
          <h1 className="tx-color-01 tx-24 tx-sm-32 tx-lg-36 mg-xl-b-5">
            404 Page Not Found
          </h1>
          <h5 className="tx-16 tx-sm-18 tx-lg-20 tx-normal mg-b-20">
            Oopps. The page you were looking for doesn't exist.
          </h5>
          <p className="tx-color-03 mg-b-30">
            You may have mistyped the address or the page may have moved. Try
            searching below.
          </p>
          <div className="d-flex mg-b-40">
            <button
              className="btn btn-primary bd-0 mg-l-5 pd-sm-x-25"
              onClick={() => history.push('/')}>
              Take me home
            </button>
          </div>
          <span className="tx-12 tx-color-03">
            &copy; {new Date().getFullYear()} <a href="http://adlersocial.com">Adler Social</a>, LLC.
            All Rights Reserved.
          </span>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
