/**
 * Pixabay API documentation: https://pixabay.com/api/docs
 * Dependencie Pixabay API documentation: https://www.npmjs.com/package/pixabay-api
 */
import { authenticate } from 'pixabay-api';
const pixabayKey = process.env.REACT_APP_PIXABAY_KEY;
const { searchImages } = authenticate(pixabayKey);

export const getPixabayImages = async (query = '', page = 1) => {
  try {
    if (query !== '') {
      const { hits: images } = await searchImages(query, {
        per_page: 20,
        page
      });
      return images;
    }
    return [];
  } catch (err) {
    throw new Error(err);
  }
};
