import { API_POSTS_MONTH } from '../../utils/constants';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const axios = require('axios');

// Get all the posts
export const getPosts = createAsyncThunk(
  'posts/getPosts',
  async (payload, { getState, requestId, rejectWithValue }) => {
    try {
      const { loadingPosts } = getState().posts;
      if (!loadingPosts) {
        throw new Error('Not loading posts');
      }
      let res = await axios.post(API_POSTS_MONTH, payload);
      return res.data.posts;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const slice = createSlice({
  name: 'posts',
  initialState: {
    postsLists: [],
    currentRequestId: undefined,
    loadingPosts: false,
    errorPosts: null,
    selectedPost: null
  },
  reducers: {
    setCurrentPost: (state, { payload }) => {
      state.selectedPost = payload;
    },
    resetPosts: (state, action) => {
      state.postsLists = [];
    }
  },
  extraReducers: {
    [getPosts.pending]: (state, action) => {
      if (!state.loadingPosts) {
        state.loadingPosts = true;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [getPosts.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loadingPosts && state.currentRequestId === requestId) {
        state.postsLists = action.payload;
        state.loadingPosts = false;
        state.errorPosts = null;
      }
    },
    [getPosts.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loadingPosts && state.currentRequestId === requestId) {
        state.postsLists = [];
        state.loadingPosts = false;
        state.errorPosts = action.error.message;
        state.currentRequestId = undefined;
      }
    }
  }
});

export default slice.reducer;

const { setCurrentPost, resetPosts } = slice.actions;

// [Posts] selector
export const postsSelector = (state) => state.posts;

export const setSelectedPost = (payload) => (dispatch) => {
  dispatch(setCurrentPost(payload));
};

export const clearPosts = () => (dispatch) => dispatch(resetPosts());
