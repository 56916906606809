import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  authSelector,
  loginUser,
  validateUserAuth
} from '../../store/slices/auth';
import { clientsSelector, getClients } from '../../store/slices/clients';
import Loading from '../../components/Loading';
import { firebaseAuth } from '../../firebase/firebase';
import './Login.scss';

function Login() {
  const dispatch = useDispatch();
  const { isAuthenticated, loading, errorLogin } = useSelector(authSelector);
  const { customerList } = useSelector(clientsSelector);

  useEffect(() => {
    const unsusbscribe = firebaseAuth.onIdTokenChanged(async (user) => {
      if (user) {
        const idToken = await user.getIdToken(true);
        dispatch(validateUserAuth(user, idToken));
      }

      if (isAuthenticated && customerList.length === 0) {
        dispatch(getClients());
      }
    });

    return () => {
      unsusbscribe();
    };
    // eslint-disable-next-line
  }, [isAuthenticated, customerList.length]);

  const onSubmit = (event) => {
    event.preventDefault();
    dispatch(loginUser());
    dispatch(getClients());
  };

  const checkAuth = () => {
    if (isAuthenticated && customerList.length === 0) {
      return <Loading message="Getting customers..." />;
    } else if (isAuthenticated) {
      return <Redirect to="/clients" />;
    } else {
      return (
        <div>
          <div className="container-fluid">
            <div className="row justify-content-center min-vh-100">
              <div className="col-12">
                <div className="d-flex flex-column h-100">
                  <div className="row justify-content-center bg-white">
                    <div className="text-white">
                      <div style={{ height: 150 }}>
                        <div
                          style={{
                            height: 150,
                            textAlign: 'center',
                            paddingTop: 20,
                            backgroundColor: 'white'
                          }}>
                          <img
                            src="../assets/img/adler_logo_large.png"
                            className="img-fluid"
                            width="30%"
                            alt="Adler Suite"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center login-wrapper flex-grow-1">
                    <div
                      className="text-white"
                      style={{ paddingTop: 50, textAlign: 'center' }}>
                      <p>Please login with your GSuite Account</p>
                      {loading ? (
                        <div className="spinner-grow" role="status" />
                      ) : (
                        <form onSubmit={onSubmit}>
                          <button type="submit" className="login-btn">
                            <img
                              src="./assets/img/google-login/btn_google_signin_dark_normal_web.png"
                              alt="Google Login"
                            />
                          </button>
                        </form>
                      )}
                      {errorLogin && (
                        <p className="pt-3">
                          <strong>- {errorLogin} -</strong>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return checkAuth();
}

export default Login;
