import React from 'react';

const DescriptionSection = ({ title, detail }) => {
  return (
    <section className="card card-app-description">
      <h6 className="card-subtitle mt-2 ml-2 text-muted">{title}</h6>
      <article
        style={{
          margin: 5,
          marginLeft: 10
        }}
        className=" text-wrapper">
        <div>
          {!detail ? (
            <span style={{ color: 'red' }}>No description</span>
          ) : (
            <>
              <p>
                {detail.description ? (
                  <>{detail.description}</>
                ) : (
                  <span style={{ color: 'red' }}>No description</span>
                )}
              </p>
              <small style={{ fontSize: '0.9rem' }}>
                Upload by:{' '}
                <u>
                  <a href={detail.authorPage} target="__blank">
                    {detail.name}
                  </a>
                </u>
              </small>
            </>
          )}
        </div>
      </article>
    </section>
  );
};

export default DescriptionSection;
