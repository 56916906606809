import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import DataTables from '../../components/DataTables';
import NewGroupModal from '../../components/NewGroupModal';
import { clientsSelector } from '../../store/slices/clients';
import { getClients } from '../../store/slices/clients';
import {
  API_GROUPS_DELETE,
  API_GROUPS,
  API_CLIENTS,
  API_KEY
} from '../../utils';
import BulkModal from '../../components/BulkModal';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import Loading from '../../components/Loading';
import { setSelectedClient } from '../../store/slices/selectedClient';
import { authSelector } from '../../store/slices/auth';
import BulkToAll from '../../components/BulkToAll';
import GroupNameColumn from './GroupNameColumn/GroupNameColumn';
const axios = require('axios');

const Groups = () => {
  const [loaded, setLoaded] = useState(false);
  const { customerList: activeClients } = useSelector(clientsSelector);
  const [customerList, setCustomerList] = useState([]);
  const [currentClients, setCurrentClients] = useState([]);
  const [currentGroupId, setCurrentGroupId] = useState(null);
  const { userInfo } = useSelector(authSelector);
  const [change, setChange] = useState(true);
  const table = useRef(null);
  const dispatch = useDispatch();

  const groupActions = (cell, row) => {
    if (row.id) {
      const userRole = userInfo.role;
      return (
        <>
          <nav className="nav nav-icon-only" style={{ float: 'right' }}>
            <a
              onClick={() => dataGroupHandler(row.id, row.clientSocialNetworks)}
              href={'#BulkModal'}
              className="btn-icon"
              data-toggle="modal"
              data-animation="effect-scale"
              data-backdrop="static"
              data-keyboard="false"
              title="Bulk clients">
              <i className="fa fa-2x fa-fw fa-file-excel" />
            </a>

            {userRole === 'admin' || userRole === 'superAdmin' ? (
              <button
                onClick={() => {
                  dataGroupHandler(row.id, row.clientSocialNetworks);
                  Swal.fire({
                    title: 'Delete Group',
                    text: 'Are you sure you want delete?',
                    showCloseButton: true,
                    showCancelButton: true
                  }).then((res) => {
                    if (res.value) {
                      axios
                        .get(API_GROUPS_DELETE + row.id, {
                          headers: {
                            Authorization: `bearer ${userInfo.accessToken}`
                          }
                        })
                        .then((response) => {
                          Swal.fire({
                            title: 'Success!',
                            text: 'Group Deleted.',
                            showCloseButton: true
                          }).then((res) => {
                            if (res.value) {
                              setChange(!change);
                            }
                          });
                        })
                        .catch((error) => {
                          Swal.fire({
                            title: 'Error!',
                            icon: 'error',
                            text: 'Have problems deleting.'
                          });
                          console.error('error ' + error);
                        });
                    }
                  });
                }}
                className="btn-icon"
                data-toggle="tooltip"
                title="Delete group">
                <i className="fa fa-2x fa-fw fa-trash-alt" />
              </button>
            ) : (
              []
            )}
          </nav>
        </>
      );
    }
  };

  const groupName = (cell, row) => {
    let x = row.clientsText;
    if (row.id) {
      return (
        <GroupNameColumn
          id={row.id}
          groupName={row['groupName']}
          length={x.length}
        />
      );
    }
  };

  const undercoverGetClients = () => {
    try {
      dispatch(getClients());
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (userInfo.role === 'superAdmin' && activeClients.length === 0) {
      undercoverGetClients();
    }
    // eslint-disable-next-line
  });

  useEffect(() => {
    setCustomerList([]);
    setLoaded(false);

    axios
      .all([
        axios.get(API_GROUPS, {
          headers: {
            Authorization: `bearer ${userInfo.accessToken}`
          }
        }),
        axios.get(API_CLIENTS, { headers: { 'X-API-KEY': API_KEY } })
      ])
      .then(
        axios.spread((...response) => {
          const groups = response[0].data.groups;
          const clients = response[1].data.clients;

          let groupsByClient = clients.map((client) => {
            let idGroups = client.clientGroups.map((cligroup) => cligroup.id);
            let clientGroups = client.clientGroups
              .map((cligroup) => cligroup.groupName)
              .join(', ');
            return {
              id: client.id,
              clientName: client.clientName,
              idGroups: idGroups,
              clientSocialNetworks: client.socialnetworks,
              timeZone: client.timeZone,
              bestTimes: client.bestTimes,
              mergeFields: client.mergeFields,
              clientGroups: clientGroups.slice(0, clientGroups.length)
            };
          });

          let listGroups = groups.map((group) => {
            let listClients = groupsByClient.filter((grcli) =>
              grcli.idGroups.includes(group.id)
            );

            let clientsTextArray = [];
            const len = listClients.length;
            let finalListClients = listClients.map((cli, count) => {
              clientsTextArray.push(cli.clientName);
              let newUrl = cli.clientName
                .replace(/\s+/g, '%20')
                .replace(/\//g, '%2F')
                .replace(/'/g, '%1D')
                .replace(/’/g, '%1E');
              if (len === count + 1) {
                return (
                  <span key={cli.id}>
                    <Link
                      onClick={() => dispatch(setSelectedClient(cli))}
                      onMouseDown={() => dispatch(setSelectedClient(cli))}
                      to={{
                        pathname: `/calendar/${newUrl}`,
                        clientId: cli.id,
                        clientGroups: cli.clientGroups,
                        socialNetworks: cli['clientSocialNetworks']
                      }}>
                      {cli.clientName}
                    </Link>
                  </span>
                );
              } else {
                return (
                  <span key={cli.id}>
                    <Link
                      onClick={() => dispatch(setSelectedClient(cli))}
                      onMouseDown={() => dispatch(setSelectedClient(cli))}
                      to={{
                        pathname: `/calendar/${newUrl}`,
                        clientId: cli.id,
                        clientGroups: cli.clientGroups,
                        socialNetworks: cli['clientSocialNetworks']
                      }}>
                      {cli.clientName}
                    </Link>
                    <span>, </span>
                  </span>
                );
              }
            });

            let clientsText =
              clientsTextArray !== undefined ? clientsTextArray : '';

            return {
              id: group.id,
              groupName: group.groupName,
              clients: finalListClients,
              clientsText: clientsText
            };
          });
          setCustomerList(listGroups);
          setLoaded(true);
        })
      )
      .catch((error) => {
        console.error(error);
      });
  }, [change, dispatch, userInfo.accessToken]);

  const clientColumns = [
    {
      dataField: 'id',
      text: 'Id',
      hidden: true
    },
    {
      dataField: 'groupName',
      text: 'Group Name',
      headerStyle: () => {
        return {
          width: '266px',
          textAlign: 'left'
        };
      },
      style: () => {
        return {
          paddingTop: '12px',
          textAlign: 'left'
        };
      },
      formatter: groupName
    },
    {
      dataField: 'clients',
      text: 'Clients',
      headerStyle: () => {
        return {};
      },
      style: () => {
        return {
          paddingTop: '12px'
        };
      }
    },
    {
      dataField: 'dummy-actions',
      text: 'Actions',
      formatter: groupActions,
      headerStyle: () => {
        return {
          width: '200px',
          textAlign: 'left'
        };
      },
      style: () => {
        return {
          border: 'none',
          display: 'flex'
        };
      }
    }
  ];

  const dataGroupHandler = (groupId) => {
    getClientsOfGroup(groupId);
    setCurrentGroupId(groupId);
  };

  const myCallback = () => {
    setChange(!change);
  };

  const getClientsOfGroup = (id) => {
    var clients = customerList.filter((cl) => cl.id === id)[0];
    clients = clients.clientsText;
    setCurrentClients(clients);
  };

  const userRole = userInfo.role;

  if (!loaded) {
    return <Loading />;
  }
  return (
    <div className={'hg-100'}>
      <Header />
      <div
        className="content content-fixed"
        style={{
          height: 'calc(100vh - 60px)',
          overflow: 'auto',
          padding: '0 1px 0 1px'
        }}>
        <div className="row row-xs">
          <DataTables
            userRole={userRole}
            view={1}
            data={customerList}
            columns={clientColumns}
            exportBtnText={'Export to CSV'}
            noDataIndication={'No Groups'}
            ref={table}
            alternTitle={'groups'}
          />
        </div>
      </div>

      <NewGroupModal callbackFromParent={myCallback} />
      <BulkModal
        title={'clients'}
        currentClients={currentClients}
        currentGroupId={currentGroupId}
        currentCustomerName={userInfo.displayName}
      />
      {userInfo.role === 'superAdmin' ? (
        <BulkToAll
          title={'clients'}
          currentClients={activeClients}
          updateState={setChange}
          state={change}
          currentCustomerName={userInfo.displayName}
        />
      ) : null}

      <Footer />
    </div>
  );
};

export default Groups;
