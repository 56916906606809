import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import ButtonsGroup from '../ButtonsGroup';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import filterFactory from 'react-bootstrap-table2-filter';
import './DataTables.css';

// Err Forward ref: Probably src/pages/Groups ref
// The ref could it be a only ref in the top level not props
const DataTables = ({
  data,
  columns,
  view,
  viewState,
  buttonsCallback = () => {},
  userRole,
  noDataIndication,
  expandRow,
  rowEvents,
  alternTitle,
  title = null,
  handleModal,
  showTotal,
  rowStyle,
  loading
}) => {
  const pagination = paginationFactory({
    sizePerPageList: [
      {
        text: '15',
        value: 15
      },
      {
        text: '30',
        value: 30
      },
      {
        text: '45',
        value: 45
      },
      {
        text: '60',
        value: 60
      }
    ]
  });

  const MySearch = (props) => {
    const handleSearch = (e) => props.onSearch(e.target.value);
    return (
      <div className="search-label">
        <input
          autoComplete="off"
          className="form-control"
          placeholder={alternTitle ? `Search ${alternTitle}` : 'Search clients'}
          onChange={(e) => handleSearch(e)}
          type="text"
        />
      </div>
    );
  };

  return (
    <>
      <ToolkitProvider
        keyField="id"
        data={data}
        columns={columns}
        rowStyle={rowStyle}
        bootstrap4
        search>
        {(props) => (
          <>
            <div
              className="df-table shadow-sm"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row'
              }}>
              <div className="search-form">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-search">
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
                <MySearch {...props.searchProps} />
              </div>
              {data?.length > 0 && showTotal ? (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end',
                    marginRight: '20px'
                  }}>{`Total Clients: ${data?.length}`}</div>
              ) : null}

              <span style={{ fontSize: '16px', fontWeight: '500' }}>
                {title}
              </span>
              <ButtonsGroup
                view={view}
                viewState={viewState}
                connectCallback={buttonsCallback}
                userRole={userRole}
                handleModal={handleModal}
                loading={loading}
                showTotal={showTotal}
              />
            </div>
            <BootstrapTable
              {...props.baseProps}
              pagination={pagination}
              rowEvents={rowEvents}
              striped
              hover
              rowStyle={rowStyle}
              condensed
              noDataIndication={noDataIndication}
              wrapperClasses="table table-dashboard mg-b-0"
              expandRow={expandRow}
              filter={filterFactory()}
            />
          </>
        )}
      </ToolkitProvider>
    </>
  );
};

export default DataTables;
