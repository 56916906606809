import { combineReducers } from '@reduxjs/toolkit';
import clientsReducer from './clients.js';
import postsReducer from './posts.js';
import groupsReducer from './groups.js';
import selectedClientReducer from './selectedClient.js';
import assignmentReducer from './assignments.js';
import { authReducer } from '../../reducers/authReducer.js';
import { generalStateReducer } from '../../reducers/generalStateReducer.js';
import { reviewsReducer } from '../../reducers/reviewsReducer.js';

const combinedReducer = combineReducers({
  auth: authReducer,
  reviews: reviewsReducer,
  clients: clientsReducer,
  posts: postsReducer,
  groups: groupsReducer,
  selectedClient: selectedClientReducer,
  assignments: assignmentReducer,
  generalState: generalStateReducer
});

export default combinedReducer;
