import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { authSelector } from '../../store/slices/auth';

import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import EditIcon from '@material-ui/icons/Edit';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import DataTables from '../../components/DataTables';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import NewContentStrategyModal from '../../components/NewContentStrategyModal/NewContentStrategyModal';
import StrategyImages from '../../components/StrategyImages/StrategyImages';
import axios from 'axios';
import { API_GET_CONTENT_STRATEGIES, API_KEY } from '../../utils/constants';

const ContentStrategies = () => {
  const { userInfo } = useSelector(authSelector);

  const [showModal, setShowModal] = useState(false);
  const [showImgModal, setShowImgModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    setShowImgModal(false);
    setDefaultData({});
  };
  const handleShow = () => setShowModal(true);
  const handleShowImgModal = () => setShowImgModal(true);

  const [defaultData, setDefaultData] = useState({});

  const CSName = (cell, row) => {
    if (row.id) {
      let newUrl = row?.strategyName;
      return (
        <div>
          <Link
            to={{
              pathname: `/content-strategies/${newUrl}`
            }}>
            <span>{row['strategyName']}</span>
          </Link>
          <span>{` (${row?.linkedClients.length})`}</span>
        </div>
      );
    }
  };
  const [cStrategies, setCStrategies] = useState([]);
  const [loading, setLoading] = useState(false);
  const clientColumns = [
    {
      dataField: 'id',
      text: 'id',
      hidden: true
    },
    {
      dataField: 'strategyName',
      text: 'name',
      headerStyle: () => {
        return {
          width: '190px',
          textAlign: 'left'
        };
      },
      style: () => {
        return {
          paddingTop: '12px',
          textAlign: 'left'
        };
      },
      formatter: CSName
    },
    {
      dataField: 'creationDate',
      text: 'date created',
      headerStyle: () => {
        return {
          width: '120px',
          textAlign: 'left'
        };
      },
      style: () => {
        return {
          paddingTop: '12px',
          textAlign: 'center'
        };
      },
      // row value
      formatter: (cell, row) => {
        return (
          <div>
            <span>{new Date(row['createdAt']).toLocaleDateString()}</span>
          </div>
        );
      }
    },
    {
      dataField: 'linkedGroups',
      text: 'linked groups',
      headerStyle: () => {
        return {};
      },
      style: () => {
        return {
          paddingTop: '12px'
        };
      },
      formatter: (cell, row) => {
        return (
          <div>
            <span>
              {row['linkedIndustries']?.map(
                (industry, index) =>
                  `${industry}${
                    row['linkedIndustries'].length > 1 &&
                    index < row['linkedIndustries'].length - 1
                      ? ','
                      : ''
                  }`
              )}
            </span>
          </div>
        );
      }
    },
    {
      dataField: 'linkedClients',
      text: 'linked clients',
      headerStyle: () => {
        return {};
      },
      style: () => {
        return {
          paddingTop: '12px'
        };
      },
      formatter: (cell, row) => {
        return (
          <div>
            <span>
              {row['linkedClients']?.map((clientName, index) => {
                let newUrl = clientName
                  .replace(/\s+/g, '%20')
                  .replace(/\//g, '%2F')
                  .replace(/'/g, '%1D')
                  .replace(/’/g, '%1E');
                return (
                  <>
                    <Link
                      to={{
                        pathname: `/client/${newUrl}`
                      }}>
                      <span>{clientName}</span>
                    </Link>
                    {row['linkedClients'].length > 1 &&
                    index < row['linkedClients'].length - 1
                      ? ', '
                      : ''}
                  </>
                );
              })}
            </span>
          </div>
        );
      }
    },
    {
      dataField: 'actions',
      text: 'actions',
      headerStyle: () => {
        return {
          width: '320px',
          textAlign: 'left'
        };
      },
      style: () => {
        return {
          paddingTop: '12px'
        };
      },
      formatter: (cell, row) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div
              onClick={() => {
                handleShowImgModal(true);
                setDefaultData(row);
              }}
              style={{
                color: '#8392A5',
                display: 'flex',
                flexDirection: 'row',
                width: '80px',
                justifyContent: 'space-around',
                alignContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
              }}>
              <ImageSearchIcon style={{ color: '#8392A5' }} />
              <span
                style={{
                  textAlign: 'center'
                }}>
                Select Images
              </span>
            </div>
            <div
              onClick={() => {
                handleShow(true);
                setDefaultData(row);
              }}
              style={{
                color: '#8392A5',
                display: 'flex',
                width: '70px',
                justifyContent: 'space-around',
                alignContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
              }}>
              <EditIcon style={{ color: '#8392A5' }} />
              <span>Edit</span>
            </div>
            <div>
              <a
                href="#clone"
                style={{
                  color: '#8392A5',
                  display: 'flex',
                  width: '70px',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  alignContent: 'center',
                  height: '100%'
                }}>
                <FileCopyOutlinedIcon style={{ color: '#8392A5' }} />
                <span>Clone</span>
              </a>
            </div>
          </div>
        );
      }
    }
  ];

  const getData = async () => {
    setLoading(true);
    try {
      const { data: response } = await axios.get(API_GET_CONTENT_STRATEGIES, {
        headers: { 'X-API-KEY': API_KEY }
      });
      const { contentStrategies } = response;

      setCStrategies(contentStrategies);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
      return [];
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Header loading={loading} />
      <div
        className="content content-fixed"
        style={{
          height: 'calc(100vh - 60px)',
          overflow: 'auto',
          padding: '0 1px 0 1px'
        }}>
        <DataTables
          alternTitle={'Strategy'}
          view={'contentStrategies'}
          userRole={userInfo.role}
          title={'Content Strategies'}
          data={cStrategies || []}
          columns={clientColumns}
          handleModal={handleShow}
          noDataIndication={'No Content Strategies'}
        />
      </div>
      <NewContentStrategyModal
        showModal={showModal}
        defaultData={{
          ...defaultData,
          industry: defaultData?.linkedIndustries
        }}
        handleClose={handleClose}
        callbackFromParent={() => {
          getData();
        }}
      />
      <StrategyImages
        isModal
        showModal={showImgModal}
        defaultData={{
          ...defaultData,
          industry: defaultData?.linkedIndustries
        }}
        handleClose={handleClose}
      />
      <Footer />
    </>
  );
};

export default ContentStrategies;
