import React from 'react';
import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import {
  TwitterIcon,
  FacebookIcon,
  GoogleIcon,
  InstagramIcon
} from '../../../Icons';
import { API_SN_DELETE, API_GET_LINKS } from '../../../utils';

const Links = ({ selectedClient, userInfo }) => {
  const history = useHistory();
  const [links, setLinks] = useState(null);

  // Function to disconnect social networks
  const disconnectSocialNetwork = async (network, clientId) => {
    Swal.fire({
      title: `Disconnect ${network}`,
      text: `Are you sure you want disconnect ${network}?`,
      showCloseButton: true,
      showCancelButton: true
    }).then(async (res) => {
      if (res.value) {
        await axios
          .delete(API_SN_DELETE, {
            data: {
              socialNetwork: network,
              id: clientId
            }
          })
          .then((res) => {
            if (res.status === 200) {
              Swal.fire({
                title: 'Success!',
                text: 'Disconnected!',
                showCloseButton: true
              }).then((res) => {
                if (res.value) {
                  history.go(0);
                }
              });
            }
          });
      }
    });
  };

  useEffect(() => {
    if (!links) {
      const clientSocialNetworksLinks = async (clientId) => {
        try {
          const { Links } = await (
            await fetch(API_GET_LINKS + new URLSearchParams({ clientId }))
          ).json();
          if (links === null) {
            setLinks(Links);
          }
        } catch (error) {
          console.error('The link have an error', error);
        }
      };
      if (selectedClient.id && links === null) {
        clientSocialNetworksLinks(selectedClient.id);
      }
    }
  }, [selectedClient.id, links]);

  return (
    <div className="col-sm-6 col-md-5 col-lg mt-4 social-networks-section">
      <label className="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1 mg-b-15">
        Links &amp; Social Networks
      </label>
      <ul className="list-unstyled profile-info-list">
        <li>
          <TwitterIcon />
          {links?.Twitter_URL &&
          selectedClient.clientSocialNetworks.twitterAccount ? (
            <a target="n_blank" href={links?.Twitter_URL}>
              {selectedClient.clientSocialNetworks.twitterAccount}
            </a>
          ) : (
            <span>
              {selectedClient.clientSocialNetworks.twitterAccount
                ? selectedClient.clientSocialNetworks.twitterAccount
                : 'None'}
            </span>
          )}
          {selectedClient.clientSocialNetworks.twitterAccount &&
          userInfo.role === 'superAdmin' ? (
            <button
              style={{
                borderWidth: '0',
                background: 'none',
                cursor: 'pointer',
                outline: 'inherit'
              }}
              onClick={() => {
                disconnectSocialNetwork('twitter', selectedClient.id);
              }}>
              <span className="badge badge-danger">disconnect</span>
            </button>
          ) : null}
        </li>
        <li>
          <FacebookIcon />
          {links?.Facebook_URL &&
          selectedClient.clientSocialNetworks.facebookPage ? (
            <a target="n_blank" href={links?.Facebook_URL}>
              {selectedClient.clientSocialNetworks.facebookPage}
            </a>
          ) : (
            <span>
              {selectedClient.clientSocialNetworks.facebookPage
                ? selectedClient.clientSocialNetworks.facebookPage
                : 'None'}
            </span>
          )}
          {selectedClient.clientSocialNetworks.facebookPage &&
          userInfo.role === 'superAdmin' ? (
            <button
              style={{
                borderWidth: '0',
                background: 'none',
                cursor: 'pointer',
                outline: 'inherit'
              }}
              onClick={() => {
                disconnectSocialNetwork('facebook', selectedClient.id);
              }}>
              <span className="badge badge-danger">disconnect</span>
            </button>
          ) : null}
        </li>
        <li>
          <GoogleIcon />
          {selectedClient.clientSocialNetworks?.googleMyBusinessLocationName &&
          (links?.GMB_Google_2_URL || links?.Google_Business_Listing_URL) ? (
            <a
              target="n_blank"
              href={
                links?.GMB_Google_2_URL || links?.Google_Business_Listing_URL
              }>
              {selectedClient.clientSocialNetworks.googleMyBusinessLocationName}
            </a>
          ) : (
            <span>
              {selectedClient.clientSocialNetworks.googleMyBusinessLocationName
                ? selectedClient.clientSocialNetworks
                    .googleMyBusinessLocationName
                : 'None'}
            </span>
          )}
          {selectedClient.clientSocialNetworks.googleMyBusinessLocationName &&
          userInfo.role === 'superAdmin' ? (
            <button
              style={{
                borderWidth: '0',
                background: 'none',
                cursor: 'pointer',
                outline: 'inherit'
              }}
              onClick={() => {
                disconnectSocialNetwork('googleMyBusiness', selectedClient.id);
              }}>
              <span className="badge badge-danger">disconnect</span>
            </button>
          ) : null}
        </li>
        <li>
          <InstagramIcon />
          {links?.Instagram_URL &&
          selectedClient.clientSocialNetworks.instagramPage ? (
            <a target="n_blank" href={links?.Instagram_URL}>
              {selectedClient.clientSocialNetworks.instagramPage}
            </a>
          ) : (
            <span>
              {selectedClient.clientSocialNetworks.instagramPage
                ? selectedClient.clientSocialNetworks.instagramPage
                : 'None'}
            </span>
          )}
          {selectedClient.clientSocialNetworks.instagramPage &&
          userInfo.role === 'superAdmin' ? (
            <button
              style={{
                borderWidth: '0',
                background: 'none',
                cursor: 'pointer',
                outline: 'inherit'
              }}
              onClick={() => {
                disconnectSocialNetwork('instagram', selectedClient.id);
              }}>
              <span className="badge badge-danger">disconnect</span>
            </button>
          ) : null}
        </li>
      </ul>
    </div>
  );
};

export default Links;
