import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { SearchIcon } from '../../Icons';
import { API_GET_CS_LIST } from '../../utils/constants';
function SearchBar({ onSelectClient }) {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(API_GET_CS_LIST);
        setClients(response.data);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    fetchClients();
  }, []);

  const getContentStrategies = () => {
    return clients.map((strategy) => ({
      label: strategy.strategyName,
      value: strategy.id
    }));
  };
  const handleChangeClient = (x) => {
    console.log(x);
    onSelectClient(x);
  };
  const searchStyle = {
    control: (_, { selectProps: { width } }) => ({
      width: '240px',
      border: 'none'
    }),
    indicatorsContainer: () => ({
      display: 'none'
    }),
    input: (provided) => ({
      ...provided,
      marginTop: '0.5em'
    })
  };
  return (
    <>
      <div
        className="search-form"
        style={{
          width: '280px',
          height: '45px',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '10px'
        }}>
        <SearchIcon />
        <Select
          styles={searchStyle}
          className="client-search"
          type="search"
          placeholder="Search Images by Strategy"
          onChange={(client) => handleChangeClient(client)}
          options={getContentStrategies()}
          hideSelectedOptions={true}
          controlShouldRenderValue={false}
        />
      </div>
    </>
  );
}

export default SearchBar;
