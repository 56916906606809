const initialState = {
  loading: false,
  editable: {
    id: '',
    editable: '',
    message: ''
  },
  reviews: []
};

export const reviewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'reviews/setEditable':
      return { ...state, editable: action.payload };
    case 'reviews/editMessage':
      return {
        ...state,
        editable: { ...state.editable, message: action.payload }
      };
    case 'reviews/approve':
      return { ...state, reviews: action.payload };
    case 'reviews/setReviews':
      return { ...state, reviews: action.payload };
    case 'reviews/save':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
