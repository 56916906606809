import { API_ASSIGN_CREATOR } from '../../../../../utils/constants';

export const setDoneTask = async (assignment, clientId, checked) => {
  try {
    const options = {
      method: 'PUT',
      body: JSON.stringify({
        ...assignment,
        id: clientId,
        done: !checked
      })
    };
    const response = await fetch(API_ASSIGN_CREATOR, options);
    return response;
  } catch (error) {
    throw new Error('An error has ocurred when trying to fetch: ', error);
  }
};
