/**
 * Pexels API documentation: https://www.pexels.com/api/documentation
 */
import { createClient } from 'pexels';
const pexelsKey = process.env.REACT_APP_PEXELS_KEY;

export const getPexelsImages = async (query = '', page = 1) => {
  try {
    if (query !== '') {
      const client = createClient(pexelsKey);
      const { photos: images } = await client.photos.search({
        query,
        page,
        per_page: 20
      });
      return images;
    }
    return [];
  } catch (err) {
    throw new Error(err);
  }
};
