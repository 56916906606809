import React, { useState, useEffect, useRef } from 'react';
import { APP_S3, MANIPULATE_DESCRIPTION } from '../../../utils';
import './UserGallery.css';
import axios from 'axios';
import { NextIcon, PrevIcon } from '../../../Icons';
import '../Modal-calendar.css';

import JsZip from 'jszip';
import FileSaver from 'file-saver';
import { useSelector } from 'react-redux';
import { selectedClientSelector } from '../../../store/slices/selectedClient';

const UserGallery = (props) => {
  let urlInput = useRef();
  const [showImgModal, setShowImgModal] = useState([null, null]);
  const [isCopied, setIsCopied] = useState(false);
  const [totalProgress, setTotalProgress] = useState(0);
  const [images, setImages] = useState(null);
  const [allDescriptions, setAllDescriptions] = useState(null);
  const [allUrls, setAllUrls] = useState(null);
  const [current, setCurrent] = useState(null);
  const [isDownloading, setIsDownLoading] = useState(false);
  const [createdTime, setCreatedTime] = useState(null);
  const { selectedClient } = useSelector(selectedClientSelector);
  useEffect(() => {
    setImages(null);
    setAllDescriptions(null);
    setCreatedTime(null);
  }, [props.userId]);

  const download = async (url) => {
    try {
      const resp = await fetch(url);

      return await resp.blob();
    } catch (err) {
      console.log(err);
    }
  };

  const downloadByGroup = async (urls) => {
    let counter = 0;
    try {
      const downloadPromises = urls.map(async (url) => {
        try {
          return download(url);
        } catch (error) {
          console.error(`Error downloading ${url}:`, error);
        }
      });
      downloadPromises.map((promise) => {
        return promise.then(() => {
          counter++;
          setTotalProgress(
            Math.floor((counter * 100) / downloadPromises.length)
          );
        });
      });
      return Promise.all(downloadPromises);
    } catch (error) {
      console.error('Error during download:', error);
      throw error;
    }
  };

  const exportZip = (blobs) => {
    const zip = JsZip();
    blobs.forEach(async (blob, i) => {
      let mime = blob.type;
      if (mime === 'image/jpeg') {
        mime = 'jpg';
      } else if (mime === 'image/png') {
        mime = 'png';
      } else {
        mime = 'jpg';
      }
      zip.file(`file-${i}.${mime}`, blob);
    });

    zip.generateAsync({ type: 'blob' }).then((zipFile) => {
      setTimeout(() => {
        const fileName = `${selectedClient.clientName}-images.zip`;
        setIsDownLoading(false);
        setTotalProgress(0);

        return FileSaver.saveAs(zipFile, fileName);
      }, 1000);
    });
  };

  const downloadAndZip = async (urls) => {
    const blobs = await downloadByGroup(urls);
    return exportZip(blobs);
  };

  const copyUrl = () => {
    urlInput.select();
    document.execCommand('copy');
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 4500);
  };

  useEffect(() => {
    const handleLoadImages = async (imgs, allDescriptions) => {
      let x = imgs.reverse().map((data, index) => {
        let url = data.url;
        let urlOriginal = url.replace('/thumbnails', '');
        let allimgs = imgs;
        return (
          <div
            key={index}
            style={{
              display: 'inline-block',
              margin: 1,
              position: 'relative'
            }}>
            <div>
              {allDescriptions ? (
                allDescriptions[url]?.isUsed ? (
                  <i
                    style={{
                      margin: 4,
                      position: 'absolute',
                      color: 'red',
                      zIndex: 1
                    }}
                    className="far fa-dot-circle"></i>
                ) : null
              ) : null}
              <img
                alt={data?.LastModified}
                onClick={() => {
                  setCurrent(index);
                  const dateFormat = new Intl.DateTimeFormat('en-US', {
                    dateStyle: 'medium',
                    timeStyle: 'long'
                  }).format(new Date(data?.LastModified));
                  setCreatedTime(dateFormat);

                  let ev = {
                    target: { value: urlOriginal }
                  };
                  if (!props.clientName) {
                    props.setAppImage(ev);
                    let justUrls = allimgs.map((item) => {
                      return item.url;
                    });
                    let des = '';
                    allDescriptions !== null
                      ? typeof allDescriptions?.url === 'object'
                        ? allDescriptions[url]?.description
                          ? (des = allDescriptions[url]?.description)
                          : (des = '')
                        : allDescriptions[url]
                        ? (des = allDescriptions[url])
                        : (des = '')
                      : (des = '');
                    props.checkImg([justUrls, ev.target.value, des]);
                  }

                  setShowImgModal([urlOriginal, url, dateFormat]);
                }}
                src={url}
                style={{
                  height: props.clientName ? 81 : 75,
                  width: props.clientName ? 81 : 75,
                  resizeMode: 'cover',
                  opacity: allDescriptions
                    ? allDescriptions[url]?.isUsed
                      ? 0.5
                      : 1
                    : 1
                }}
              />
            </div>
          </div>
        );
      });
      let all = imgs.map((item, index) => {
        return { url: item.url, LastModified: item.LastModified };
      });
      setAllUrls(all);
      setImages(x);
    };

    const getImages = async () => {
      if (props.userId) {
        await axios
          .get(`${APP_S3}?userId=${props.userId}`)
          .then(async (response) => {
            if (response.data.body.length) {
              let z = response.data.body;
              let config = {
                method: 'post',
                url: MANIPULATE_DESCRIPTION,
                data: {
                  userid: props.userId,
                  get: true,
                  to_suite: true
                }
              };
              await axios(config).then((res) => {
                if (res.data?.images) {
                  setAllDescriptions(res.data.images);
                  handleLoadImages(z, res.data.images);
                } else {
                  setAllDescriptions(null);
                  handleLoadImages(z, null);
                }
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    };

    getImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userId]);

  const handleClose = () => {
    setShowImgModal([null, null]);
    setIsCopied(false);
  };

  return (
    <div>
      <div className="card card-app-photos pd-5">
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: props.clientName ? 'space-between' : 'center'
          }}>
          <h5
            style={{ paddingLeft: 10, paddingTop: 10 }}
            className="card-title">
            App Gallery
          </h5>
          {props.clientName ? (
            <button
              style={{
                display: 'flex',
                alignItems: 'center',
                height: 30,
                alignSelf: 'center'
              }}
              className={`btn btn-primary  tx-11 p-1 mt-0`}
              onClick={(e) => {
                setIsDownLoading(true);
                setTimeout(() => {
                  let urls = allUrls.map(({ url }) => {
                    let original = url.replace('/thumbnails/', '/');
                    return original;
                  });
                  downloadAndZip(urls);
                }, 1000);
              }}
              disabled={isDownloading || !images}>
              <span
                className={`spinner-border spinner-border-sm mr-1 ${
                  !isDownloading ? 'hidden' : ''
                }`}
                role="status"
                aria-hidden="true"></span>
              <i
                className={`fas fa-fw fa-download ${
                  isDownloading ? 'hidden' : ''
                }`}></i>
              <span
                style={{ fontSize: 10 }}
                className={!isDownloading ? 'hidden' : ''}>
                {' '}
                Downloading {totalProgress + '%'}
              </span>
              <span className={isDownloading ? 'hidden' : ''}>
                {'Download All'}
              </span>
            </button>
          ) : null}
        </div>
        <div
          onContextMenu={(e) => e.preventDefault()}
          onDragStart={(e) => e.preventDefault()}
          className="images-wrapper">
          <div className="card-rows">
            {images ? (
              images
            ) : (
              <span className="d-flex align-items-center justify-content-center pd-y-13 tx-gray-400">
                No photos
              </span>
            )}
          </div>
        </div>

        {showImgModal[0] !== null && props.clientName && (
          <React.Fragment>
            <div className="modal-backdrop in"></div>
            <div
              className="modal fade in show"
              id="exampleModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              style={{ display: 'block' }}>
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      {'App gallery'}
                    </h5>
                    <button
                      type="button"
                      className="close button-like-a"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => handleClose()}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <div className="imgContainer">
                      {current !== 0 ? (
                        <button
                          className={`bnt-l-follow`}
                          onClick={() => {
                            if (current > 0) {
                              let urlOriginal = allUrls[
                                current - 1
                              ].url.replace('/thumbnails', '');
                              const dateFormat = new Intl.DateTimeFormat(
                                'en-US',
                                {
                                  dateStyle: 'medium',
                                  timeStyle: 'long'
                                }
                              ).format(
                                new Date(allUrls[current - 1]?.LastModified)
                              );
                              setCreatedTime(dateFormat);
                              setShowImgModal([
                                urlOriginal,
                                allUrls[current - 1]?.url
                              ]);
                              setCurrent(current - 1);
                            }
                          }}>
                          <PrevIcon id="prev-icon" className="icon-modal" />
                        </button>
                      ) : null}
                      <div
                        className="imgModal"
                        style={{
                          backgroundImage: `url(${showImgModal[0]})`,
                          height: 400,
                          width: '100%',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center'
                        }}
                      />
                      {current < allUrls.length - 1 ? (
                        <button
                          className={`bnt-r-follow`}
                          onClick={() => {
                            if (current < allUrls.length - 1) {
                              let urlOriginal = allUrls[
                                current + 1
                              ].url.replace('/thumbnails', '');
                              const dateFormat = new Intl.DateTimeFormat(
                                'en-US',
                                {
                                  dateStyle: 'medium',
                                  timeStyle: 'long'
                                }
                              ).format(
                                new Date(allUrls[current + 1]?.LastModified)
                              );
                              setCreatedTime(dateFormat);
                              setShowImgModal([
                                urlOriginal,
                                allUrls[current + 1]?.url
                              ]);
                              setCurrent(current + 1);
                            }
                          }}>
                          <NextIcon className="icon-modal" />
                        </button>
                      ) : null}
                    </div>
                    <div style={{ height: 80, marginTop: 15 }}>
                      <span style={{ fontWeight: 'bold' }}>
                        Image description
                      </span>
                      <p style={{ marginTop: 10, marginLeft: 5 }}>
                        {allDescriptions ? (
                          typeof allDescriptions[showImgModal[1]] ===
                          'object' ? (
                            allDescriptions[showImgModal[1]]?.description ? (
                              allDescriptions[showImgModal[1]]?.description
                            ) : (
                              <span style={{ color: 'red' }}>
                                No description
                              </span>
                            )
                          ) : allDescriptions[showImgModal[1]] ? (
                            allDescriptions[showImgModal[1]]
                          ) : (
                            <span style={{ color: 'red' }}>No description</span>
                          )
                        ) : (
                          <span style={{ color: 'red' }}>No description</span>
                        )}
                      </p>
                    </div>
                    <p className="text-muted mt-2">
                      <span style={{ fontWeight: '300' }}>
                        {createdTime && `Created at ${createdTime}`}
                      </span>
                    </p>
                  </div>

                  <div className="modal-footer">
                    <input
                      className={'form-control input-url'}
                      rows="1"
                      onChange={() => {}}
                      value={showImgModal[0]}
                      ref={(ref) => (urlInput = ref)}
                    />
                    <button
                      type="button"
                      style={{ fontWeight: '500', fontSize: 13 }}
                      className="btn btn-primary"
                      onClick={() => {
                        copyUrl();
                      }}>
                      {isCopied ? 'Copied!' : 'Copy'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
      {!props.clientName ? (
        <>
          <div className="card card-app-description">
            <h6
              className="card-subtitle mt-2 ml-2 text-muted"
              id="exampleModalLabel">
              App Gallery Description
            </h6>
            <div
              style={{
                margin: 5,
                marginLeft: 10
              }}
              className=" text-wrapper">
              <p>
                {allDescriptions ? (
                  typeof allDescriptions[showImgModal[1]] === 'object' ? (
                    allDescriptions[showImgModal[1]]?.description ? (
                      allDescriptions[showImgModal[1]]?.description
                    ) : (
                      <span style={{ color: 'red' }}>No description</span>
                    )
                  ) : allDescriptions[showImgModal[1]] ? (
                    allDescriptions[showImgModal[1]]
                  ) : (
                    <span style={{ color: 'red' }}>No description</span>
                  )
                ) : (
                  <span style={{ color: 'red' }}>No description</span>
                )}
              </p>
            </div>
          </div>
          <small className="text-muted mt-2">
            <span style={{ fontWeight: '300' }}>
              {createdTime && `Created at ${createdTime}`}
            </span>
          </small>
        </>
      ) : null}
    </div>
  );
};
export default UserGallery;
