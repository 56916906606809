import React from 'react';

const ScheduleColumn = ({ children: lastScheduledPost }) => {
  return (
    <>
      {lastScheduledPost !== null
        ? // If
          new Intl.DateTimeFormat('en-US', {
            dateStyle: 'long',
            timeStyle: 'short'
          }).format(new Date(lastScheduledPost * 1000))
        : // Else
          'No data'}
    </>
  );
};

export default ScheduleColumn;
