import React, { useState, useEffect } from 'react';
import DateTimePicker from 'react-datetime-picker';
import RepeatOn from './RepeatOn/RepeatOn';
import moment from 'moment-timezone';

const RecurrentPosts = (props) => {
  const [recurrentDate, setRecurrentDate] = useState(new Date());
  const [repeatEvery, setRepeatEvety] = useState('day');
  const [interval, setInterval] = useState(1);
  const [datesToPublish, setDatesToPublish] = useState([]);
  const [weekDays, setWeekDays] = useState([]);
  const startDate = props.date;

  const handleRecurrentDate = (date) => {
    const hours = startDate.getHours();
    const mins = startDate.getMinutes();
    date.setHours(hours);
    date.setMinutes(mins);
    setRecurrentDate(date);
  };

  const handleRepeatEvery = (e) => setRepeatEvety(e.target.value);
  const handleNumberOfinterval = (e) => {
    if (e.target.value <= 7) {
      setInterval(e.target.value);
    }
  };

  // =============== FINAL ARR LISTENER, PASS TO PARENT ===================
  useEffect(() => {
    let unixArr = [];
    datesToPublish.forEach((date) => {
      unixArr.push(moment.utc(date).unix());
    });
    if (unixArr.length > 0) {
      props.recurrentDates(unixArr);
    }
    // eslint-disable-next-line
  }, [datesToPublish]);

  // ===================== HANDLING DAYS INTERVAL ===========================
  const daysInterval = () => {
    let differenceInTime = recurrentDate.getTime() - startDate.getTime();
    let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    let daysToPublish = differenceInDays + 1;

    for (let i = 0; i < daysToPublish; i = i + parseInt(interval)) {
      let date = new Date(startDate);
      let element = date.setDate(date.getDate() + parseInt(i));
      setDatesToPublish((oldArr) => [...oldArr, new Date(element)]);
    }
  };

  // ===================== HANDLING WEEKS INTERVAL ===========================
  const weeksInterval = () => {
    let weeksOfDiff = moment(recurrentDate).diff(moment(startDate), 'week');
    weekDays.sort().forEach((weekDay) => {
      let diff = parseInt(weekDay) - startDate.getDay();
      let initialDate = new Date(startDate);
      let newDate = initialDate.setDate(initialDate.getDate() + diff);
      for (let z = 0; z < weeksOfDiff + 1; z = z + parseInt(interval)) {
        let xxx = moment(newDate).add(z, 'week');
        let dateToPush = new Date(xxx);
        let today = new Date();
        if (dateToPush >= today) {
          setDatesToPublish((oldArr) => [...oldArr, dateToPush]);
        }
      }
    });
  };

  // =============== CHANGE DATE LISTENER ===================
  useEffect(() => {
    if (recurrentDate > startDate) {
      if (repeatEvery === 'day') {
        setDatesToPublish([]);
        daysInterval();
      } else {
        setDatesToPublish([]);
        weeksInterval();
      }
    }
    // eslint-disable-next-line
  }, [recurrentDate, startDate, interval, weekDays]);

  // ========================================================================
  return (
    <div>
      <div>
        <h6 className="card-subtitle mb-2 text-muted">
          Repeat every: <small>(7 max)</small>
        </h6>
        <div className="row input-group">
          <div className="col-4 pr-0">
            <input
              type="number"
              className="form-control"
              value={interval}
              onChange={handleNumberOfinterval}
              min="1"
              max="7"
            />
          </div>
          <div className="col-6 pl-0">
            <select
              className="form-control"
              value={repeatEvery}
              onChange={handleRepeatEvery}>
              <option value="day">Day{interval > 1 && 's'}</option>
              <option value="week">Week{interval > 1 && 's'}</option>
            </select>
          </div>
        </div>
      </div>
      <div className="mt-3">
        {repeatEvery !== 'day' && (
          <RepeatOn weekDays={(data) => setWeekDays(data)} />
        )}
      </div>
      <div className="mt-3">
        <h6 className="card-subtitle mb-2 text-muted">Ends on:</h6>
        <DateTimePicker
          onChange={handleRecurrentDate}
          value={recurrentDate}
          required
          minDate={startDate}
          maxDate={new Date(moment().add(1, 'years'))}
          clearIcon={null}
          calendarIcon={null}
          format="MM/dd/y"
          tileClassName={(date) => {
            return datesToPublish.map((d) => {
              if (moment(date.date).isSame(d, 'day')) {
                return 'recurrent-day';
              }
              return undefined;
            });
          }}
        />
      </div>
    </div>
  );
};

export default RecurrentPosts;
