import React from 'react';
const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <div>
          <span>
            &copy; {new Date().getFullYear()}{' '}
            <a href="http://adlersocial.com">Adler Social</a>, LLC. All Rights
            Reserved.
          </span>
        </div>
        <div>
          <nav style={{ display: 'none' }} className="nav">
            <a href="https://samxlabs.com" className="nav-link">
              Get Help
            </a>
          </nav>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
