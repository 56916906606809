import React from 'react';

const SectionTitle = ({ title }) => {
  return (
    <header
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
      }}>
      <h5 style={{ paddingLeft: 10, paddingTop: 10 }}>{title}</h5>
    </header>
  );
};

export default SectionTitle;
