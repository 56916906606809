import {
  API_CLIENTS,
  API_KEY,
  // LINKS_CLIENTS,
  API_CLIENTS_DELETE,
  API_CLIENTS_EDIT,
  API_POSTS_COUNT,
  API_FACEBOOK_PAGES
} from '../../utils/constants';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import AWS_Lambda from '../../aws/aws';

const axios = require('axios');

// Get all the clients
export const getClients = createAsyncThunk(
  'clients/logoutUser',
  async (payload, { getState, requestId, rejectWithValue }) => {
    const { userInfo } = getState().auth;

    try {
      return await axios
        .all([
          axios.get(API_CLIENTS, { headers: { 'X-API-KEY': API_KEY } }),
          axios.get(API_FACEBOOK_PAGES, {
            headers: {
              Authorization: `bearer ${userInfo.accessToken}`
            }
          })
        ])
        .then(
          axios.spread(async (response, response3) => {
            const clients = response.data.clients;

            // let params = {
            //   FunctionName: LINKS_CLIENTS,
            //   Payload: JSON.stringify({ httpMethodLambda: 'GET' })
            // };
            // let links = await AWS_Lambda.invoke(params).promise();
            // links = JSON.parse(links['Payload']);
            // links = JSON.parse(links['body']);
            // links = links['Items'];

            let clientsListWithGroups = clients.map(function (client, index) {
              let countG = null;
              let likesPerClient;
              let reachedPerClient;
              let engagementPerClient;

              response3.data.pages.forEach((datos) => {
                if (
                  client.active !== false &&
                  client.socialnetworks.facebookPage === datos.entity_name
                ) {
                  likesPerClient = {
                    client: client.clientName,
                    likes: datos.country_page_likes
                  };
                  reachedPerClient = {
                    client: client.clientName,
                    reached: datos.reached,
                    reachedPercentage: datos.reachedPercentage
                  };
                  engagementPerClient = {
                    client: client.clientName,
                    engagement: datos.engament,
                    engagementPercentage: datos.engamentPercentage
                  };
                }
              });

              if (typeof client.gmbId === 'undefined') {
                countG = 0;
              } else {
                countG = 0;
                // links.filter(function (r) {
                //   return r.id === client.gmbId;
                // });
              }

              let countG2 = countG[0];
              let countY = null;

              if (typeof client.gmbId === 'undefined') {
                countY = 0;
              } else {
                countY = 0;
                // links.filter(function (r) {
                //   return r.id === client.yelpId;
                // });
              }

              let countY2 = countY[0];

              let clientGrp = null;

              if (typeof client.clientGroups === 'undefined') {
                clientGrp = 'None';
              }

              if (typeof client.clientGroups === 'object') {
                if (client.clientGroups.hasOwnProperty('groupName')) {
                  clientGrp = client.clientGroups.groupName;
                } else {
                  let groupsFromArray = client.clientGroups.map(
                    (item) => item.groupName
                  );
                  clientGrp = groupsFromArray.join(', ');
                }
              }

              return {
                id: client.id,
                clientName: client.clientName,
                clientGroups: clientGrp,
                clientSocialNetworks: client.socialnetworks,
                timeZone: client.timeZone,
                bestTimes: client.bestTimes,
                appActive: client.appActive,
                appUid: client.appUid,
                smsCount: client.smsCount,
                mergeFields: client.mergeFields,
                gmbId: client.gmbId,
                yelpId: client.yelpId,
                countGmb: countG2,
                countYelp: countY2,
                likesFacebook: likesPerClient,
                reachedFacebook: reachedPerClient,
                engagementFacebook: engagementPerClient,
                trained: client.trained
              };
            });
            return clientsListWithGroups;
          })
        )
        .catch(function (error) {
          console.error('error getting clients', error);
          return [];
        });
    } catch (error) {
      console.error('error in multiple promises ', error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete a client
export const deleteClient = createAsyncThunk(
  'clients/deleteClient',
  async (payload, { getState, rejectWithValue }) => {
    try {
      const { loadingClients } = getState().clients;
      const { userInfo } = getState().auth;

      if (!loadingClients) {
        return;
      }

      let res = await axios.post(API_CLIENTS_DELETE, payload, {
        headers: {
          Authorization: `bearer ${userInfo.accessToken}`
        }
      });
      if (res.status === 200) return true;
      else return false;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Update a client
export const updateClient = createAsyncThunk(
  'clients/updateClient',
  async (payload, { getState, rejectWithValue }) => {
    try {
      const { loadingClients } = getState().clients;
      if (!loadingClients) {
        return;
      }
      let res = await axios.post(API_CLIENTS_EDIT, payload);
      if (res.status === 200) return true;
      else throw new Error('Could not update client');
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get client by id
export const getClientById = createAsyncThunk(
  'clients/getClientById',
  async ({ clientId }, { getState, requestId, rejectWithValue }) => {
    try {
      const { loadingClients } = getState().clients;
      if (!loadingClients) {
        return;
      }
      let res = await axios.get(`${API_CLIENTS}/${clientId}`, {
        headers: {
          'X-API-KEY': API_KEY
        }
      });

      return res.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Get client by id
export const getCountersById = createAsyncThunk(
  'clients/getCountersById',
  async ({ id }, { getState, requestId, rejectWithValue }) => {
    try {
      const { loadingClients } = getState().clients;
      if (!loadingClients) {
        return;
      }
      let res = await axios.post(
        API_POSTS_COUNT,
        { id: id },
        {
          headers: {
            'X-API-KEY': API_KEY
          }
        }
      );

      return res.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const slice = createSlice({
  name: 'clients',
  initialState: {
    customerList: [],
    currentRequestId: undefined,
    loadingClients: true,
    errorClients: null
  },
  reducers: {},
  extraReducers: {
    [getClientById.pending]: (state, action) => {
      if (!state.loadingClients) {
        state.loadingClients = true;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [getClientById.fulfilled]: (state) => {
      if (state.loadingClients) {
        state.loadingClients = false;
        state.errorClients = null;
      }
    },
    [getClientById.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loadingClients && state.currentRequestId === requestId) {
        state.loadingClients = false;
        state.errorClients = action.error;
        state.currentRequestId = undefined;
      }
    },
    [getCountersById.pending]: (state, action) => {
      if (!state.loadingClients) {
        state.loadingClients = true;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [getCountersById.fulfilled]: (state, action) => {
      if (state.loadingClients) {
        state.loadingClients = false;
        state.errorClients = null;
      }
    },
    [getCountersById.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loadingClients && state.currentRequestId === requestId) {
        state.loadingClients = false;
        state.errorClients = action.error;
        state.currentRequestId = undefined;
      }
    },
    [getClients.pending]: (state, action) => {
      if (!state.loadingClients) {
        state.loadingClients = true;
        state.currentRequestId = action.meta?.requestId;
      }
    },
    [getClients.fulfilled]: (state, action) => {
      if (state.loadingClients) {
        state.customerList = action.payload;
        state.loadingClients = false;
        state.errorClients = null;
      }
    },
    [getClients.rejected]: (state, action) => {
      if (state.loadingClients) {
        state.customerList = [];
        state.loadingClients = false;
        state.errorClients = action.error;
        state.currentRequestId = undefined;
      }
    },
    [deleteClient.pending]: (state, action) => {
      if (!state.loadingClients) {
        state.loadingClients = true;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [deleteClient.fulfilled]: (state, action) => {
      if (state.loadingClients) {
        state.loadingClients = false;
        state.errorClients = null;
      }
    },
    [deleteClient.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loadingClients && state.currentRequestId === requestId) {
        state.customerList = null;
        state.loadingClients = false;
        state.errorClients = action.error;
        state.currentRequestId = undefined;
      }
    },
    [updateClient.pending]: (state, action) => {
      if (!state.loadingClients) {
        state.loadingClients = true;
        state.currentRequestId = action.meta.requestId;
      }
    },
    [updateClient.fulfilled]: (state, action) => {
      if (state.loadingClients) {
        state.loadingClients = false;
        state.errorClients = null;
      }
    },
    [updateClient.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (state.loadingClients && state.currentRequestId === requestId) {
        state.customerList = null;
        state.loadingClients = false;
        state.errorClients = action.error;
        state.currentRequestId = undefined;
      }
    }
  }
});

export default slice.reducer;

// Clients selector
export const clientsSelector = (state) => state.clients;
