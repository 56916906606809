import React from 'react';

const Image = (props) => {
  const { alt, src, userPage, selectImage } = props;

  return (
    <div className="col-3-sm">
      <img
        alt={alt}
        src={src}
        style={{
          height: 77,
          width: 77,
          margin: 0.5
        }}
        onClick={() => {
          selectImage();
        }}
      />
      <p style={{ fontSize: '0.7rem' }}>
        <u>
          <a href={userPage} target="__blank">
            Visit author
          </a>
        </u>
      </p>
    </div>
  );
};

export default Image;
