import React, { useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
const Image = ({ img, alt, style, onDeleteImage, isModal }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
        maxHeight: isModal ? '160px' : '210px',
        marginBottom: '15px'
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <img
        src={img.SK}
        alt={alt}
        style={{
          ...style,
          maxHeight: isModal ? '155px' : '210px',
          borderRadius: '4px',
          opacity: isHovered ? '0.5' : 1,
          transition: isHovered ? '200ms' : 0
        }}
      />

      {isHovered && (
        <button
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'rgb(255, 0, 0)',
            color: 'white',
            border: 'none',
            width: '50px',
            height: '30px',
            borderRadius: '5px',
            cursor: 'pointer',
            zIndex: 100,
            opacity: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          onClick={() => {
            onDeleteImage(img);
          }}>
          <DeleteIcon fontSize="medium" />
        </button>
      )}
    </div>
  );
};

export default Image;
