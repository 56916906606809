import React, { createContext, useState } from 'react';

export const ClientsContext = createContext();

const ClientsContextProvider = (props) => {
  const [clientsContext, setClientsContext] = useState({
    nada: 'nada por el momento'
  });

  return (
    <ClientsContext.Provider
      value={{
        clientsContext,
        setClientsContext
      }}>
      {props.children}
    </ClientsContext.Provider>
  );
};

export default ClientsContextProvider;
